// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const GlobeIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'globe'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M22 12C22 17.5228 17.5228 22 12 22M22 12C22 6.47715 17.5228 2 12 2M22 12H2M12 22C6.47715 22 2 17.5228 2 12M12 22C9.43223 19.3038 8 15.7233 8 12C8 8.27674 9.43223 4.69615 12 2M12 22C14.5678 19.3038 16 15.7233 16 12C16 8.27674 14.5678 4.69615 12 2M2 12C2 6.47715 6.47715 2 12 2"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
GlobeIcon.displayName = 'GlobeIcon'
export { GlobeIcon }
