// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const GifIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'gif'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 1.5C2.39543 1.5 1.5 2.39543 1.5 3.5V20.5C1.5 21.6046 2.39543 22.5 3.5 22.5H20.5C21.6046 22.5 22.5 21.6046 22.5 20.5V3.5C22.5 2.39543 21.6046 1.5 20.5 1.5H3.5ZM15.2727 7.91818V16.5685H17.1016V12.9952H20.62V11.4873H17.1016V9.42608H21.0001V7.91818H15.2727ZM13.8984 7.91818V16.5685H12.0695V7.91818H13.8984ZM8.63879 10.1695C8.74579 10.3272 8.82886 10.5088 8.88799 10.7144H10.7465C10.6873 10.2863 10.5536 9.89494 10.3452 9.54014C10.1368 9.18252 9.86792 8.87418 9.53846 8.61512C9.21182 8.35606 8.83731 8.15614 8.41492 8.01534C7.99254 7.87173 7.53637 7.79993 7.04641 7.79993C6.47479 7.79993 5.944 7.89989 5.45403 8.09982C4.96407 8.29974 4.53465 8.59119 4.16577 8.97415C3.79689 9.35711 3.50967 9.82454 3.30411 10.3765C3.10137 10.9255 3 11.5507 3 12.2518C3 13.1642 3.17036 13.9526 3.51108 14.6172C3.85462 15.2789 4.33332 15.79 4.94718 16.1504C5.56104 16.508 6.27345 16.6868 7.08442 16.6868C7.81092 16.6868 8.45576 16.5404 9.01893 16.2475C9.5821 15.9519 10.0242 15.5323 10.3452 14.9889C10.6662 14.4426 10.8267 13.7893 10.8267 13.029V11.9393H7.20692V13.3162H9.0648C9.05732 13.662 8.97867 13.9633 8.82886 14.2201C8.67399 14.4904 8.44872 14.7002 8.15305 14.8495C7.8602 14.9959 7.50681 15.0691 7.09287 15.0691C6.6367 15.0691 6.24107 14.9593 5.90598 14.7396C5.57089 14.5172 5.31183 14.1948 5.1288 13.7724C4.94577 13.35 4.85425 12.8375 4.85425 12.2349C4.85425 11.6323 4.94718 11.1227 5.13302 10.7059C5.31887 10.2863 5.57793 9.96674 5.91021 9.7471C6.24529 9.52746 6.63388 9.41765 7.07598 9.41765C7.31251 9.41765 7.52792 9.44721 7.72222 9.50635C7.91933 9.56548 8.09392 9.65136 8.24597 9.764C8.40085 9.87382 8.53178 10.009 8.63879 10.1695Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
GifIcon.displayName = 'GifIcon'
export { GifIcon }
