import React from 'react'
import clsx from 'clsx'

import { ZapFilledIcon } from '../icons'
import { Badge, BadgeProps } from './Badge'
import { VisuallyHidden } from '../VisuallyHidden'

import styles from './UpgradeBadge.module.css'

type UpgradeBadgeProps = Omit<BadgeProps, 'children'>

const UpgradeBadge = React.forwardRef<HTMLSpanElement, UpgradeBadgeProps>(
  ({ className, ...props }, forwardedRef) => {
    return (
      <Badge
        size="medium"
        className={clsx(className, styles.upgrade)}
        {...props}
        ref={forwardedRef}
      >
        <ZapFilledIcon />
        <VisuallyHidden>Upgrade</VisuallyHidden>
      </Badge>
    )
  },
)

UpgradeBadge.displayName = 'UpgradeBadge'

export { UpgradeBadge, UpgradeBadgeProps }
