// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const ShirtIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'shirt'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M15.5 4H19C20.6 4 21 5.41176 21 6.11765L20 19.8824C20 21.5765 18.6667 22 18 22H6C4.4 22 4 20.5882 4 19.8824L3 6.11765C3 4.42353 4.33333 4 5 4H8.5M12 9L12 21M8.07143 3.07143L9.36667 2.1C9.45321 2.03509 9.55848 2 9.66667 2H14.3333C14.4415 2 14.5468 2.03509 14.6333 2.1L15.9286 3.07143M8.07143 3.07143L7.5 3.5V10C7.5 10.5 8 11 8.5 11C9 11 12 8.12124 12 8.12124M8.07143 3.07143L12 7M8.07143 3.07143L8 3M15.9286 3.07143L16.5 3.5V10C16.5 10.5 16 11 15.5 11C15 11 12 8.12124 12 8.12124M15.9286 3.07143L12 7M15.9286 3.07143L16 3M12 8.12124V7"
          stroke="currentColor"
        />
      </svg>
    )
  },
)
ShirtIcon.displayName = 'ShirtIcon'
export { ShirtIcon }
