// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const IgReelIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'ig-reel'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M13.2512 2L15.7023 6.51402H10.6179L8.16679 2H13.2512Z"
          fill="currentColor"
        />
        <path
          d="M16.5115 2C19.1678 2 21.3882 3.94355 21.892 6.5147H18.1766L15.7244 2H16.5115Z"
          fill="currentColor"
        />
        <path
          d="M5.83414 2.26169C3.94925 2.87357 2.50237 4.50094 2.10792 6.51415H8.14392L5.83414 2.26169Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 16.3781V8.72883H22V16.3781C22 19.478 19.5378 22 16.5116 22H7.48844C4.46222 22 2 19.478 2 16.3781ZM10.3667 18.2406L15.0722 15.6644C15.7489 15.2938 15.7489 14.3 15.0722 13.9296L10.3667 11.3535C9.72711 11.0031 8.954 11.478 8.954 12.2208V17.3733C8.954 18.1161 9.72711 18.5907 10.3667 18.2406Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
IgReelIcon.displayName = 'IgReelIcon'
export { IgReelIcon }
