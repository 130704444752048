import React, { CSSProperties, forwardRef } from 'react'
import { Slot } from '@radix-ui/react-slot'
import clsx from 'clsx'

import { Space } from '../../styles/tokens/tokens'
import styles from './Card.module.css'

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Size of the padding around the card
   * @default small
   */
  padding?: 'small' | 'large' | 'none' | Space
  /**
   * Render the card as a child element
   */
  asChild?: boolean
}

interface CardCssVariables extends CSSProperties {
  '--card-padding': `var(--space-${Space})` | 'none'
}

const paddingMap = {
  small: 'var(--space-md)',
  large: 'var(--space-xl)',
  none: 'none',
} as const

const spaces = ['xl', 'lg', 'md', 'xs', '2xs', '2xl', '3xl', '4xl', '3xs', 'sm']
function isSpacePadding(padding: string): padding is Space {
  return spaces.includes(padding)
}

/**
 * A container for content representing a single entity
 */
export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    { asChild, padding = 'small', className, ...props }: CardProps,
    forwardedRef,
  ) => {
    const Tag = asChild ? Slot : 'div'
    const cardPadding = isSpacePadding(padding)
      ? (`var(--space-${padding})` as const)
      : paddingMap[padding]

    return (
      <Tag
        style={
          {
            '--card-padding': cardPadding,
          } satisfies CardCssVariables as CardCssVariables
        }
        className={clsx(styles.container, className)}
        ref={forwardedRef}
        {...props}
      />
    )
  },
)

Card.displayName = 'Card'
