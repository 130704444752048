import * as React from 'react'
import clsx from 'clsx'
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'

import styles from './ScrollArea.module.css'

type ScrollAreaElement = React.ElementRef<typeof ScrollAreaPrimitive.Viewport>

type ScrollAreaProps = {
  /**
   * Additional CSS class to apply to the viewport
   */
  className?: string
  /**
   * Size of the scrollbar
   * @default medium
   */
  size?: 'small' | 'medium'
  /**
   * The orientation of the scrollbars
   * @default vertical
   */
  scrollbars?: 'vertical' | 'horizontal' | 'both'
  /**
   * Describes the nature of scrollbar visibility
   * `auto` means that scrollbars are visible when content is overflowing
   * `always` means that scrollbars are always visible regardless of whether the content is overflowing.
   * `scroll` means that scrollbars are visible when the user is scrolling
   * `hover` when the user is scrolling along its corresponding orientation and when the user is hovering over the scroll area
   * @default auto
   */
  visibility?: 'auto' | 'always' | 'scroll' | 'hover'
  /**
   * Content to render inside the scroll area
   */
  children?: React.ReactNode
} & React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Viewport>

const ScrollArea = React.forwardRef<ScrollAreaElement, ScrollAreaProps>(
  (
    {
      className,
      size = 'medium',
      scrollbars = 'vertical',
      visibility = 'auto',
      ...props
    }: ScrollAreaProps,
    forwardedRef,
  ) => {
    return (
      <ScrollAreaPrimitive.Root
        type={visibility}
        scrollHideDelay={1000}
        className={clsx(styles.root, styles[size], className)}
      >
        <ScrollAreaPrimitive.Viewport
          {...props}
          ref={forwardedRef}
          className={styles.viewport}
        />
        <div className={styles.focusRing} />

        {scrollbars !== 'vertical' ? (
          <ScrollAreaPrimitive.Scrollbar
            data-radius={4}
            orientation="horizontal"
            className={clsx(styles.scrollbar)}
          >
            <ScrollAreaPrimitive.Thumb className={styles.thumb} />
          </ScrollAreaPrimitive.Scrollbar>
        ) : null}

        {scrollbars !== 'horizontal' ? (
          <ScrollAreaPrimitive.Scrollbar
            data-radius={4}
            orientation="vertical"
            className={clsx(styles.scrollbar)}
          >
            <ScrollAreaPrimitive.Thumb className={styles.thumb} />
          </ScrollAreaPrimitive.Scrollbar>
        ) : null}

        {scrollbars === 'both' ? (
          <ScrollAreaPrimitive.Corner className={styles.corner} />
        ) : null}
      </ScrollAreaPrimitive.Root>
    )
  },
)
ScrollArea.displayName = 'ScrollArea'

export { ScrollArea }
export type { ScrollAreaProps }
