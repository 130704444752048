import React from 'react'
import clsx from 'clsx'
import type * as Polymorphic from '@radix-ui/react-polymorphic'

import textColorStyles from '../../styles/atoms/text-color.module.css'
import textAlignStyles from '../../styles/atoms/text-align.module.css'

import styles from './Text.module.css'

type TextColor =
  | 'default'
  | 'subtle'
  | 'inverted'
  | 'brand'
  | 'warning'
  | 'success'
  | 'critical'
  | 'upgrade'
  | 'inherit'

type TextProps = {
  /**
   * Render as a different element
   * @default span
   */
  as?:
    | 'p'
    | 'div'
    | 'label'
    | 'span'
    | 'small'
    | 'legend'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'time'

  /**
   * Controls visual size of the text
   * @default md
   */
  size?: 'sm' | 'md' | 'lg'

  /**
   * Align text to the left, center or right
   */
  align?: 'left' | 'center' | 'right'

  /**
   * Controls visual weight of the text
   * @default regular
   */
  weight?: 'regular' | 'medium' | 'bold'

  /**
   * Controls line height of the text
   * @default regular
   */
  lineHeight?: 'tight' | 'regular'

  /**
   * Controls visual color of the text
   * @default inherit
   */
  color?: TextColor
  /**
   * Controls the text overflow behavior, adding ellipsis when the text exceeds the width of the container.
   * When using beware of [common flex and ellipsis pitfalls](https://css-tricks.com/flexbox-truncated-text/)
   * @default false
   */
  truncate?: boolean
}

const Text = React.forwardRef(
  (
    {
      children,
      className,
      as,
      size = 'md',
      color = 'inherit',
      align,
      weight = 'regular',
      lineHeight = 'regular',
      truncate = false,
      ...props
    },
    forwardedRef,
  ) => {
    const Tag = as || 'span'

    return (
      <Tag
        className={clsx(
          styles.base,
          styles[size],
          styles[weight],
          styles[`lh-${lineHeight}`],
          textColorStyles[color],
          align && textAlignStyles[align],
          truncate && styles.truncate,
          className,
        )}
        ref={forwardedRef}
        {...props}
      >
        {children}
      </Tag>
    )
  },
) as Polymorphic.ForwardRefComponent<'span', TextProps>

Text.displayName = 'Text'

export { Text, TextProps, TextColor }
