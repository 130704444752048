import React from 'react'
import clsx from 'clsx'

import styles from './Skeleton.module.css'

type SkeletonProps = {
  /**
   * Width of the skeleton
   * accepts number (in px) or string to set in % or ch
   * @example 82, '100%', '24ch'
   */
  width?: number | string
  /**
   * Height of the skeleton
   * accepts number (in px) or string to set in %, rem or ch
   * @example 82, '100%', '1rem'
   */
  height?: number | string
  /**
   * Additional class name
   */
  className?: string
  /**
   * If true, the skeleton will have fully corners
   */
  rounded?: boolean
  /**
   * If true, the skeleton will be squared, no border radius
   */
  squared?: boolean
  /**
   * If false, the children will be rendered instead of the skeleton
   */
  show?: boolean
  /**
   * If provided, skeleton will wrap the whildren in an overlay
   */
  children?: React.ReactNode
}

const computeSize = (size: number | string | undefined): string | undefined => {
  if (typeof size === 'number') {
    return `${size}px`
  }

  if (typeof size === 'string') {
    return size
  }

  return undefined
}

const Skeleton = React.forwardRef<HTMLDivElement, SkeletonProps>(
  (
    {
      className,
      width,
      height,
      squared = false,
      rounded = false,
      show,
      children,
      ...props
    }: SkeletonProps,
    forwardedRef,
  ) => {
    // If children are provided and show is false, we should render the children
    if (children && show === false) {
      return <>{children}</>
    }

    const computedWidth = computeSize(width)
    const computedHeight = computeSize(height)
    const skeletonClassName = clsx(
      styles.skeleton,
      squared && styles.squared,
      rounded && styles.rounded,
      className,
    )
    const skeletonStyle = {
      '--skeleton-width': computedWidth,
      '--skeleton-height': computedHeight,
    } as React.CSSProperties

    // If children provided, skeleton is loaded in the overlay
    if (children) {
      return (
        <div ref={forwardedRef} className={styles.wrapper} aria-hidden>
          {children}
          <div className={skeletonClassName} style={skeletonStyle} {...props} />
        </div>
      )
    }

    return (
      <div
        aria-hidden
        ref={forwardedRef}
        className={skeletonClassName}
        style={skeletonStyle}
        {...props}
      />
    )
  },
)

Skeleton.displayName = 'Skeleton'

type SkeletonTextProps = {
  /**
   * Number of lines to render in the paragraph
   */
  lines?: number
  /**
   * Width of the skeleton
   * accepts number (in px) or string to set in % or ch
   * @example 82, '100%', '24ch'
   */
  width?: number | string
  className?: string
}

const SkeletonText = React.forwardRef<HTMLDivElement, SkeletonTextProps>(
  (
    { className, lines = 3, width, ...props }: SkeletonTextProps,
    forwardedRef,
  ) => {
    const computedWidth = computeSize(width) ?? '100%'

    return (
      <div
        ref={forwardedRef}
        className={clsx(styles.text, className)}
        style={
          {
            '--skeleton-text-width': computedWidth,
          } as React.CSSProperties
        }
        {...props}
      >
        {Array.from({ length: lines }).map((_, index) => (
          <div key={index} className={styles.lineContainer}>
            <Skeleton
              key={index}
              rounded
              className={styles.line}
              width={lines < 2 || index !== lines - 1 ? '100%' : '60%'}
            />
          </div>
        ))}
      </div>
    )
  },
)

SkeletonText.displayName = 'Skeleton.Text'

export { Skeleton, SkeletonText }
