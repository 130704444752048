import clsx from 'clsx'
import React from 'react'

import styles from './Input.module.css'

type InputProps = Omit<
  React.ComponentPropsWithoutRef<'input'>,
  'size' | 'prefix' | 'suffix'
> & {
  /**
   * Size of the input
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Prefix element to render before the input
   */
  prefix?: React.ReactNode
  /**
   * Suffix element to render after the input
   */
  suffix?: React.ReactNode
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, size = 'medium', prefix, suffix, ...props }: InputProps,
    forwardedRef,
  ) => {
    return (
      <div className={clsx(styles.base, styles[size], className)}>
        {prefix && <div className={styles.prefix}>{prefix}</div>}
        <input
          type="text"
          {...props}
          className={styles.input}
          ref={forwardedRef}
        />
        {suffix && <div className={styles.suffix}>{suffix}</div>}
      </div>
    )
  },
)

Input.displayName = 'Input'

export { Input, InputProps }
