/**
 * Global reminder fields configuration
 * THIS PACKAGE SHOULD HAVE NO DEPENDENCIES
 */

export enum PostFields {
  MUSIC = 'music',
  TITLE = 'title',
  TEXT = 'text',
  TOPICS = 'topics',
  PRODUCTS = 'products',
  OTHER = 'other',
  LINK = 'link',
  FIRST_COMMENT = 'comment_text',
  LOCATION_NAME = 'service_geolocation_name',
  LOCATION_ID = 'service_geolocation_id',
  SHARE_TO_FEED = 'share_to_feed',
}

enum UpdateService {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Linkedin = 'linkedin',
  Pinterest = 'pinterest',
  Twitter = 'twitter',
  Tiktok = 'tiktok',
  GoogleBusiness = 'googlebusiness',
  StartPage = 'startPage',
  Mastodon = 'mastodon',
  Youtube = 'youtube',
  Threads = 'threads',
}

enum UpdateType {
  WHATS_NEW = 'whats_new',
  OFFER = 'offer',
  EVENT = 'event',
  POST = 'post',
  REELS = 'reels',
  THREAD = 'thread',
  STORY = 'story',
  SHORT = 'short',
  VIDEO = 'video',
  FACEBOOK_REEL = 'facebook_reel',
  CAROUSEL = 'carousel',
}

export type Settings = {
  reminderFields: PostFields[]
  postFields: PostFields[]
}

export type PostTypeConfig = {
  [key: string]: Settings & { remindersEnabled?: boolean }
}

type ServiceDetail = {
  enabled: boolean
  postTypes: PostTypeConfig
}

type ServiceConfig = Record<string, ServiceDetail>

export const ChannelFieldConfiguration: ServiceConfig = {
  [UpdateService.Instagram]: {
    enabled: true,
    postTypes: {
      [UpdateType.POST]: {
        remindersEnabled: true,
        reminderFields: [
          PostFields.FIRST_COMMENT,
          PostFields.LOCATION_NAME,
          PostFields.MUSIC,
          PostFields.PRODUCTS,
        ],
        postFields: [
          PostFields.FIRST_COMMENT,
          PostFields.LOCATION_NAME,
          PostFields.LOCATION_ID,
          PostFields.LINK,
        ],
      },
      [UpdateType.REELS]: {
        remindersEnabled: true,
        reminderFields: [
          PostFields.MUSIC,
          PostFields.FIRST_COMMENT,
          PostFields.LOCATION_NAME,
          PostFields.TEXT,
          PostFields.TOPICS,
          PostFields.PRODUCTS,
        ],
        postFields: [
          PostFields.FIRST_COMMENT,
          PostFields.LOCATION_NAME,
          PostFields.LOCATION_ID,
          PostFields.LINK,
          PostFields.SHARE_TO_FEED,
        ],
      },
      [UpdateType.STORY]: {
        remindersEnabled: true,
        reminderFields: [
          PostFields.TEXT,
          PostFields.LINK,
          PostFields.MUSIC,
          PostFields.OTHER,
        ],
        postFields: [],
      },
    },
  },
  [UpdateService.Tiktok]: {
    enabled: true,
    postTypes: {
      [UpdateType.POST]: {
        remindersEnabled: true,
        reminderFields: [],
        postFields: [],
      },
    },
  },
  [UpdateService.Youtube]: {
    enabled: true,
    postTypes: {
      [UpdateType.POST]: {
        remindersEnabled: true,
        reminderFields: [],
        postFields: [],
      },
    },
  },
}
