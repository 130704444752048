// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const InstagramIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'instagram'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3871 3.65323C19.3387 2.56452 17.8468 2 16.1532 2H7.84677C4.33871 2 2 4.33871 2 7.84677V16.1129C2 17.8468 2.56452 19.3387 3.69355 20.4274C4.78226 21.4758 6.23387 22 7.8871 22H16.1129C17.8468 22 19.2984 21.4355 20.3468 20.4274C21.4355 19.379 22 17.8871 22 16.1532V7.84677C22 6.15323 21.4355 4.70161 20.3871 3.65323ZM20.2258 16.1532C20.2258 17.4032 19.7823 18.4113 19.0565 19.0968C18.3306 19.7823 17.3226 20.1452 16.1129 20.1452H7.8871C6.67742 20.1452 5.66935 19.7823 4.94355 19.0968C4.21774 18.371 3.85484 17.3629 3.85484 16.1129V7.84677C3.85484 6.6371 4.21774 5.62903 4.94355 4.90323C5.62903 4.21774 6.67742 3.85484 7.8871 3.85484H16.1935C17.4032 3.85484 18.4113 4.21774 19.1371 4.94355C19.8226 5.66935 20.2258 6.67742 20.2258 7.84677V16.1532ZM18.532 6.67741C18.532 7.32322 18.0084 7.84676 17.3626 7.84676C16.7168 7.84676 16.1933 7.32322 16.1933 6.67741C16.1933 6.03159 16.7168 5.50805 17.3626 5.50805C18.0084 5.50805 18.532 6.03159 18.532 6.67741ZM6.83842 11.9597C6.83842 9.09678 9.17713 6.79839 11.9997 6.79839C14.8223 6.79839 17.161 9.1371 17.161 11.9597C17.161 14.7823 14.8626 17.121 11.9997 17.121C9.13681 17.121 6.83842 14.8226 6.83842 11.9597ZM8.69326 11.9597C8.69326 13.7742 10.1852 15.2661 11.9997 15.2661C13.8142 15.2661 15.3062 13.7742 15.3062 11.9597C15.3062 10.1452 13.8142 8.65323 11.9997 8.65323C10.1852 8.65323 8.69326 10.1452 8.69326 11.9597Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
InstagramIcon.displayName = 'InstagramIcon'
export { InstagramIcon }
