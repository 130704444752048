// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const ShopifyIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'shopify'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M18 1.44377L16.5889 6.38958C16.5889 6.38958 14.992 5.70267 13.0981 5.84005C10.313 6.01178 10.313 7.62604 10.313 8.03819C10.4615 10.2363 16.7374 10.7172 17.1088 15.9034C17.3687 19.9906 14.7692 22.7726 11.0186 22.9787C6.48806 23.2535 4 20.7805 4 20.7805L4.96552 17.0025C4.96552 17.0025 7.45358 18.7541 9.45889 18.6168C10.7586 18.5481 11.2414 17.552 11.2042 16.8651C11.0186 13.98 5.8939 14.1518 5.55968 9.41203C5.29974 5.4279 8.12202 1.37507 14.3607 1.03161C16.7745 0.859884 18 1.44377 18 1.44377Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
ShopifyIcon.displayName = 'ShopifyIcon'
export { ShopifyIcon }
