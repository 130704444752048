// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const OrderIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'order'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M2.00781 2H4.00382L6.65851 14.4511C6.75589 14.9071 7.00848 15.3148 7.37279 15.6039C7.73711 15.893 8.19035 16.0455 8.65452 16.0351H18.415C18.8693 16.0343 19.3097 15.878 19.6635 15.5918C20.0174 15.3056 20.2634 14.9068 20.3611 14.4612L22.0078 7.01253H5.07168M8.94394 20.9975C8.94394 21.5512 8.49712 22 7.94594 22C7.39475 22 6.94793 21.5512 6.94793 20.9975C6.94793 20.4438 7.39475 19.995 7.94594 19.995C8.49712 19.995 8.94394 20.4438 8.94394 20.9975ZM19.922 20.9975C19.922 21.5512 19.4752 22 18.924 22C18.3728 22 17.926 21.5512 17.926 20.9975C17.926 20.4438 18.3728 19.995 18.924 19.995C19.4752 19.995 19.922 20.4438 19.922 20.9975Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
OrderIcon.displayName = 'OrderIcon'
export { OrderIcon }
