// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const SpotifyIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'spotify'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M18.5068 10.7517C14.9611 8.64595 9.11242 8.45237 5.72754 9.47969C5.18384 9.6446 4.6091 9.33783 4.44448 8.79422C4.27977 8.25032 4.58625 7.67588 5.13015 7.51067C9.01588 6.33124 15.475 6.55901 19.557 8.98209C20.046 9.27241 20.2062 9.9038 19.9165 10.3918C19.6265 10.8808 18.9945 11.042 18.5067 10.7517H18.5068ZM18.3907 13.8705C18.1419 14.2742 17.6141 14.4008 17.211 14.1529C14.255 12.3359 9.74726 11.8095 6.24997 12.8711C5.7965 13.008 5.31742 12.7525 5.1797 12.2997C5.11404 12.082 5.13738 11.8472 5.24458 11.6467C5.35178 11.4463 5.53411 11.2965 5.75158 11.2302C9.74667 10.0178 14.7133 10.6049 18.1085 12.6915C18.5117 12.9397 18.6385 13.4678 18.3907 13.8705ZM17.0447 16.8658C16.9978 16.9427 16.9363 17.0097 16.8635 17.0628C16.7907 17.1159 16.7082 17.1542 16.6206 17.1754C16.5331 17.1966 16.4422 17.2003 16.3532 17.1863C16.2642 17.1723 16.1788 17.1409 16.102 17.0939C13.5189 15.5152 10.2676 15.1587 6.43863 16.0333C6.35089 16.0534 6.26006 16.056 6.17133 16.0409C6.0826 16.0259 5.9977 15.9935 5.92148 15.9456C5.84527 15.8977 5.77923 15.8353 5.72714 15.7619C5.67505 15.6886 5.63793 15.6056 5.6179 15.5179C5.59772 15.4301 5.59506 15.3392 5.61007 15.2504C5.62507 15.1616 5.65744 15.0767 5.70533 15.0004C5.75322 14.9241 5.81568 14.8581 5.88914 14.806C5.9626 14.7539 6.04561 14.7168 6.13343 14.6969C10.3236 13.7391 13.9178 14.1513 16.8172 15.923C16.8941 15.9699 16.9609 16.0316 17.0139 16.1043C17.067 16.1771 17.1052 16.2597 17.1263 16.3472C17.1474 16.4348 17.1511 16.5256 17.1371 16.6146C17.1231 16.7036 17.0917 16.7889 17.0448 16.8658H17.0447ZM11.9998 1C5.92497 1 1 5.92477 1 11.9998C1 18.0754 5.92497 23 11.9999 23C18.0751 23 23 18.0754 23 11.9998C23 5.92487 18.0752 1 11.9999 1"
          fill="currentColor"
        />
      </svg>
    )
  },
)
SpotifyIcon.displayName = 'SpotifyIcon'
export { SpotifyIcon }
