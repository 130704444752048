// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const YoutubeIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'youtube'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.7605 7.14823C23.7605 7.14823 23.5259 5.46864 22.8061 4.72877C21.8939 3.75817 20.8707 3.75357 20.4016 3.69669C17.0432 3.44998 12.0053 3.44998 12.0053 3.44998H11.9947C11.9947 3.44998 6.95707 3.44998 3.5984 3.69669C3.12933 3.75357 2.10667 3.75817 1.19387 4.72877C0.474133 5.46864 0.24 7.14823 0.24 7.14823C0.24 7.14823 0 9.12058 0 11.0929V12.9423C0 14.9144 0.24 16.8867 0.24 16.8867C0.24 16.8867 0.474133 18.5663 1.19387 19.3062C2.10667 20.2771 3.30587 20.2459 3.84 20.3477C5.76027 20.5349 12 20.5928 12 20.5928C12 20.5928 17.0432 20.585 20.4016 20.3385C20.8707 20.2817 21.8939 20.2771 22.8061 19.3062C23.5259 18.5663 23.7605 16.8867 23.7605 16.8867C23.7605 16.8867 24 14.9144 24 12.9423V11.0929C24 9.12058 23.7605 7.14823 23.7605 7.14823ZM9.5224 15.1828L9.52133 8.33467L16.0061 11.7705L9.5224 15.1828Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
YoutubeIcon.displayName = 'YoutubeIcon'
export { YoutubeIcon }
