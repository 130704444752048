import React, { useCallback, useState, useEffect } from 'react'
import * as ToastPrimitive from '@radix-ui/react-toast'

import { ToastEvent, ToastId, ToastState, ToasterToast } from './ToastState'
import { Button } from '../Button'

import { Toast } from './Toast'

import styles from './Toaster.module.css'

type InternalToast = ToasterToast & {
  dismissed?: boolean
}
function Toaster() {
  const [toasts, setToasts] = useState<InternalToast[]>([])

  const dismissToast = useCallback((id: ToastId) => {
    setToasts((toasts) =>
      toasts.map((toast) =>
        toast.id === id ? { ...toast, dismissed: true } : toast,
      ),
    )
  }, [])

  useEffect(() => {
    function subscriber(event: ToastEvent) {
      if (event.type === 'dismiss') {
        dismissToast(event.id)
        return
      }

      if (event.type === 'dismissAll') {
        setToasts((toasts) =>
          toasts.map((toast) => ({ ...toast, dismissed: true })),
        )
        return
      }

      // push event
      const toast = event.toast

      setToasts((existingToasts) => {
        const existingToastIndex = existingToasts.findIndex(
          (existingToast) => existingToast.id === toast.id,
        )

        // update the toast if it already exists
        if (existingToastIndex > -1) {
          return existingToasts.map((item, index) =>
            index !== existingToastIndex ? item : { ...item, ...toast },
          )
        }

        // add the toast if it doesn't exist
        return [toast, ...existingToasts]
      })
    }

    return ToastState.subscribe(subscriber)
  }, [dismissToast])

  return (
    <ToastPrimitive.Provider>
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          variant={toast.variant}
          open={!toast.dismissed}
          duration={toast.duration}
          dismissible={toast.dismissible}
          onDismiss={() => {
            dismissToast(toast.id)
            toast.onDismiss?.(toast.id)
          }}
        >
          {toast.message}
          {toast.action && (
            <Toast.Actions>
              <Button variant="secondary" onClick={toast.action.onClick}>
                {toast.action.label}
              </Button>
            </Toast.Actions>
          )}
        </Toast>
      ))}
      <ToastPrimitive.Viewport className={styles.viewport} />
    </ToastPrimitive.Provider>
  )
}

Toaster.displayName = 'Toaster'

export { Toaster }
