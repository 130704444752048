// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const CanvaIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'canva'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.22183 19.7782C6.28473 21.8411 9.08262 23 12 23C14.9174 23 17.7153 21.8411 19.7782 19.7782C21.8411 17.7153 23 14.9174 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1C9.08262 1 6.28473 2.15893 4.22183 4.22183C2.15893 6.28473 1 9.08262 1 12C1 14.9174 2.15893 17.7153 4.22183 19.7782ZM16.3582 13.9118C16.4368 13.9903 16.4897 14.0908 16.51 14.2C16.5438 14.3906 16.5045 14.587 16.4 14.75L16.29 14.97C15.8222 15.7612 15.1833 16.4378 14.42 16.95C13.87 17.39 12.88 17.83 11.67 17.83C10.7557 17.8067 9.87139 17.4991 9.14002 16.95C8.48002 16.4 7.93002 15.52 7.60002 14.42C7.29661 12.9758 7.42715 11.4742 7.9752 10.1041C8.52326 8.73391 9.46434 7.5565 10.68 6.71998C11.23 6.38998 11.89 6.27998 12.66 6.27998C13.54 6.27998 14.42 6.60998 14.86 7.37998C15.19 7.92998 15.19 8.80998 14.86 9.35998C14.42 9.90998 14.09 10.02 13.76 9.90998C13.6529 9.84217 13.5725 9.73945 13.5324 9.61918C13.4923 9.49891 13.495 9.36849 13.54 9.24998C13.98 8.58998 13.87 8.14998 13.54 7.70998C13.4547 7.58182 13.3407 7.47531 13.207 7.39893C13.0734 7.32255 12.9237 7.2784 12.77 7.26998C12.11 7.26998 11.56 7.70998 11.01 8.47998C9.91002 9.79998 9.14002 12.22 9.58002 14.09C9.91002 15.19 10.68 16.4 12.11 16.4C12.55 16.4 13.1 16.29 13.54 16.07C14.53 15.52 14.86 15.19 15.41 14.42L15.63 13.98C15.6731 13.9037 15.738 13.8419 15.8164 13.8027C15.8948 13.7635 15.9831 13.7487 16.07 13.76C16.1792 13.7803 16.2797 13.8332 16.3582 13.9118Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
CanvaIcon.displayName = 'CanvaIcon'
export { CanvaIcon }
