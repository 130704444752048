import React from 'react'
import clsx from 'clsx'
import type * as Polymorphic from '@radix-ui/react-polymorphic'

import { Button, ButtonProps } from '../Button'
import { VisuallyHidden } from '../VisuallyHidden'
import { Tooltip } from '../Tooltip'

import styles from './IconButton.module.css'

type IconButtonProps = ButtonProps & {
  /**
   * Icon displayed in the Button
   */
  children: React.ReactElement

  /**
   * Label for the Button
   */
  label: string

  /**
   * Tooltip to be displayed
   */
  tooltip?: React.ReactNode
}

type PolymorphicIconButton = Polymorphic.ForwardRefComponent<
  'button',
  IconButtonProps
>

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      children,
      label,
      tooltip,
      size = 'medium',
      variant = 'secondary',
      className,
      ...props
    }: IconButtonProps,
    forwardedRef,
  ) => {
    const iconButton = (
      <Button
        variant={variant}
        size={size}
        className={clsx(styles.base, styles[size], className)}
        ref={forwardedRef}
        {...props}
      >
        {children}
        <VisuallyHidden>{label}</VisuallyHidden>
      </Button>
    )

    if (tooltip) {
      return (
        <Tooltip content={tooltip} side="top" delay={800}>
          {iconButton}
        </Tooltip>
      )
    }

    return iconButton
  },
) as PolymorphicIconButton

IconButton.displayName = 'IconButton'

export { IconButton }
export type { IconButtonProps }
