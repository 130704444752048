// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const XRetweetIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'xretweet'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.87358 2.25L10.6425 7.17159C11.1274 7.67193 11.1397 8.49622 10.67 9.0127C10.2003 9.52918 9.42654 9.54226 8.94172 9.04193L7.22941 7.27482L7.2294 18.3852H12.9462C13.6213 18.3852 14.1685 18.9681 14.1685 19.6872C14.1685 20.4063 13.6213 20.9892 12.9462 20.9892L6.63801 20.9892C5.65147 20.9892 4.78496 20.1535 4.78496 19.0362L4.78496 6.99906L3.07265 8.76617C2.58783 9.26651 1.81406 9.25342 1.34439 8.73695C0.874715 8.22047 0.886998 7.39617 1.37182 6.89583L5.87358 2.25ZM9.83156 4.0628C9.83156 3.34371 10.3788 2.76078 11.0538 2.76078H17.362C18.3486 2.76078 19.2151 3.59653 19.2151 4.71382V16.7509L20.9274 14.9838C21.4122 14.4835 22.186 14.4966 22.6556 15.0131C23.1253 15.5295 23.113 16.3538 22.6282 16.8542L18.1264 21.5L13.3575 16.5784C12.8727 16.0781 12.8604 15.2538 13.33 14.7373C13.7997 14.2208 14.5735 14.2077 15.0583 14.7081L16.7706 16.4752V5.36483H11.0538C10.3788 5.36483 9.83156 4.78189 9.83156 4.0628Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
XRetweetIcon.displayName = 'XRetweetIcon'
export { XRetweetIcon }
