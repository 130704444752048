// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const DropboxIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'dropbox'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M12.0146 5.76176L6.50732 9.52351L12.0146 13.2853L6.50732 17.047L1 13.2539L6.50732 9.49216L1 5.76176L6.50732 2L12.0146 5.76176ZM6.47803 18.2382L11.9854 14.4765L17.4927 18.2382L11.9854 22L6.47803 18.2382ZM12.0146 13.2539L17.522 9.49216L12.0146 5.76176L17.4927 2L23 5.76176L17.4927 9.52351L23 13.2853L17.4927 17.047L12.0146 13.2539Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
DropboxIcon.displayName = 'DropboxIcon'
export { DropboxIcon }
