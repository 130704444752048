import React from 'react'
import clsx from 'clsx'

import { isHexColor } from '../../helpers/colors'
import { generateTagCssProperties } from './helpers'

import styles from './Tag.module.css'

const tagColors = [
  'purple-light',
  'purple',
  'pink-light',
  'pink',
  'red-light',
  'red',
  'orange-light',
  'orange',
  'yellow-light',
  'yellow',
  'green-light',
  'green',
  'teal-light',
  'teal',
  'blue-light',
  'blue',
  'gray-light',
  'gray',
] as const

type TagColor = (typeof tagColors)[number]

type TagProps = {
  /**
   * Label of the tag
   */
  children: string
  /**
   * Color of the tag
   */
  color?: TagColor | string
  /**
   * Additional classname
   */
  className?: string
}

const Tag = React.forwardRef<HTMLDivElement, TagProps>(
  ({ children, color, className, ...props }: TagProps, forwardedRef) => {
    const isPredefinedColor = color && tagColors.includes(color as TagColor)
    const isCustomColor = color && isHexColor(color as string)

    return (
      <span
        className={clsx(
          styles.base,
          isPredefinedColor && styles[color],
          className,
        )}
        style={isCustomColor ? generateTagCssProperties(color) : undefined}
        ref={forwardedRef}
        {...props}
      >
        {children}
      </span>
    )
  },
)

Tag.displayName = 'Tag'

export { Tag }
export type { TagProps, TagColor }
