type IconSize = 'xsmall' | 'small' | 'medium' | 'large' | number

type IconColor =
  | 'default'
  | 'subtle'
  | 'inverted'
  | 'brand'
  | 'critical'
  | 'warning'
  | 'success'
  | 'upgrade'

type IconProps = Omit<
  React.SVGAttributes<SVGElement>,
  'children' | 'stroke'
> & {
  /**
   * Size of the icon
   */
  size?: IconSize
  /**
   * Stroke width of the icon
   */
  stroke?: number
  /**
   * Color of the icon
   */
  color?: 'currentColor' | IconColor
}

type IconComponent = React.ForwardRefExoticComponent<IconProps>
type IconComponentRef = React.ForwardedRef<SVGSVGElement>

const DEFAULT_ICON_SIZE = 16

const sizeMapping = {
  xsmall: 12,
  small: 16,
  medium: 24,
  large: 40,
}

const computeSize = (size: IconSize = 'small'): number => {
  if (typeof size === 'number') {
    return size
  }

  return sizeMapping[size]
}

const computeStrokeWidth = (size: number, stroke?: number): number => {
  if (stroke) {
    return stroke
  }

  if (size <= 16) {
    return 2.2
  }

  if (size >= 80) {
    return 1
  }

  if (size >= 64) {
    return 1.5
  }

  return 2
}

const getIconStyle = (
  { size = 'small' }: { size?: IconSize; stroke?: number },
  passedStyle?: React.CSSProperties,
): React.CSSProperties => {
  let style: Record<string, string> = {}

  const computedSize = computeSize(size)
  if (computedSize !== DEFAULT_ICON_SIZE) {
    style['--icon-size'] = `${computedSize}px`
  }

  if (passedStyle) {
    style = Object.assign(style, passedStyle)
  }

  return style as React.CSSProperties
}

export {
  computeSize,
  computeStrokeWidth,
  getIconStyle,
  IconProps,
  IconSize,
  IconColor,
  IconComponent,
  IconComponentRef,
}
