// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const YtShortIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'yt-short'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M17.5505 10.4729C17.2205 10.2874 16.9049 10.0877 16.5321 9.87371C16.9049 9.64551 17.2494 9.44578 17.5793 9.26028C19.1142 8.43288 19.9607 6.74985 19.7023 5.03812C19.3292 2.22832 16.0728 0.602457 13.6056 2.00012C11.0809 3.44095 8.55623 4.85309 6.06016 6.33638C4.08058 7.52032 3.79359 9.08925 4.10921 10.9435C4.31008 12.099 5.08469 12.9546 6.1031 13.5396C6.46166 13.7393 6.80591 13.9391 7.19333 14.1673C6.76297 14.4239 6.37578 14.6521 5.95973 14.8661C4.12352 16.0216 3.49229 18.3892 4.52526 20.3007C5.61548 22.312 8.14018 23.0822 10.1627 21.9982C12.3432 20.7715 14.5236 19.5162 16.6897 18.2611C17.2062 17.9615 17.7513 17.6906 18.2103 17.3196C20.5198 15.4081 20.1756 11.9422 17.5505 10.4729ZM9.84732 15.1515V8.91821L15.2698 12.0278L9.84732 15.1515Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
YtShortIcon.displayName = 'YtShortIcon'
export { YtShortIcon }
