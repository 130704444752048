import React from 'react'
import {
  useLightboxState,
  PluginProps,
  MODULE_CONTROLLER,
  createModule,
} from 'yet-another-react-lightbox'
import { Text } from '../Text'
import styles from './Lightbox.module.css'

const PLUGIN_COUNTER = 'counter'
const CounterComponent = () => {
  const { slides, currentIndex } = useLightboxState()
  if (slides.length <= 1) return null

  return (
    <div className={styles.counter}>
      <Text as="label" color="inverted" weight="medium">
        {currentIndex + 1}/{slides.length}
      </Text>
    </div>
  )
}

export const Counter = ({ addChild }: PluginProps) => {
  addChild(MODULE_CONTROLLER, createModule(PLUGIN_COUNTER, CounterComponent))
}
