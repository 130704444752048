import { SplitClient, SplitFactoryProvider } from '@splitsoftware/splitio-react'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'

import trackImpression from './trackImpressions'
import { ORG_DATA_QUERY } from './queries/currentOrganization'
import { LOCATION_DATA_QUERY } from './queries/location'
import getEnvironmentFromURL from './getEnvironmentFromURL'
import getAttributesFromAccount from './getAttributesFromAccount'

import type { OrganizationAttributes, OrgChangeEvent } from './types'

export const SPLITIO_PROD_CLIENT_KEY = 'p80t4a0uumurkoiu03n901vds197chq8bssp'
export const SPLITIO_LOCAL_CLIENT_KEY = 'cv7k6ubsjas041tvs0869rd69l5i26svbg8r'
export const SPLITIO_STAGING_CLIENT_KEY = '7ep659kbp3peet2964pukms48g4ubsn83b9s'

const splitFactory: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: SPLITIO_PROD_CLIENT_KEY,
    key: 'key', // Dummy value, replaced when we create a client later
    trafficType: 'organization',
  },

  // Track experiments to Segment
  impressionListener: {
    logImpression: trackImpression,
  },
}

/** @todo Ideally pull this from the window.appshell global. Couldn't do it here due to race condition. */
const ORG_CHANGE_EVENT = 'appshell__organization_event'

export const OrganizationAttributeContext =
  React.createContext<OrganizationAttributes | null>(null)

export function FeaturesWrapper({
  mockSplits,
  children,
  environment: environmentProp,
}: {
  mockSplits?: SplitIO.MockedFeaturesMap
  children: React.ReactNode
  environment?: string
}) {
  // Use no-cache to see if it helps avoid conflicts with other Apollo clients
  const { data } = useQuery(ORG_DATA_QUERY, { fetchPolicy: 'no-cache' })
  const { data: locationData } = useQuery(LOCATION_DATA_QUERY)

  const initialOrgId = data?.account?.currentOrganization?.id
  const [switchedOrgId, setSwitchedOrgId] = useState<string | null>(null)
  const currentOrgId = switchedOrgId || initialOrgId
  const [organizationAttributes, setOrganizationAttributes] =
    useState<OrganizationAttributes | null>(null)

  let environment = 'production' // Checked later

  useEffect(() => {
    setOrganizationAttributes(
      getAttributesFromAccount(data?.account, locationData),
    )
  }, [currentOrgId, data, locationData])

  useEffect(() => {
    function handleOrgSwitch(event: OrgChangeEvent) {
      setSwitchedOrgId(event.detail.organizationId)
    }
    window.addEventListener(ORG_CHANGE_EVENT, handleOrgSwitch as EventListener)
    return function cleanup() {
      window.removeEventListener(
        ORG_CHANGE_EVENT,
        handleOrgSwitch as EventListener,
      )
    }
  }, [])

  // Support mock / localhost mode
  // https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#localhost-mode
  if (mockSplits) {
    splitFactory.core.authorizationKey = 'localhost'
    splitFactory.features = mockSplits
  } else {
    // Support environments (either forced by prop, or detected)
    environment = environmentProp || getEnvironmentFromURL()

    if (environment === 'staging') {
      splitFactory.core.authorizationKey = SPLITIO_STAGING_CLIENT_KEY
    }
    if (environment === 'local') {
      splitFactory.core.authorizationKey = SPLITIO_LOCAL_CLIENT_KEY
    }
    if (environment === 'production') {
      splitFactory.core.authorizationKey = SPLITIO_PROD_CLIENT_KEY
    }
  }

  return (
    <SplitFactoryProvider config={splitFactory}>
      <SplitClient splitKey={currentOrgId} trafficType="organization">
        <OrganizationAttributeContext.Provider value={organizationAttributes}>
          {children}
        </OrganizationAttributeContext.Provider>
      </SplitClient>
    </SplitFactoryProvider>
  )
}
