// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const SoundcloudIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'soundcloud'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M19.4737 18C21.4212 18 23 16.4198 23 14.4706C23 12.5213 21.4212 10.9412 19.4737 10.9412C18.9666 10.9412 18.4845 11.0483 18.0488 11.2412C17.8409 8.31183 15.4007 6 12.421 6C11.6922 6 10.9956 6.13832 10.3561 6.39017C10.1051 6.48902 9.95257 6.73898 9.95257 7.00895V16.5882C9.95257 17.3679 10.5841 18 11.3631 18H19.4737Z"
          fill="currentColor"
        />
        <path
          d="M8.18953 7.97647C8.18953 7.66459 8.44214 7.41176 8.75375 7.41176C9.06536 7.41176 9.31796 7.66459 9.31796 7.97647V17.4353C9.31796 17.7472 9.06536 18 8.75375 18C8.44214 18 8.18953 17.7472 8.18953 17.4353V7.97647Z"
          fill="currentColor"
        />
        <path
          d="M6.35584 10.5971C6.35584 10.2852 6.60845 10.0324 6.92005 10.0324C7.23166 10.0324 7.48427 10.2852 7.48427 10.5971V17.4353C7.48427 17.7472 7.23166 18 6.92005 18C6.60845 18 6.35584 17.7472 6.35584 17.4353V10.5971Z"
          fill="currentColor"
        />
        <path
          d="M4.66317 9.75C4.66317 9.43812 4.91577 9.18529 5.22738 9.18529C5.53898 9.18529 5.79159 9.43812 5.79159 9.75V17.2941C5.79159 17.606 5.53898 17.8588 5.22738 17.8588C4.91577 17.8588 4.66317 17.606 4.66317 17.2941V9.75Z"
          fill="currentColor"
        />
        <path
          d="M2.83367 12.0706C2.83367 11.7587 3.08628 11.5059 3.39788 11.5059C3.70949 11.5059 3.9621 11.7587 3.9621 12.0706V17.2941C3.9621 17.606 3.70949 17.8588 3.39788 17.8588C3.08628 17.8588 2.83367 17.606 2.83367 17.2941V12.0706Z"
          fill="currentColor"
        />
        <path
          d="M1 12.7765C1 12.4646 1.25261 12.2118 1.56421 12.2118C1.87582 12.2118 2.12843 12.4646 2.12843 12.7765V15.8824C2.12843 16.1942 1.87582 16.4471 1.56421 16.4471C1.25261 16.4471 1 16.1942 1 15.8824V12.7765Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
SoundcloudIcon.displayName = 'SoundcloudIcon'
export { SoundcloudIcon }
