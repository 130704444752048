import React from 'react'

import styles from './StatusDot.module.css'
import clsx from 'clsx'

type StatusDotProps = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * Additional CSS classes
   */
  className?: string
}

/**
 * A small dot used to indicate status
 */
const StatusDot = React.forwardRef<HTMLDivElement, StatusDotProps>(
  ({ className, ...props }: StatusDotProps, forwardedRef) => {
    return (
      <div
        {...props}
        className={clsx(styles.statusDot, className)}
        ref={forwardedRef}
      />
    )
  },
)

StatusDot.displayName = 'StatusDot'

export { StatusDot }
export type { StatusDotProps }
