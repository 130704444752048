// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const PanelRightCloseIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'panel-right-close'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)

    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <rect
          width="18"
          height="18"
          x="3"
          y="3"
          rx="2"
          stroke="currentColor"
          style={{
            viewTransitionName: 'icon-panel-right-close__window',
          }}
        />
        <path
          d="M15 3v18"
          stroke="currentColor"
          style={{
            viewTransitionName: 'icon-panel-right-close__separator',
          }}
        />
        <path
          d="m8 9 3 3-3 3"
          stroke="currentColor"
          style={{
            viewTransitionName: 'icon-panel-right-close__arrow',
          }}
        />
      </svg>
    )
  },
)
PanelRightCloseIcon.displayName = 'PanelRightCloseIcon'
export { PanelRightCloseIcon }
