// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const ChevronUpIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'chevron-up'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M18 15L12 9L6 15"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
ChevronUpIcon.displayName = 'ChevronUpIcon'
export { ChevronUpIcon }
