// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const MastodonIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'mastodon'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.68 17.7682C19.6352 17.4391 22.2083 15.7407 22.5317 14.1888C23.0412 11.7441 22.9992 8.22295 22.9992 8.22295C22.9992 3.45048 19.647 2.05159 19.647 2.05159C17.9569 1.3275 15.0545 1.02299 12.0393 1H11.9652C8.94993 1.02299 6.04953 1.3275 4.35923 2.05159C4.35923 2.05159 1.00697 3.45048 1.00697 8.22295C1.00697 8.51228 1.00537 8.81659 1.0037 9.13339C0.999066 10.0132 0.993924 10.9893 1.02121 12.0082C1.14294 16.6754 1.93853 21.2752 6.56468 22.4173C8.69769 22.944 10.529 23.0542 12.004 22.9786C14.6787 22.8402 16.1801 22.0882 16.1801 22.0882L16.092 20.278C16.092 20.278 14.1806 20.8402 12.0339 20.7717C9.90716 20.7036 7.66194 20.5577 7.31796 18.1221C7.28619 17.9081 7.2703 17.6794 7.2703 17.4391C7.2703 17.4391 9.35808 17.9151 12.004 18.0282C13.6218 18.0974 15.139 17.9398 16.68 17.7682ZM19.0451 14.3719V8.59319C19.0451 7.41216 18.7227 6.47364 18.0753 5.7793C17.4074 5.08495 16.5329 4.72901 15.4475 4.72901C14.1914 4.72901 13.2402 5.1793 12.6115 6.08001L12 7.03595L11.3887 6.08001C10.7598 5.1793 9.80867 4.72901 8.5527 4.72901C7.46714 4.72901 6.59263 5.08495 5.92488 5.7793C5.27732 6.47364 4.95494 7.41216 4.95494 8.59319V14.3719H7.40932V8.76304C7.40932 7.58071 7.94264 6.98059 9.0094 6.98059C10.1889 6.98059 10.7801 7.69248 10.7801 9.10014V12.1702H13.22V9.10014C13.22 7.69248 13.8112 6.98059 14.9906 6.98059C16.0574 6.98059 16.5907 7.58071 16.5907 8.76304V14.3719H19.0451Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
MastodonIcon.displayName = 'MastodonIcon'
export { MastodonIcon }
