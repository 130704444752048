// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const TiktokIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'tiktok'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1008 1C16.4471 4.1219 18.1096 5.98316 21 6.18117V9.69248C19.3249 9.86409 17.8577 9.28987 16.1511 8.20743V14.7746C16.1511 23.1173 7.47359 25.7244 3.98494 19.7446C1.74313 15.8967 3.11592 9.14466 10.3073 8.87406V12.5768C9.75948 12.6692 9.17384 12.8144 8.63858 13.0058C7.03908 13.5734 6.13229 14.636 6.38417 16.5105C6.86906 20.101 13.1537 21.1637 12.631 14.1476V1.0066H16.1008V1Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
TiktokIcon.displayName = 'TiktokIcon'
export { TiktokIcon }
