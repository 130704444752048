// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const MessageCircleHeartIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'message-circle-heart'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M15.7999 9.19999C15.3326 8.74193 14.7043 8.48535 14.0499 8.48535C13.3955 8.48535 12.7672 8.74193 12.2999 9.19999L11.9999 9.59999L11.6499 9.29999C11.4206 9.05186 11.142 8.85447 10.8318 8.72054C10.5217 8.5866 10.1869 8.51908 9.84914 8.52233C9.51133 8.52557 9.17794 8.59951 8.87043 8.73938C8.56292 8.87926 8.2881 9.08196 8.06366 9.33446C7.83922 9.58695 7.67013 9.88364 7.56728 10.2054C7.46443 10.5272 7.43009 10.867 7.46647 11.2028C7.50285 11.5387 7.60915 11.8632 7.77852 12.1555C7.94789 12.4478 8.17659 12.7014 8.44989 12.9L12.0499 16.4L15.6499 12.9C16.8499 11.7 16.7499 10.2 15.8499 9.19999M7.9 20C9.80858 20.979 12.0041 21.2442 14.0909 20.7478C16.1777 20.2513 18.0186 19.0258 19.2818 17.2922C20.545 15.5585 21.1474 13.4307 20.9806 11.2921C20.8137 9.1536 19.8886 7.14496 18.3718 5.62818C16.855 4.1114 14.8464 3.18624 12.7078 3.0194C10.5693 2.85257 8.44147 3.45503 6.70782 4.71823C4.97417 5.98143 3.74869 7.8223 3.25222 9.9091C2.75575 11.9959 3.02094 14.1914 4 16.1L2 22L7.9 20Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
MessageCircleHeartIcon.displayName = 'MessageCircleHeartIcon'
export { MessageCircleHeartIcon }
