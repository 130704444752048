import { useContext } from 'react'
import { useSplitTreatments } from '@splitsoftware/splitio-react'
import { OrganizationAttributeContext } from './FeaturesWrapper'

export function useSplit<T = Record<string, never>>(
  name: string,
  attributes = {},
): {
  isReady: boolean
  isTimedout: boolean
  split: SplitIO.TreatmentWithConfig
  config: T
  client: SplitIO.IBrowserClient | null
} {
  const orgAttributes = useContext(OrganizationAttributeContext)
  const orgAttributesReady = orgAttributes !== null
  const combinedAttributes = { ...orgAttributes, ...attributes }
  const { treatments, isReady, isTimedout, client } = useSplitTreatments({
    names: [name],
    attributes: combinedAttributes,
  })
  const split = treatments[name] || { treatment: 'off' }
  let config = {} as T
  if (split.config) {
    config = JSON.parse(split.config) as T
  }
  return {
    isReady: orgAttributesReady && isReady,
    isTimedout,
    split,
    config,
    client,
  }
}
