import * as React from 'react'
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog'
import clsx from 'clsx'

import usePortalContainer from '../../helpers/usePortalContainer'
import { Heading } from '../Heading'
import { Paragraph, ParagraphProps } from '../Paragraph'

import styles from './AlertDialog.module.css'

/**
 * AlertDialog
 */
type AlertDialogProps = React.ComponentProps<
  typeof AlertDialogPrimitive.Root
> & {
  /**
   * Content to render inside the alert dialog
   */
  children?: React.ReactNode
  /**
   * Whether the alert dialog is modal or not
   * @default true
   */
  modal?: boolean
  /**
   * Whether the alert dialog is open or not
   */
  open?: boolean
  /**
   * Whether dialog is open by default
   */
  defaultOpen?: boolean
  /**
   * Callback to be called when the alert dialog is closed via clicking on
   * any of the actions or pressing the escape key
   */
  onOpenChange?: (open: boolean) => void
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  ...props
}: AlertDialogProps) => {
  return <AlertDialogPrimitive.Root {...props} />
}

AlertDialog.displayName = 'AlertDialog'

/**
 * AlertDialog Content
 */
type AlertDialogContentElement = React.ElementRef<
  typeof AlertDialogPrimitive.Content
>
type AlertDialogContentProps = React.ComponentPropsWithoutRef<
  typeof AlertDialogPrimitive.Content
>

const AlertDialogContent = React.forwardRef<
  AlertDialogContentElement,
  AlertDialogContentProps
>(
  (
    { className, children, ...props }: AlertDialogContentProps,
    forwardedRef,
  ) => {
    const container = usePortalContainer()

    const handlePropagation = (event: React.MouseEvent) => {
      event.stopPropagation()
    }

    return (
      <AlertDialogPrimitive.Portal container={container}>
        <AlertDialogPrimitive.Overlay
          className={styles.overlay}
          onClick={handlePropagation}
        >
          <AlertDialogPrimitive.Content
            {...props}
            ref={forwardedRef}
            className={clsx(styles.content, className)}
          >
            {children}
          </AlertDialogPrimitive.Content>
        </AlertDialogPrimitive.Overlay>
      </AlertDialogPrimitive.Portal>
    )
  },
)

AlertDialogContent.displayName = 'AlertDialog.Content'

/**
 * AlertDialog Trigger
 */
type AlertDialogTriggerElement = React.ElementRef<
  typeof AlertDialogPrimitive.Trigger
>
type AlertDialogTriggerProps = Omit<
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Trigger>,
  'asChild'
>

const AlertDialogTrigger = React.forwardRef<
  AlertDialogTriggerElement,
  AlertDialogTriggerProps
>((props, forwardedRef) => (
  <AlertDialogPrimitive.Trigger {...props} ref={forwardedRef} asChild />
))

AlertDialogTrigger.displayName = 'AlertDialog.Trigger'

/**
 * AlertDialog Title
 */
type AlertDialogTitleElement = React.ElementRef<'h3'>
type AlertDialogTitleProps = React.ComponentPropsWithoutRef<'h3'>

const AlertDialogTitle = React.forwardRef<
  AlertDialogTitleElement,
  AlertDialogTitleProps
>(({ className, children, ...props }, forwardedRef) => (
  <AlertDialogPrimitive.Title asChild>
    <Heading
      as="h3"
      size="small"
      className={clsx(styles.title, className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Heading>
  </AlertDialogPrimitive.Title>
))

AlertDialogTitle.displayName = 'AlertDialog.Title'

/**
 * AlertDialog Description
 */
type AlertDialogDescriptionElement = React.ElementRef<'p'>
type AlertDialogDescriptionProps = ParagraphProps

const AlertDialogDescription = React.forwardRef<
  AlertDialogDescriptionElement,
  AlertDialogDescriptionProps
>(({ className, children, ...props }, forwardedRef) => (
  <AlertDialogPrimitive.Description asChild>
    <Paragraph
      className={clsx(styles.description, className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Paragraph>
  </AlertDialogPrimitive.Description>
))

AlertDialogDescription.displayName = 'AlertDialog.Description'

/**
 * AlertDialog Actions
 */
type AlertDialogActionsElement = React.ElementRef<'div'>
type AlertDialogActionsProps = React.ComponentPropsWithoutRef<'div'>

const AlertDialogActions = React.forwardRef<
  AlertDialogActionsElement,
  AlertDialogActionsProps
>(({ className, children, ...props }, forwardedRef) => (
  <div
    {...props}
    className={clsx(styles.actions, className)}
    ref={forwardedRef}
  >
    {children}
  </div>
))

AlertDialogActions.displayName = 'AlertDialog.Actions'

/**
 * AlertDialog Cancel
 */
type AlertDialogCancelElement = React.ElementRef<
  typeof AlertDialogPrimitive.Cancel
>
type AlertDialogCancelProps = Omit<
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Cancel>,
  'asChild'
>

const AlertDialogCancel = React.forwardRef<
  AlertDialogCancelElement,
  AlertDialogCancelProps
>((props, forwardedRef) => (
  <AlertDialogPrimitive.Cancel {...props} ref={forwardedRef} asChild />
))

AlertDialogCancel.displayName = 'AlertDialog.Cancel'

/**
 * AlertDialog Action
 */
type AlertActionElement = React.ElementRef<typeof AlertDialogPrimitive.Action>
type AlertDialogActionProps = Omit<
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Action>,
  'asChild'
>

const AlertDialogAction = React.forwardRef<
  AlertActionElement,
  AlertDialogActionProps
>((props, forwardedRef) => (
  <AlertDialogPrimitive.Action {...props} ref={forwardedRef} asChild />
))

AlertDialogAction.displayName = 'AlertDialog.Action'

/**
 * AlertDialog Separator
 */
type AlertDialogSeparatorElement = React.ElementRef<'div'>
type AlertDialogSeparatorProps = React.ComponentPropsWithoutRef<'div'> & {
  children?: never
}

const AlertDialogSeparator = React.forwardRef<
  AlertDialogSeparatorElement,
  AlertDialogSeparatorProps
>(({ className, ...props }, forwardedRef) => (
  <div
    {...props}
    className={clsx(styles.separator, className)}
    ref={forwardedRef}
  />
))

AlertDialogSeparator.displayName = 'AlertDialog.Separator'

const AlertDialogBody = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, forwardedRef) => (
  <div {...props} className={clsx(styles.body, className)} ref={forwardedRef} />
))

AlertDialogBody.displayName = 'AlertDialog.Body'

const AlertDialogObject = Object.assign(AlertDialog, {
  Trigger: AlertDialogTrigger,
  Content: AlertDialogContent,
  Title: AlertDialogTitle,
  Description: AlertDialogDescription,
  Actions: AlertDialogActions,
  Cancel: AlertDialogCancel,
  Action: AlertDialogAction,
  Body: AlertDialogBody,
  Separator: AlertDialogSeparator,
})

export { AlertDialogObject as AlertDialog }

export type {
  AlertDialogProps,
  AlertDialogTriggerProps,
  AlertDialogContentProps,
  AlertDialogTitleProps,
  AlertDialogDescriptionProps,
  AlertDialogActionsProps,
  AlertDialogCancelProps,
  AlertDialogActionProps,
  AlertDialogSeparatorProps,
}
