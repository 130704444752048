import React from 'react'
import clsx from 'clsx'

import { LoadingIcon } from '../icons'
import { IconProps } from '../icons/helpers'

import styles from './SimpleSpinner.module.css'

type SimpleSpinnerProps = IconProps

/**
 * A simple spinner component used to indicate loading state
 */
const SimpleSpinner = React.forwardRef<SVGSVGElement, SimpleSpinnerProps>(
  (
    { className, color = 'subtle', ...props }: SimpleSpinnerProps,
    forwardedRef,
  ) => {
    return (
      <LoadingIcon
        ref={forwardedRef}
        color={color}
        className={clsx(styles.spinner, className)}
        {...props}
      />
    )
  },
)

SimpleSpinner.displayName = 'SimpleSpinner'

export { SimpleSpinner }
