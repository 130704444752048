import React from 'react'
import clsx from 'clsx'

import { CloseIcon, CriticalIcon, InfoIcon, WarningIcon } from '../icons'

import styles from './Notice.module.css'
import { UnstyledButton } from '../UnstyledButton'
import { VisuallyHidden } from '../VisuallyHidden'

type NoticeProps = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * Visual variant of the notice
   */
  variant?: 'neutral' | 'error' | 'warning' | 'info'
  className?: string
  children: React.ReactNode
  /**
   * On dismiss callback, when passed a close button will be displayed
   */
  onDismiss?: () => void
  /**
   * Custom icon to render in the notice
   * Set to `false` to disable
   */
  icon?: React.ReactNode | false
}

const iconByVariant = {
  neutral: InfoIcon,
  error: CriticalIcon,
  warning: WarningIcon,
  info: InfoIcon,
}

type NoticeTextProps = React.HTMLAttributes<HTMLParagraphElement> & {
  className?: string
}

const NoticeText = React.forwardRef<HTMLParagraphElement, NoticeTextProps>(
  ({ className, ...props }, forwardedRef) => {
    return (
      <p
        ref={forwardedRef}
        className={clsx(styles.text, className)}
        {...props}
      />
    )
  },
)

NoticeText.displayName = 'Notice.Text'

type NoticeHeadingProps = React.HTMLAttributes<HTMLHeadingElement> & {
  className?: string
}

const NoticeHeading = React.forwardRef<HTMLHeadingElement, NoticeHeadingProps>(
  ({ className, children, ...props }, forwardedRef) => {
    return (
      <h4
        ref={forwardedRef}
        className={clsx(styles.heading, className)}
        {...props}
      >
        {children}
      </h4>
    )
  },
)

NoticeHeading.displayName = 'Notice.Heading'

type NoticeActionsProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: string
}

const NoticeActions = React.forwardRef<HTMLDivElement, NoticeActionsProps>(
  ({ className, children, ...props }, forwardedRef) => {
    return (
      <div
        ref={forwardedRef}
        className={clsx(styles.actions, className)}
        {...props}
      >
        {children}
      </div>
    )
  },
)

NoticeActions.displayName = 'Notice.Actions'

const Notice = React.forwardRef<HTMLDivElement, NoticeProps>(
  (
    {
      variant = 'neutral',
      children,
      className,
      onDismiss,
      icon,
      ...props
    }: NoticeProps,
    forwardedRef,
  ) => {
    const Icon = iconByVariant[variant]

    // if the first child is a string, we assume it's just simple text content
    const isTextOnly = typeof React.Children.toArray(children)[0] === 'string'

    return (
      <div
        ref={forwardedRef}
        role="status"
        className={clsx(styles.notice, styles[variant], className)}
        {...props}
      >
        {icon !== false && (
          <span className={styles.icon}>{icon ?? <Icon />}</span>
        )}
        <div className={styles.content}>
          {isTextOnly ? <NoticeText>{children}</NoticeText> : children}
        </div>
        {onDismiss && (
          <UnstyledButton className={styles.close} onClick={onDismiss}>
            <VisuallyHidden>Dismiss</VisuallyHidden>
            <CloseIcon />
          </UnstyledButton>
        )}
      </div>
    )
  },
)

Notice.displayName = 'Notice'

const NoticeObject = Object.assign(Notice, {
  Text: NoticeText,
  Heading: NoticeHeading,
  Actions: NoticeActions,
})

export {
  NoticeObject as Notice,
  NoticeProps,
  NoticeTextProps,
  NoticeHeadingProps,
}
