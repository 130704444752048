// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const FacebookIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'facebook'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M23 12.0672C23 5.95496 18.0751 1 12 1C5.92486 1 1 5.95496 1 12.0672C1 17.5912 5.02254 22.1697 10.2812 23V15.2663H7.48828V12.0672H10.2812V9.62898C10.2812 6.85525 11.9235 5.32314 14.4361 5.32314C15.6396 5.32314 16.8984 5.53929 16.8984 5.53929V8.26287H15.5114C14.1449 8.26287 13.7188 9.11597 13.7188 9.99119V12.0672H16.7695L16.2818 15.2663H13.7188V23C18.9775 22.1697 23 17.5912 23 12.0672Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
FacebookIcon.displayName = 'FacebookIcon'
export { FacebookIcon }
