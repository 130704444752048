// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const GoogleDriveIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'google-drive'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0055 2.5H8.71537L15.7094 14.4168H22.9995L16.0055 2.5ZM22.9996 15.2533H9.10418L5.49609 21.5H19.3915L22.9996 15.2533ZM8.00958 2.98885L11.7101 9.25403L4.70049 21.184L1 14.9188L8.00958 2.98885Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
GoogleDriveIcon.displayName = 'GoogleDriveIcon'
export { GoogleDriveIcon }
