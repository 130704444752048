// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const AppleMusicIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'apple-music'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M12 1C5.92472 1 1 5.92472 1 12C1 18.0753 5.92472 23 12 23C18.0753 23 23 18.0753 23 12C23 5.92472 18.0753 1 12 1ZM12 2.21408C17.404 2.21408 21.786 6.59605 21.786 12C21.786 17.404 17.404 21.786 12 21.786C6.59601 21.786 2.22321 17.404 2.22321 12C2.22321 6.59605 6.59601 2.21408 12 2.21408Z"
          fill="currentColor"
        />
        <path
          d="M16.714 5.71429L8.98436 7.58153H8.97541V10.3903H8.98436V15.4125C8.6462 15.2307 8.23162 15.1227 7.78621 15.1227C6.63883 15.1227 5.71429 15.8346 5.71429 16.7082C5.71429 17.5821 6.63909 18.2857 7.78621 18.2857C8.93334 18.2857 9.90831 17.5813 9.8674 16.7082L9.84634 16.2601L9.79531 10.1889L15.9033 8.716V14.2613C15.5467 14.0416 15.0915 13.9072 14.5971 13.9072C13.4497 13.9072 12.5248 14.619 12.5248 15.4926C12.5248 16.3665 13.4497 17.0701 14.5971 17.0701C15.7445 17.0701 16.7088 16.3665 16.7088 15.4926C16.7088 15.4844 16.7091 15.4767 16.7088 15.4684L16.714 5.71429Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
AppleMusicIcon.displayName = 'AppleMusicIcon'
export { AppleMusicIcon }
