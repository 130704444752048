import React from 'react'
import clsx from 'clsx'

import styles from './Kbd.module.css'

type KbdProps = React.HTMLAttributes<HTMLElement> & {
  className?: string
  children: React.ReactNode
}

type Platform = 'mac' | 'pc'

const getPlatform = (): Platform => {
  return navigator.userAgent.includes('Macintosh') ? 'mac' : 'pc'
}

export const keyMapping: Record<
  string,
  string | { [key in Platform]: string }
> = {
  mod: { pc: 'Ctrl', mac: '⌘' },
  alt: { pc: 'Alt', mac: '⌥' },
  shift: '⇧',
  tab: '⇥',
  enter: '↵',
  control: { pc: 'Ctrl', mac: '^' },
  del: '⌫',
}

function mapKey(key: string): string {
  const platform = getPlatform()
  const mappedKey = keyMapping[key]

  if (!mappedKey) {
    return key
  }

  if (typeof mappedKey === 'string') {
    return mappedKey
  }

  return mappedKey[platform]
}

const Kbd = React.forwardRef<HTMLElement, KbdProps>(
  ({ children, className, ...props }: KbdProps, forwardedRef) => {
    return (
      <kbd
        ref={forwardedRef}
        className={clsx(styles.kbd, className)}
        {...props}
      >
        {typeof children === 'string' ? mapKey(children) : children}
      </kbd>
    )
  },
)

Kbd.displayName = 'Kbd'

export { Kbd, KbdProps }
