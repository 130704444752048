// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const PdfIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'pdf'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.87868 2.87868C4.44129 2.31607 5.20435 2 6 2H15C15.2652 2 15.5196 2.10536 15.7071 2.29289L20.7071 7.29289C20.8946 7.48043 21 7.73478 21 8V13H3V5C3 4.20435 3.31607 3.44129 3.87868 2.87868ZM14 7C14 7.53043 14.2107 8.03914 14.5858 8.41421C14.9609 8.78929 15.4696 9 16 9H20L14 3V7Z"
          fill="currentColor"
        />
        <path
          d="M17.7518 15V22H16.2526V15H17.7518ZM20.6502 17.9904V19.1154H17.342V17.9904H20.6502ZM21 15V16.1298H17.342V15H21Z"
          fill="currentColor"
        />
        <path
          d="M11.8301 22H10.246L10.256 20.875H11.8301C12.2232 20.875 12.5547 20.7901 12.8246 20.6202C13.0944 20.4471 13.2976 20.1955 13.4342 19.8654C13.5741 19.5353 13.6441 19.1362 13.6441 18.6683V18.3269C13.6441 17.9679 13.6041 17.6522 13.5242 17.3798C13.4475 17.1074 13.3326 16.8782 13.1794 16.6923C13.0261 16.5064 12.8379 16.367 12.6147 16.274C12.3915 16.1779 12.1349 16.1298 11.8451 16.1298H10.216V15H11.8451C12.3315 15 12.7763 15.0801 13.1794 15.2404C13.5858 15.3974 13.9373 15.6234 14.2338 15.9183C14.5303 16.2131 14.7585 16.5657 14.9184 16.976C15.0816 17.383 15.1633 17.8365 15.1633 18.3365V18.6683C15.1633 19.1651 15.0816 19.6186 14.9184 20.0288C14.7585 20.4391 14.5303 20.7917 14.2338 21.0865C13.9406 21.3782 13.5891 21.6042 13.1794 21.7644C12.7729 21.9215 12.3232 22 11.8301 22ZM11.0955 15V22H9.59636V15H11.0955Z"
          fill="currentColor"
        />
        <path
          d="M5.83342 19.5048H3.97945V18.3798H5.83342C6.11993 18.3798 6.35313 18.3349 6.53303 18.2452C6.71293 18.1522 6.84452 18.024 6.92781 17.8606C7.0111 17.6971 7.05274 17.5128 7.05274 17.3077C7.05274 17.0994 7.0111 16.9054 6.92781 16.726C6.84452 16.5465 6.71293 16.4022 6.53303 16.2933C6.35313 16.1843 6.11993 16.1298 5.83342 16.1298H4.49916V22H3V15H5.83342C6.4031 15 6.89116 15.0994 7.2976 15.2981C7.70737 15.4936 8.02053 15.7644 8.23708 16.1106C8.45363 16.4567 8.5619 16.8526 8.5619 17.2981C8.5619 17.75 8.45363 18.141 8.23708 18.4712C8.02053 18.8013 7.70737 19.0561 7.2976 19.2356C6.89116 19.4151 6.4031 19.5048 5.83342 19.5048Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
PdfIcon.displayName = 'PdfIcon'
export { PdfIcon }
