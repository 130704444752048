import React, { useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import type * as Polymorphic from '@radix-ui/react-polymorphic'
import { Slot } from '@radix-ui/react-slot'

import { UnstyledButton } from '../UnstyledButton'
import { useControllableState } from '../../hooks/useControllableState'
import { Text } from '../Text'
import { useResizeObserver } from '../../hooks/useResizeObserver'
import { useMergeRefs } from '../../hooks/useMergeRefs'

import { PanelRightCloseIcon, PanelRightOpenIcon } from '../icons'
import { Tooltip } from '../Tooltip'
import { IconButton } from '../IconButton'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { Drawer } from '../Drawer'

import styles from './Sidebar.module.css'
import { useViewTransition } from '../../hooks/useViewTransition'

type SidebarContextValue = {
  state: 'expanded' | 'collapsed'
  open: boolean
  setOpen: (open: boolean) => void
  // Toggle sidebar toggles it across the states
  toggleSidebar: () => void
  // Mobile state is managed separately to avoid mixing states
  isMobile: boolean
  openMobile: boolean
  setOpenMobile: (open: boolean) => void
}

const SidebarContext = React.createContext<SidebarContextValue | null>(null)

type SidebarProviderProps = React.ComponentProps<'div'> & {
  /**
   * Mobile fallback is used to fallback to offcanvas mode on mobile
   * @default false
   */
  mobileFallback?: boolean
  /**
   * Mobile fallback threshold is used to determine the width threshold to
   * fallback to offcanvas mode on mobile
   * @default 768
   */
  mobileFallbackThreshold?: number
  open?: boolean
  defaultOpen?: boolean
  onOpenChange?: (open: boolean) => void
  children: React.ReactNode
}

function useSidebar() {
  const context = React.useContext(SidebarContext)
  if (!context) {
    throw new Error('useSidebar must be used within a Sidebar.Provider')
  }

  return context
}

const SIDEBAR_KEYBOARD_SHORTCUT = '/'

/**
 * Provider component is used to control the open state of the sidebar. To use the sidebar, you need to wrap it with the `Sidebar.Provider` component.
 */
const SidebarProvider = React.forwardRef<
  HTMLDivElement,
  SidebarProviderProps & React.ComponentProps<'div'>
>(
  (
    {
      defaultOpen = true,
      open: openProp,
      onOpenChange: setOpenProp,
      className,
      children,
      mobileFallback = false,
      mobileFallbackThreshold = 768,
      ...props
    },
    ref,
  ) => {
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    const [open = false, setOpen] = useControllableState<boolean>({
      prop: openProp,
      defaultProp: defaultOpen,
      onChange: setOpenProp,
    })
    const [openMobile, setOpenMobile] = useState(false)
    const isMobile =
      useMediaQuery(`(width < ${mobileFallbackThreshold}px)`) && mobileFallback

    const startTransition = useViewTransition()

    const toggleSidebar = useCallback(() => {
      if (isMobile) {
        return setOpenMobile((open) => !open)
      }

      return startTransition(() => {
        setOpen((open) => !open)
      })
    }, [setOpen, isMobile, setOpenMobile, startTransition])

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (
          event.key === SIDEBAR_KEYBOARD_SHORTCUT &&
          (event.metaKey || event.ctrlKey)
        ) {
          event.preventDefault()
          toggleSidebar()
        }
      }

      window.addEventListener('keydown', handleKeyDown)
      return () => window.removeEventListener('keydown', handleKeyDown)
    }, [toggleSidebar])

    const state = open ? 'expanded' : 'collapsed'

    const contextValue = useMemo<SidebarContextValue>(
      () => ({
        state,
        open,
        setOpen,
        toggleSidebar,
        isMobile,
        openMobile,
        setOpenMobile,
      }),
      [
        state,
        open,
        setOpen,
        toggleSidebar,
        isMobile,
        openMobile,
        setOpenMobile,
      ],
    )

    return (
      <SidebarContext.Provider value={contextValue}>
        <div
          ref={ref}
          className={clsx(styles.wrapper, className)}
          data-sidebar-state={state}
          data-sidebar-is-mobile={isMobile}
          {...props}
        >
          {children}
        </div>
      </SidebarContext.Provider>
    )
  },
)

SidebarProvider.displayName = 'Sidebar.Provider'

type SidebarProps = {
  /**
   * The content of the navigation
   */
  children: React.ReactNode
  /**
   * The class name of the navigation
   */
  className?: string
  /**
   * The mode of how sidebar should expand and collapse
   * @default 'none'
   */
  collapsible?: 'offcanvas' | 'icon' | 'none'
} & React.ComponentProps<'nav'>

/**
 * The sidebar component itself, that shows navigation menu
 */
const Sidebar = React.forwardRef<React.ElementRef<'aside'>, SidebarProps>(
  (
    { children, className, collapsible = 'none', ...rest }: SidebarProps,
    ref,
  ) => {
    const { state, isMobile, openMobile, setOpenMobile } = useSidebar()

    if (collapsible === 'none') {
      return (
        <aside
          className={clsx(styles.sidebarStatic, className)}
          ref={ref}
          {...rest}
        >
          {children}
        </aside>
      )
    }

    if (isMobile) {
      return (
        <Drawer direction="left" open={openMobile} onOpenChange={setOpenMobile}>
          <Drawer.Content
            data-sidebar="sidebar"
            data-mobile="true"
            className={styles.mobileSidebar}
          >
            <aside
              ref={ref}
              className={clsx(styles.mobileSidebarInner, className)}
              {...rest}
            >
              {children}
            </aside>
          </Drawer.Content>
        </Drawer>
      )
    }

    return (
      <aside
        ref={ref}
        className={clsx(styles.container, className)}
        data-state={state}
        data-sidebar="container"
        data-collapsible={state === 'collapsed' ? collapsible : ''}
        {...rest}
      >
        <div data-sidebar="placeholder" className={styles.placeholder} />
        <div data-sidebar="sidebar" className={clsx(styles.sidebar)}>
          {children}
        </div>
      </aside>
    )
  },
)

Sidebar.displayName = 'Sidebar'

type SidebarTriggerProps = {
  /**
   * Additional class name for the trigger
   */
  className?: string
  /**
   * Trigger itself, if not provided, a default one will be used
   */
  children?: React.ReactNode
  /**
   * onClick handler for the trigger
   */
  onClick?: React.ComponentProps<'button'>['onClick']
}

/**
 * The trigger component is used to toggle the sidebar open state. To use it, wrap your button with it.
 * There is also one provided out of the box, that you can use directly <Sidebar.Trigger />
 */
const SidebarTrigger = ({
  children,
  onClick,
  ...props
}: SidebarTriggerProps) => {
  const { isMobile, toggleSidebar, open } = useSidebar()

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      toggleSidebar()
      onClick?.(event)
    },
    [toggleSidebar, onClick],
  )

  if (!children) {
    const label = open ? 'Collapse sidebar' : 'Expand sidebar'

    return (
      <Tooltip content={label} shortcut={'mod+/'}>
        <IconButton
          {...props}
          variant="tertiary"
          label={label}
          onClick={handleClick}
        >
          {open && !isMobile ? <PanelRightOpenIcon /> : <PanelRightCloseIcon />}
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Slot {...props} onClick={handleClick}>
      {children}
    </Slot>
  )
}

SidebarTrigger.displayName = 'Sidebar.Trigger'

/**
 * The header component is used to create a sticky header for the sidebar
 */
const SidebarHeader = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<'header'>
>(({ className, ...props }, ref) => {
  return (
    <header
      ref={ref}
      data-sidebar="header"
      className={clsx(styles.header, className)}
      {...props}
    />
  )
})
SidebarHeader.displayName = 'Sidebar.Header'

/**
 * The footer component is used to create a sticky footer for the sidebar
 */
const SidebarFooter = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<'footer'>
>(({ className, ...props }, ref) => {
  return (
    <footer
      ref={ref}
      data-sidebar="footer"
      className={clsx(styles.footer, className)}
      {...props}
    />
  )
})
SidebarFooter.displayName = 'Sidebar.Footer'

type SidebarContentProps = React.ComponentProps<'div'> & {
  as?: 'div' | 'nav'
}

/**
 * The content component is used to create a scrollable content for the sidebar
 */
const SidebarContent = React.forwardRef<HTMLDivElement, SidebarContentProps>(
  ({ className, as: As = 'div', onScroll, ...props }, forwardedRef) => {
    const [scrollState, setScrollState] = useState({
      hasScrollTop: false,
      hasScrollBottom: false,
    })
    const [containerRef] = useResizeObserver({
      onResize: (_, element) => {
        checkScroll(element)
      },
    })

    const checkScroll = useCallback((element: HTMLElement) => {
      const { scrollTop, scrollHeight, clientHeight } = element

      setScrollState({
        hasScrollTop: scrollTop > 1,
        hasScrollBottom: scrollTop < scrollHeight - clientHeight - 1,
      })
    }, [])

    const ref = useMergeRefs(containerRef, forwardedRef)

    const handleScroll = useCallback(
      (event: React.UIEvent<HTMLDivElement>) => {
        checkScroll(event.currentTarget)
        onScroll?.(event)
      },
      [checkScroll, onScroll],
    )

    return (
      <As
        ref={ref}
        data-sidebar="content"
        data-scroll-top={scrollState.hasScrollTop}
        data-scroll-bottom={scrollState.hasScrollBottom}
        className={clsx(styles.content, className)}
        onScroll={handleScroll}
        {...props}
      />
    )
  },
)
SidebarContent.displayName = 'Sidebar.Content'

type SidebarGroupProps = React.ComponentProps<'section'> & {
  as?: 'section' | 'div' | 'nav'
}
/**
 * The group component is used to create a group of navigation items
 */
const SidebarGroup = React.forwardRef<HTMLDivElement, SidebarGroupProps>(
  ({ className, as: As = 'section', ...props }, ref) => {
    return (
      <As
        ref={ref}
        data-sidebar="group"
        className={clsx(styles.group, className)}
        {...props}
      />
    )
  },
)
SidebarGroup.displayName = 'Sidebar.Group'

/**
 * The group label component is used to create a label for the group
 */
const SidebarGroupLabel = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentProps<'div'>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      data-sidebar="group-label"
      className={clsx(styles.groupLabel, className)}
      {...props}
    >
      <Text size="sm" color="subtle">
        {children}
      </Text>
    </div>
  )
})
SidebarGroupLabel.displayName = 'Sidebar.GroupLabel'

type SidebarGroupContentProps = React.ComponentProps<'div'> & {
  as?: 'div' | 'nav' | 'section'
}
/**
 * The group content component is used to create a content for the group
 */
const SidebarGroupContent = React.forwardRef<
  HTMLDivElement,
  SidebarGroupContentProps
>(({ className, as: As = 'div', ...props }, ref) => (
  <As
    ref={ref}
    data-sidebar="group-content"
    className={clsx(styles.groupContent, className)}
    {...props}
  />
))
SidebarGroupContent.displayName = 'Sidebar.GroupContent'

/**
 * List of navigation items
 */
type SidebarListProps = {
  children: React.ReactNode
  className?: string
} & React.ComponentProps<'ul'>

const SidebarList = React.forwardRef<React.ElementRef<'ul'>, SidebarListProps>(
  (props, ref) => {
    const { children, className, ...rest } = props

    return (
      <ul
        ref={ref}
        data-sidebar="list"
        className={clsx(styles.list, className)}
        {...rest}
      >
        {children}
      </ul>
    )
  },
)

SidebarList.displayName = 'Sidebar.List'

/**
 * List item component is used to create a list item, usually you would use `Sidebar.Button` inside it
 */
const SidebarListItem = React.forwardRef<
  React.ElementRef<'li'>,
  React.ComponentProps<'li'>
>(({ children, className, ...props }, ref) => {
  return (
    <li
      ref={ref}
      data-sidebar="item"
      className={clsx(styles.item, className)}
      {...props}
    >
      {children}
    </li>
  )
})

SidebarListItem.displayName = 'Sidebar.ListItem'

type SidebarButtonProps = {
  /**
   * The content of the navigation item
   */
  children: React.ReactNode
  /**
   * The prefix of the navigation item, usually an icon or an avatar
   */
  prefix?: React.ReactNode
  /**
   * The suffix of the navigation item, usually a badge or count
   */
  suffix?: React.ReactNode
  /**
   * Whether the navigation item is disabled
   */
  disabled?: boolean
  /**
   * Whether the navigation item is selected, if you are using `NavLink` then `aria-current` will apply selected state automatically
   */
  selected?: boolean
  /**
   * The size of the navigation item
   */
  size?: 'medium' | 'large'
  /**
   * The class name of the navigation item
   */
  className?: string
}

type PolymorphicSidebarButton = Polymorphic.ForwardRefComponent<
  'button',
  SidebarButtonProps
>

/**
 * Navigation item, use `as` prop to change the element type to `a` or `Link`
 */
const SidebarButton = React.forwardRef(
  (
    {
      children,
      prefix,
      className,
      disabled,
      size = 'medium',
      selected,
      suffix,
      ...props
    },
    ref,
  ) => {
    return (
      <UnstyledButton
        ref={ref}
        disabled={disabled}
        data-sidebar="button"
        onClick={props.onSelect}
        className={clsx(
          styles.button,
          size === 'large' && styles.large,
          selected && styles.selected,
          disabled && styles.disabled,
          className,
        )}
        {...props}
      >
        {prefix && <span className={styles.itemPrefix}>{prefix}</span>}
        <span className={styles.itemContent}>{children}</span>
        {suffix && <span className={styles.itemSuffix}>{suffix}</span>}
      </UnstyledButton>
    )
  },
) as PolymorphicSidebarButton

SidebarButton.displayName = 'Sidebar.Button'

const SidebarButtonSkeleton = React.forwardRef<
  HTMLDivElement,
  SidebarButtonProps
>(({ className, ...props }, ref) => {
  return (
    <SidebarButton
      as="div"
      aria-busy
      ref={ref}
      className={clsx(styles.skeleton, className)}
      {...props}
    />
  )
})

SidebarButtonSkeleton.displayName = 'Sidebar.ButtonSkeleton'

/**
 * Navigation separator, used to separate navigation items
 */
const SidebarSeparator = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<'div'>
>(({ className, ...props }, ref) => {
  return (
    <div
      role="separator"
      data-sidebar="separator"
      ref={ref}
      className={clsx(styles.separator, className)}
      {...props}
    />
  )
})
SidebarSeparator.displayName = 'Sidebar.Separator'

const SidebarRail = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<'button'>
>(({ className, ...props }, ref) => {
  const { toggleSidebar, isMobile } = useSidebar()

  if (isMobile) {
    return null
  }

  return (
    <UnstyledButton
      ref={ref}
      data-sidebar="rail"
      aria-label="Toggle Sidebar"
      tabIndex={-1}
      className={clsx(styles.rail, className)}
      onClick={toggleSidebar}
      {...props}
    />
  )
})
SidebarRail.displayName = 'Sidebar.Rail'

const SidebarObject = Object.assign(Sidebar, {
  List: SidebarList,
  ListItem: SidebarListItem,
  Button: SidebarButton,
  ButtonSkeleton: SidebarButtonSkeleton,
  Separator: SidebarSeparator,
  Header: SidebarHeader,
  Footer: SidebarFooter,
  Content: SidebarContent,
  Trigger: SidebarTrigger,
  Provider: SidebarProvider,
  Group: SidebarGroup,
  GroupLabel: SidebarGroupLabel,
  GroupContent: SidebarGroupContent,
  Rail: SidebarRail,
})

export { SidebarObject as Sidebar }
export type { SidebarProps, SidebarListProps, SidebarButtonProps }
export { useSidebar }
