// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const BlueskyIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'bluesky'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M5.76877 4.21161C8.29102 5.9712 11.0038 9.53898 12 11.4534C12.9962 9.53898 15.709 5.9712 18.2312 4.21161C20.0511 2.94199 23 1.95961 23 5.08558C23 5.70986 22.6148 10.33 22.3889 11.0801C21.6036 13.6878 18.742 14.353 16.1965 13.9504C20.6459 14.6541 21.7778 16.9849 19.3333 19.3157C14.6909 23.7424 12.6608 18.205 12.1406 16.7862C12.0453 16.5261 12.0007 16.4044 12 16.5078C11.9993 16.4044 11.9547 16.5261 11.8594 16.7862C11.3392 18.205 9.30914 23.7424 4.66667 19.3157C2.22222 16.9849 3.35407 14.6541 7.80346 13.9504C5.25797 14.353 2.39644 13.6878 1.61111 11.0801C1.3852 10.33 1 5.70986 1 5.08558C1 1.95961 3.94887 2.94199 5.76877 4.21161Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
BlueskyIcon.displayName = 'BlueskyIcon'
export { BlueskyIcon }
