// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const AltTagIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'alt-tag'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M5.00781 13C4.45553 13 4.00781 13.4477 4.00781 14C4.00781 14.5523 4.45553 15 5.00781 15L5.00781 13ZM8.00781 15C8.5601 15 9.00781 14.5523 9.00781 14C9.00781 13.4477 8.5601 13 8.00781 13L8.00781 15ZM3.05333 15.7017C2.8886 16.2289 3.18239 16.7897 3.70954 16.9545C4.23668 17.1192 4.79756 16.8254 4.96229 16.2983L3.05333 15.7017ZM6.50781 8L7.46229 7.70173C7.33183 7.28425 6.9452 7 6.50781 7C6.07043 7 5.68379 7.28425 5.55333 7.70173L6.50781 8ZM8.05333 16.2983C8.21807 16.8254 8.77894 17.1192 9.30609 16.9545C9.83323 16.7897 10.127 16.2289 9.96229 15.7017L8.05333 16.2983ZM16.0078 7C15.4555 7 15.0078 7.44772 15.0078 8C15.0078 8.55228 15.4555 9 16.0078 9V7ZM20.0078 9C20.5601 9 21.0078 8.55228 21.0078 8C21.0078 7.44772 20.5601 7 20.0078 7V9ZM17.0078 16C17.0078 16.5523 17.4555 17 18.0078 17C18.5601 17 19.0078 16.5523 19.0078 16H17.0078ZM13.0078 8C13.0078 7.44772 12.5601 7 12.0078 7C11.4555 7 11.0078 7.44772 11.0078 8H13.0078ZM15.0078 17C15.5601 17 16.0078 16.5523 16.0078 16C16.0078 15.4477 15.5601 15 15.0078 15V17ZM5.00781 15L8.00781 15L8.00781 13L5.00781 13L5.00781 15ZM4.96229 16.2983L7.46229 8.29827L5.55333 7.70173L3.05333 15.7017L4.96229 16.2983ZM5.55333 8.29827L8.05333 16.2983L9.96229 15.7017L7.46229 7.70173L5.55333 8.29827ZM16.0078 9H18.0078V7H16.0078V9ZM18.0078 9H20.0078V7H18.0078V9ZM17.0078 8V16H19.0078V8H17.0078ZM11.0078 8V15.75H13.0078V8H11.0078ZM12.2578 17H15.0078V15H12.2578V17ZM11.0078 15.75C11.0078 16.4404 11.5675 17 12.2578 17V15C12.672 15 13.0078 15.3358 13.0078 15.75H11.0078Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
AltTagIcon.displayName = 'AltTagIcon'
export { AltTagIcon }
