// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const CarouselIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'carousel'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M4.71092 2C3.21372 2 2 3.21385 2 4.7112V14.9053C2 16.4026 3.21372 17.6165 4.71093 17.6165H16.2595C17.0081 17.6165 17.6149 17.0096 17.6149 16.2609V4.71119C17.6149 3.21384 16.4012 2 14.904 2H4.71092Z"
          fill="currentColor"
        />
        <path
          d="M9.096 22C7.76841 22 6.6637 21.0456 6.43045 19.7854H18.4273C19.1759 19.7854 19.7828 19.1785 19.7828 18.4298V6.42837C21.0442 6.66051 22 7.76599 22 9.09472V19.2888C22 20.7862 20.7863 22 19.2891 22H9.096Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
CarouselIcon.displayName = 'CarouselIcon'
export { CarouselIcon }
