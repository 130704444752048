// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const TShirtIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'tshirt'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M20.38 3.46L16 2C16 3.06087 15.5786 4.07828 14.8285 4.82843C14.0783 5.57857 13.0609 6 12 6C10.9392 6 9.92176 5.57857 9.17161 4.82843C8.42147 4.07828 8.00004 3.06087 8.00004 2L3.62004 3.46C3.16742 3.61079 2.78362 3.91842 2.53789 4.32734C2.29217 4.73627 2.20072 5.21956 2.28004 5.69L2.86004 9.16C2.89812 9.39491 3.01872 9.60855 3.20018 9.76251C3.38164 9.91648 3.61206 10.0007 3.85004 10H6.00004V20C6.00004 21.1 6.90004 22 8.00004 22H16C16.5305 22 17.0392 21.7893 17.4143 21.4142C17.7893 21.0391 18 20.5304 18 20V10H20.15C20.388 10.0007 20.6184 9.91648 20.7999 9.76251C20.9814 9.60855 21.102 9.39491 21.14 9.16L21.72 5.69C21.7994 5.21956 21.7079 4.73627 21.4622 4.32734C21.2165 3.91842 20.8327 3.61079 20.38 3.46Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
TShirtIcon.displayName = 'TShirtIcon'
export { TShirtIcon }
