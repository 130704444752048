export const isLightColor = (color: string): boolean => {
  const colorWithoutHash = color.replace('#', '')
  const fullHexColor =
    colorWithoutHash.length === 3
      ? colorWithoutHash + colorWithoutHash
      : colorWithoutHash

  const r = parseInt(fullHexColor.substring(0, 2), 16)
  const g = parseInt(fullHexColor.substring(2, 4), 16)
  const b = parseInt(fullHexColor.substring(4, 6), 16)

  return (r * 299 + g * 587 + b * 114) / 1000 > 155
}

export const isHexColor = (color: string): boolean => {
  return /^#([0-9A-F]{3}){1,2}$/i.test(color)
}
