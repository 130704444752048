// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const GbpIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'gbp'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M22 8.5C22 9.87 20.88 11 19.5 11C18.12 11 17 9.87 17 8.5C17 9.87 15.88 11 14.5 11C13.12 11 12 9.87 12 8.5C12 9.87 10.88 11 9.5 11C8.12 11 7 9.87 7 8.5C7 9.87 5.88 11 4.5 11C3.12 11 2 9.87 2 8.5L3.39 3.08C3.39 3.08 3.68 2 4.7 2H19.3C20.32 2 20.61 3.08 20.61 3.08L22 8.5ZM21 12.2V20C21 21.1 20.1 22 19 22H5C3.9 22 3 21.1 3 20V12.2C3.46 12.39 3.97 12.5 4.5 12.5C5.45 12.5 6.32 12.17 7 11.62C7.69 12.17 8.56 12.5 9.5 12.5C10.45 12.5 11.32 12.17 12 11.62C12.69 12.17 13.56 12.5 14.5 12.5C15.45 12.5 16.32 12.17 17 11.62C17.68 12.17 18.56 12.5 19.5 12.5C20.03 12.5 20.54 12.39 21 12.2ZM19 17.33C19 17.13 19 16.92 18.95 16.7L18.92 16.54H15.95V17.71H17.76C17.7 17.93 17.62 18.15 17.45 18.33C17.12 18.66 16.67 18.84 16.19 18.84C15.69 18.84 15.2 18.63 14.84 18.28C14.15 17.57 14.15 16.42 14.86 15.7C15.55 15 16.69 15 17.41 15.67L17.55 15.8L18.39 14.95L18.23 14.81C17.67 14.29 16.93 14 16.15 14H16.14C15.33 14 14.57 14.31 14 14.87C13.41 15.45 13.08 16.21 13.08 17C13.08 17.8 13.39 18.54 13.96 19.09C14.54 19.66 15.35 20 16.18 20H16.2C17 20 17.71 19.71 18.23 19.2C18.7 18.72 19 18 19 17.33Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
GbpIcon.displayName = 'GbpIcon'
export { GbpIcon }
