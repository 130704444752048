// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const DollarIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'dollar'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M16.0078 8H10.0078C9.47738 8 8.96867 8.21071 8.5936 8.58579C8.21853 8.96086 8.00781 9.46957 8.00781 10C8.00781 10.5304 8.21853 11.0391 8.5936 11.4142C8.96867 11.7893 9.47738 12 10.0078 12H14.0078C14.5382 12 15.047 12.2107 15.422 12.5858C15.7971 12.9609 16.0078 13.4696 16.0078 14C16.0078 14.5304 15.7971 15.0391 15.422 15.4142C15.047 15.7893 14.5382 16 14.0078 16H8.00781M12.0078 18V6M22.0078 12C22.0078 17.5228 17.5307 22 12.0078 22C6.48496 22 2.00781 17.5228 2.00781 12C2.00781 6.47715 6.48496 2 12.0078 2C17.5307 2 22.0078 6.47715 22.0078 12Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
DollarIcon.displayName = 'DollarIcon'
export { DollarIcon }
