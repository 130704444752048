// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const TelegramIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'telegram'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M2.51239 10.6795C8.41795 7.99814 12.3559 6.23044 14.3263 5.37638C19.9521 2.93787 21.1211 2.51427 21.883 2.50014C22.0506 2.49721 22.4253 2.54049 22.668 2.74573C22.873 2.91903 22.9294 3.15313 22.9563 3.31744C22.9833 3.48175 23.0169 3.85604 22.9902 4.14851C22.6854 7.48665 21.3662 15.5874 20.6951 19.3262C20.4111 20.9082 19.852 21.4387 19.3107 21.4906C18.1343 21.6034 17.2409 20.6804 16.1015 19.902C14.3186 18.6841 13.3113 17.9259 11.5807 16.7374C9.58058 15.3638 10.8772 14.6089 12.017 13.3752C12.3153 13.0523 17.4986 8.13915 17.5989 7.69345C17.6115 7.6377 17.6231 7.42992 17.5046 7.32021C17.3862 7.21049 17.2114 7.24801 17.0852 7.27785C16.9064 7.32015 14.0581 9.28204 8.54035 13.1635C7.73187 13.7421 6.99958 14.024 6.34347 14.0092C5.62016 13.9929 4.2288 13.583 3.19447 13.2326C1.92583 12.8029 0.91753 12.5757 1.00533 11.8458C1.05106 11.4656 1.55341 11.0769 2.51239 10.6795Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
TelegramIcon.displayName = 'TelegramIcon'
export { TelegramIcon }
