// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const NegativeIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'negative'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M12.0078 7.99999V12M12.0078 16H12.0178M7.90781 20C9.81639 20.979 12.0119 21.2442 14.0987 20.7478C16.1855 20.2513 18.0264 19.0258 19.2896 17.2922C20.5528 15.5585 21.1552 13.4307 20.9884 11.2921C20.8216 9.1536 19.8964 7.14496 18.3796 5.62818C16.8628 4.1114 14.8542 3.18624 12.7157 3.0194C10.5771 2.85257 8.44928 3.45503 6.71563 4.71823C4.98198 5.98143 3.7565 7.8223 3.26004 9.9091C2.76357 11.9959 3.02875 14.1914 4.00781 16.1L2.00781 22L7.90781 20Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
NegativeIcon.displayName = 'NegativeIcon'
export { NegativeIcon }
