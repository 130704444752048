// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const AutomaticPostingIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'automatic-posting'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M3.10988 11.0657C3.54509 11.5781 3.54509 12.2744 3.10988 12.7868L1.69394 14.4538C0.945354 15.3351 0.737799 16.5617 1.39293 17.5097C1.96409 18.3361 3.01425 18.6926 4.03687 18.571L5.89532 18.3498C6.57196 18.2864 7.22065 18.5787 7.54992 19.0817L8.70102 21.3849C9.17752 22.3163 10.1033 22.9835 11.1808 22.9985C11.2506 22.9995 11.3206 23 11.3907 23C11.4608 23 11.5308 22.9995 11.6006 22.9985C12.6732 22.9835 13.5954 22.3223 14.0737 21.3976C14.2044 21.2169 14.1928 21.2111 14.0559 21.1419C14.0088 21.118 13.9467 21.0867 13.8705 21.0381C13.2714 20.6605 12.8645 20.1185 12.655 19.543C12.4534 18.9889 12.4285 18.3917 12.5793 17.8277L13.236 15.0412C13.2391 15.0277 13.2379 15.0136 13.2324 15.0008L13.2134 14.956L13.2027 14.9303C13.2004 14.9248 13.1948 14.9212 13.1887 14.9212H13.18C13.1655 14.9212 13.1615 14.9021 13.1748 14.8966C13.1814 14.8939 13.1846 14.8866 13.1819 14.8801L13.1685 14.8467C13.1564 14.8164 13.1181 14.8045 13.0899 14.8223C12.5597 15.1578 11.9308 15.352 11.2565 15.352C9.36407 15.352 7.82993 13.8221 7.82993 11.9349C7.82993 10.0476 9.36407 8.51773 11.2565 8.51773C11.7635 8.51773 12.2847 8.38462 12.6193 8.01756L15.0389 5.36315C15.2171 5.16763 15.1974 4.87556 15.078 4.64213L14.0409 2.61501C13.5644 1.68369 12.6387 1.01654 11.5613 1.00147C11.4915 1.00049 11.4215 1 11.3514 1C11.2813 1 11.2113 1.00049 11.1415 1.00147C10.064 1.01654 9.13823 1.68373 8.66173 2.61512L7.62471 4.64213C7.32748 5.22315 6.62777 5.57131 5.89532 5.50267L4.03693 5.28154C3.0143 5.15985 1.96413 5.51642 1.39296 6.34284C0.737807 7.29077 0.945362 8.51739 1.69396 9.39873L3.10988 11.0657Z"
          fill="currentColor"
        />
        <path
          d="M22.9491 10.798C22.9055 10.6859 22.8315 10.5898 22.7364 10.5221C22.6412 10.4543 22.5292 10.4178 22.4145 10.4172H18.4921L20.1933 4.61913C20.2222 4.48385 20.2068 4.34223 20.1499 4.21732C20.0929 4.09243 19.9976 3.99157 19.8795 3.93117C19.7636 3.87135 19.6322 3.85398 19.506 3.88178C19.3797 3.90958 19.2658 3.98098 19.1822 4.08474L12.0942 11.8606C12.0216 11.9498 11.9748 12.0592 11.9594 12.1757C11.9439 12.2922 11.9605 12.4109 12.007 12.5178C12.0537 12.6244 12.1283 12.7147 12.222 12.7778C12.3158 12.8409 12.4247 12.8744 12.5358 12.8741H15.7958L14.429 18.6737C14.3886 18.807 14.3925 18.951 14.44 19.0817C14.4876 19.2124 14.576 19.322 14.6905 19.3923C14.783 19.4529 14.8897 19.4848 14.9985 19.4845C15.075 19.4849 15.1508 19.4695 15.2216 19.4389C15.2924 19.4083 15.3568 19.3633 15.4111 19.3064L22.8445 11.4675C22.9223 11.3792 22.9736 11.2685 22.9922 11.1494C23.0108 11.0304 22.9959 10.9081 22.9491 10.798Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
AutomaticPostingIcon.displayName = 'AutomaticPostingIcon'
export { AutomaticPostingIcon }
