import * as React from 'react'
import clsx from 'clsx'
import * as RadixSwitch from '@radix-ui/react-switch'

import useId from '../../helpers/useId'
import { Label } from '../Label'

import styles from './Switch.module.css'

interface SwitchProps extends RadixSwitch.SwitchProps {
  /**
   * If provided, the id of the switch
   */
  id?: string
  /**
   * If provided, the children will be rendered as a label
   */
  children?: React.ReactNode
  /**
   * If true, the switch will be disabled
   */
  disabled?: boolean
  /**
   * Additional class name
   */
  className?: string
}

const Switch = React.forwardRef<HTMLButtonElement, SwitchProps>(
  (
    { className, id: passedId, children, disabled, ...props }: SwitchProps,
    forwardedRef,
  ) => {
    const id = useId(passedId)

    return (
      <div className={clsx(styles.wrapper, className)}>
        <RadixSwitch.Root
          className={styles.root}
          id={id}
          disabled={disabled}
          ref={forwardedRef}
          {...props}
        >
          <RadixSwitch.Thumb className={styles.thumb} />
        </RadixSwitch.Root>

        {children && <Label htmlFor={id}>{children}</Label>}
      </div>
    )
  },
)

Switch.displayName = 'Switch'

export { Switch }
export type { SwitchProps }
