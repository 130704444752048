import { useEffect } from 'react'

export const useDeprecationWarning = (
  deprecated?: boolean,
  message?: string,
) => {
  useEffect(() => {
    if (deprecated) {
      console.warn(`⚠️ ${message}`)
    }
  }, [deprecated, message])
}
