import { useSplit } from './useSplit'

export function useSplitEnabled<T = Record<string, never>>(
  name: string,
  attributes = {},
  enabledTreatment = 'on',
) {
  const { split, isReady, isTimedout, config, client } = useSplit<T>(
    name,
    attributes,
  )

  // If there is no client it means that the SDK was not loaded so we consider it as ready
  // We early return with default values
  if (!client) {
    return {
      isEnabled: false,
      isReady: true,
      isTimedout: false,
      config: {},
    }
  }
  const isEnabled =
    split.treatment === enabledTreatment && isReady && !isTimedout
  const ready = isReady && !isTimedout
  return { isEnabled, isReady, isTimedout, config: ready ? config : {} }
}
