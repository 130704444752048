// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const TwitchIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'twitch'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M3.7957 2L2.5 5.47833V19.39H7.24871V22H9.84131L12.4313 19.39H16.3174L21.5 14.1748V2H3.7957ZM5.52194 3.73807H19.773V13.3035L16.7494 16.3474H12L9.41071 18.9538V16.3474H5.52194V3.73807ZM10.2726 12.435H12V7.21825H10.2726V12.435ZM15.0223 12.435H16.7494V7.21825H15.0223V12.435Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
TwitchIcon.displayName = 'TwitchIcon'
export { TwitchIcon }
