// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const BufferIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'buffer'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M3 6.29162L12 1.45178L21 6.29162L12 11.0518L3 6.29162Z"
          fill="currentColor"
        />
        <path
          d="M5.89284 10.5L12 13.6034L18.1714 10.5L21.0482 11.9483L12 16.5L3.04822 11.9483L5.89284 10.5Z"
          fill="currentColor"
        />
        <path
          d="M5.84462 15.9L12 19.3222L18.1232 15.9L21 17.4888L12 22.5L3 17.4888L5.84462 15.9Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
BufferIcon.displayName = 'BufferIcon'
export { BufferIcon }
