import React, { useEffect } from 'react'
import { Provider as RadixTooltipProvider } from '@radix-ui/react-tooltip'

import { PortalContainerProvider } from '../../helpers/usePortalContainer'
import { Toaster } from '../Toast/Toaster'

// global CSS with tokens
import '../../styles/index.css'
import { useLocalStorage } from '../../hooks/useLocalStorage'

type Theme = 'light' | 'dark' | 'vrLight' | 'vrDark'
const THEME_STORAGE_KEY = 'theme'

export const THEMES: Record<Theme, string> = {
  light: 'Light',
  dark: 'Dark',
  vrLight: 'Visual Refresh Light',
  vrDark: 'Visual Refresh Dark',
}

type ThemeContextValue = {
  theme: Theme
  setTheme: (theme: Theme) => void
}

const ThemeContext = React.createContext<ThemeContextValue | undefined>(
  undefined,
)

type PopcornProviderProps = {
  children: React.ReactNode
  /**
   * The theme to use. If not provided, the theme will be set based on the
   * user's system preferences.
   * @default 'default'
   **/
  theme?: Theme
  /**
   * The id of the container where the portals will be rendered
   **/
  portalContainerId?: string
  /**
   * Whether toaster should be rendered. Internal use only.
   */
  _toaster?: boolean
  /**
   * Whether to enable the theme switcher.
   * @default false
   */
  themeSwitcherEnabled?: boolean
}

function useTheme(): ThemeContextValue {
  const context = React.useContext(ThemeContext)

  if (context === undefined) {
    throw new Error('`useTheme` must be used within a `PopcornProvider`')
  }

  return context
}

function detectSystemTheme(): Theme {
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light'
}

function PopcornProvider({
  children,
  theme,
  portalContainerId,
  _toaster = true,
  themeSwitcherEnabled = false,
}: PopcornProviderProps): JSX.Element {
  const [currentTheme, setCurrentTheme] = useLocalStorage<Theme>(
    THEME_STORAGE_KEY,
    theme ?? detectSystemTheme(),
  )
  const computedTheme = themeSwitcherEnabled
    ? currentTheme ?? theme ?? detectSystemTheme()
    : theme ?? detectSystemTheme()

  // sets theme class on the root element
  const setThemeStyles = (newTheme: Theme): void => {
    const root = document.documentElement
    root.classList.remove(...Object.keys(THEMES))
    root.style.colorScheme = newTheme
    root.classList.add(newTheme)
  }

  useEffect(() => {
    setThemeStyles(computedTheme)
  }, [computedTheme, currentTheme])

  const handleThemeChange = (theme: Theme): void => {
    if (themeSwitcherEnabled) {
      setCurrentTheme(theme)
    }
  }

  const [container, setContainer] = React.useState<HTMLElement | null>(null)
  useEffect(() => {
    if (portalContainerId) {
      setContainer(document.getElementById(portalContainerId))
    }
  }, [portalContainerId])

  return (
    <PortalContainerProvider container={container ?? document.body}>
      <ThemeContext.Provider
        value={{
          theme: computedTheme,
          setTheme: handleThemeChange,
        }}
      >
        <RadixTooltipProvider>
          {children}
          {_toaster && <Toaster />}
        </RadixTooltipProvider>
      </ThemeContext.Provider>
    </PortalContainerProvider>
  )
}

export { PopcornProvider, ThemeContext, Theme, useTheme }
