import React from 'react'
import clsx from 'clsx'
import type * as Polymorphic from '@radix-ui/react-polymorphic'

import styles from './VisuallyHidden.module.css'

type VisuallyHiddenOwnProps = {
  as?: keyof JSX.IntrinsicElements
}

type PolymorphicVisuallyHidden = Polymorphic.ForwardRefComponent<
  'span',
  VisuallyHiddenOwnProps
>

/**
 * Visually hide an element but keep it accessible to assistive technology
 */
const VisuallyHidden = React.forwardRef(
  ({ as, className, ...otherProps }, forwardedRef) => {
    const Tag = as || 'span'

    return (
      <Tag
        ref={forwardedRef}
        className={clsx(styles.base, className)}
        {...otherProps}
      />
    )
  },
) as PolymorphicVisuallyHidden

VisuallyHidden.displayName = 'VisuallyHidden'

export { VisuallyHidden }
