import React from 'react'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import clsx from 'clsx'

import { Card, CardProps } from '../Card'

import styles from './RadioCard.module.css'
import { RadioGroupItemBase } from '../RadioGroup/RadioGroup'
import { Flex } from '../Flex/Flex'

type RadioCardElement = React.ElementRef<typeof Card>

type RadioCardProps = Omit<CardProps, 'asChild'> & {
  /**
   * Value of the radio card
   */
  value: string
  /**
   * Controls whether the radio card is disabled or not
   */
  disabled?: boolean
}

/**
 * A radio card is a card that can be selected or deselected within a group
 */
const RadioCard = React.forwardRef<RadioCardElement, RadioCardProps>(
  (
    { className, value, children, disabled, ...props }: RadioCardProps,
    forwardedRef,
  ) => {
    return (
      <Card
        className={clsx(styles.card, className)}
        {...props}
        ref={forwardedRef}
        asChild
      >
        <Flex as="label" htmlFor={value} gap="xs" align="center">
          {children}

          <RadioGroupItemBase
            id={value}
            value={value}
            disabled={disabled}
            className={styles.radio}
          />
        </Flex>
      </Card>
    )
  },
)

RadioCard.displayName = 'RadioCard'

interface RadioCardGroupProps extends RadioGroupPrimitive.RadioGroupProps {
  /**
   * callback function that is called when the value of the radio card changes
   */
  onValueChange: (value: string) => void
}

/**
 * RadioCardGroup is container for RadioCard components
 */
const RadioCardGroup = React.forwardRef<
  HTMLFieldSetElement,
  RadioCardGroupProps
>(
  (
    {
      children,
      disabled,
      defaultValue,
      onValueChange,
      className,
      ...props
    }: RadioCardGroupProps,
    forwardedRef,
  ) => {
    return (
      <RadioGroupPrimitive.Root
        {...props}
        disabled={disabled}
        defaultValue={defaultValue}
        onValueChange={onValueChange}
        className={clsx(styles.group, className)}
        asChild
      >
        <fieldset ref={forwardedRef}>{children}</fieldset>
      </RadioGroupPrimitive.Root>
    )
  },
)

RadioCardGroup.displayName = 'RadioCard.Group'

const RadioCardObject = Object.assign(RadioCard, {
  Group: RadioCardGroup,
})

export { RadioCardObject as RadioCard, RadioCardGroup }
export type { RadioCardProps }
