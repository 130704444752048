// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const PatreonIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'patreon'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M22 7.60654C21.9961 4.79747 19.8179 2.4952 17.2622 1.6645C14.0885 0.632942 9.9027 0.782461 6.87219 2.21857C3.19915 3.95943 2.04532 7.77277 2.00232 11.5759C1.96703 14.7028 2.27775 22.9383 6.90281 22.997C10.3394 23.0408 10.8511 18.5933 12.4412 16.4514C13.5725 14.9275 15.0291 14.497 16.8222 14.0513C19.9041 13.2852 22.0045 10.8424 22 7.60654Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
PatreonIcon.displayName = 'PatreonIcon'
export { PatreonIcon }
