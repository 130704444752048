import React from 'react'
import clsx from 'clsx'

import { Heading, HeadingProps } from '../Heading'
import { Paragraph, ParagraphProps } from '../Paragraph'
import { ImageProps, Image } from '../Image'
import { useDeprecationWarning } from '../../hooks/useDeprecationWarning'

import styles from './EmptyState.module.css'

type EmptyStateSize = 'small' | 'medium' | 'large' | 'xlarge'

type EmptyStateVariant =
  | 'neutral'
  | 'primary'
  | 'success'
  | 'critical'
  | 'warning'

const EmptyStateContext = React.createContext<{
  size: EmptyStateSize
  variant: EmptyStateVariant
} | null>(null)

type EmptyStateProps = {
  /**
   * Size of the EmptyState
   *
   * @default medium
   */
  size?: 'small' | 'medium' | 'large' | 'xlarge'
  /**
   * Variant of the EmptyState, controls the color scheme
   *
   * @default neutral
   */
  variant?: 'neutral' | 'primary' | 'success' | 'critical' | 'warning'
  className?: string
} & React.HTMLAttributes<HTMLDivElement>

/**
 * EmptyState component to display a message when there is no content to show or an error when contant failed to load
 */
const EmptyState = React.forwardRef<HTMLDivElement, EmptyStateProps>(
  (
    {
      className,
      size = 'medium',
      variant = 'neutral',
      ...props
    }: EmptyStateProps,
    forwardedRef,
  ) => (
    <EmptyStateContext.Provider value={{ size, variant }}>
      <div
        ref={forwardedRef}
        className={clsx(styles.wrapper, styles[size], className)}
        {...props}
      />
    </EmptyStateContext.Provider>
  ),
)

EmptyState.displayName = 'EmptyState'

/**
 * Heading for the EmptyState
 */

type EmptyStateHeadingProps = HeadingProps & {
  children: React.ReactNode
  className?: string
  /**
   * The HTML element to render as
   * @default 'h3'
   */
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const sizeToHeadingSize: Record<EmptyStateSize, HeadingProps['size']> = {
  small: 'xsmall',
  medium: 'small',
  large: 'small',
  xlarge: 'medium',
}

const sizeToHeadingElement: Record<
  EmptyStateSize,
  EmptyStateHeadingProps['as']
> = {
  small: 'h4',
  medium: 'h3',
  large: 'h2',
  xlarge: 'h1',
}

const EmptyStateHeading = React.forwardRef<
  HTMLHeadingElement,
  EmptyStateHeadingProps
>(({ className, as, ...props }, forwardedRef) => {
  const { size = 'medium' } = React.useContext(EmptyStateContext) || {}

  return (
    <Heading
      size={sizeToHeadingSize[size || 'medium']}
      className={clsx(styles.heading, className)}
      as={as ?? sizeToHeadingElement[size] ?? 'h3'}
      ref={forwardedRef}
      {...props}
    />
  )
})

EmptyStateHeading.displayName = 'EmptyState.Heading'

/**
 * Description for the EmptyState
 */

type EmptyStateDescriptionProps = ParagraphProps

const EmptyStateDescription = React.forwardRef<
  HTMLHeadingElement,
  EmptyStateDescriptionProps
>(({ className, ...props }, forwardedRef) => (
  <Paragraph
    ref={forwardedRef}
    className={clsx(styles.description, className)}
    {...props}
  />
))

EmptyStateDescription.displayName = 'EmptyState.Description'

/**
 * Actions for the EmptyState
 */

type EmptyStateActionsProps = React.HTMLAttributes<HTMLDivElement>

const EmptyStateActions = React.forwardRef<
  HTMLDivElement,
  EmptyStateActionsProps
>(({ className, ...props }, forwardedRef) => (
  <div
    ref={forwardedRef}
    className={clsx(styles.actions, className)}
    {...props}
  />
))

EmptyStateActions.displayName = 'EmptyState.Actions'

/**
 * Icon for the EmptyState
 */

type EmptyStateIconProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode
  className?: string
  /**
   * @deprecated Use the `variant` prop on the EmptyState component instead
   */
  variant?: EmptyStateVariant
}

const EmptyStateIcon = React.forwardRef<HTMLImageElement, EmptyStateIconProps>(
  ({ className, children, variant: variantProp }, forwardedRef) => {
    const context = React.useContext(EmptyStateContext)
    const variant = variantProp ?? context?.variant ?? 'neutral'

    useDeprecationWarning(
      variantProp !== undefined,
      'The `variant` prop is deprecated. Use the `variant` prop on the EmptyState component instead.',
    )

    return (
      <div
        className={clsx(styles.icon, variant && styles[variant], className)}
        ref={forwardedRef}
        aria-hidden
      >
        {children}
      </div>
    )
  },
)

EmptyStateIcon.displayName = 'EmptyState.Icon'

/**
 * Illustration for the EmptyState
 */

type EmptyStateIllustrationProps = Omit<ImageProps, 'alt'> & {
  /**
   * The alt text for the image. By default, it is an empty string
   * so that the image is not announced by screen readers
   * @default ''
   */
  alt?: string
}

const EmptyStateIllustration = React.forwardRef<
  HTMLImageElement,
  EmptyStateIllustrationProps
>(({ className, alt = '', ...props }, forwardedRef) => (
  <Image
    alt={alt}
    className={clsx(styles.illustration, className)}
    {...props}
    ref={forwardedRef}
  />
))

EmptyStateIllustration.displayName = 'EmptyState.Icon'

const EmptyStateObject = Object.assign(EmptyState, {
  Heading: EmptyStateHeading,
  Description: EmptyStateDescription,
  Actions: EmptyStateActions,
  Icon: EmptyStateIcon,
  Illustration: EmptyStateIllustration,
})

export {
  EmptyStateObject as EmptyState,
  EmptyStateSize,
  EmptyStateVariant,
  EmptyStateHeadingProps,
  EmptyStateActionsProps,
  EmptyStateIconProps,
  EmptyStateDescriptionProps,
}
