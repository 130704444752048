import * as React from 'react'
import clsx from 'clsx'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { Slottable } from '@radix-ui/react-slot'
import { CheckedState } from '@radix-ui/react-checkbox'

import { Button } from '../Button'
import { ChevronRightIcon, CheckIcon, LoadingIcon } from '../icons'
import useId from '../../helpers/useId'
import usePortalContainer from '../../helpers/usePortalContainer'

import styles from './DropdownMenu.module.css'
import { Kbd } from '../Kbd'

/**
 * Dropdown menu root component
 */
type DropdownMenuProps = {
  /**
   * The content of the dropdown menu
   */
  children?: React.ReactNode
  /**
   * Trigger element for the dropdown menu, usually, a button
   */
  trigger: React.ReactNode
  /**
   * When true, keyboard navigation will loop from last
   * item to first, and vice versa.
   * @default false
   */
  loop?: boolean
  /**
   * The side of the trigger where the menu should be opened
   * @default 'bottom'
   */
  side?: 'top' | 'right' | 'bottom' | 'left'
  /**
   * The align of the menu relative to the trigger
   * @default 'end'
   */
  align?: 'start' | 'center' | 'end'
  /**
   * Controlled opened state
   */
  open?: boolean
  /**
   * Uncontrolled opened state
   * @default false
   */
  defaultOpen?: boolean
  /**
   * The modality of the dropdown menu. When `true`, interaction with outside elements
   * will be disabled and only menu content will be visible to screen readers.
   * @default true
   */
  modal?: boolean
  /**
   * Event handler called when the open state changes
   */
  onOpenChange?(open: boolean): void
  /**
   * Classname of the item
   */
  className?: string
}

const DropdownMenu = ({
  children,
  open,
  defaultOpen,
  loop = false,
  side = 'bottom',
  align = 'end',
  modal = true,
  trigger,
  className,
  onOpenChange,
}: DropdownMenuProps) => {
  const container = usePortalContainer()

  return (
    <DropdownMenuPrimitive.Root
      modal={modal}
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
    >
      <DropdownMenuPrimitive.Trigger asChild className={styles.trigger}>
        {typeof trigger === 'string' ? <Button>{trigger}</Button> : trigger}
      </DropdownMenuPrimitive.Trigger>

      <DropdownMenuPrimitive.Portal container={container}>
        <DropdownMenuPrimitive.Content
          loop={loop}
          side={side}
          align={align}
          sideOffset={4}
          collisionPadding={16}
          className={clsx(styles.menu, className)}
        >
          {children}
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  )
}

DropdownMenu.displayName = 'DropdownMenu'

/**
 * Dropdown menu label
 */
type DropdownMenuLabelProps = React.ComponentPropsWithoutRef<
  typeof DropdownMenuPrimitive.Label
> & {
  /**
   * Classname of the label
   */
  className?: string
  /**
   * Content of the label
   */
  children?: React.ReactNode
}

type DropdownMenuLabelElement = React.ElementRef<
  typeof DropdownMenuPrimitive.Label
>

const DropdownMenuLabel = React.forwardRef<
  DropdownMenuLabelElement,
  DropdownMenuLabelProps
>(({ children, className, ...props }, forwardedRef) => (
  <DropdownMenuPrimitive.Label
    {...props}
    ref={forwardedRef}
    className={clsx(styles.label, className)}
  >
    {children}
  </DropdownMenuPrimitive.Label>
))

DropdownMenuLabel.displayName = 'DropdownMenu.Label'

/**
 * Dropdown menu separator
 */
type DropdownMenuSeparatorProps = React.ComponentPropsWithoutRef<
  typeof DropdownMenuPrimitive.Separator
> & {
  /**
   * Classname of the label
   */
  className?: string
  /**
   * Content of the label
   */
  children?: never
}

type DropdownMenuSeparatorElement = React.ElementRef<
  typeof DropdownMenuPrimitive.Separator
>

const DropdownMenuSeparator = React.forwardRef<
  DropdownMenuSeparatorElement,
  DropdownMenuSeparatorProps
>(({ className, ...props }, forwardedRef) => (
  <DropdownMenuPrimitive.Separator
    {...props}
    ref={forwardedRef}
    className={clsx(styles.separator, className)}
  />
))

DropdownMenuSeparator.displayName = 'DropdownMenu.Separator'

/**
 * Dropdown menu item
 */
type DropdownMenuItemProps = DropdownMenuPrimitive.DropdownMenuItemProps & {
  /**
   * The variant of the menu item
   * @default default
   */
  variant?: 'default' | 'critical'
  /**
   * The shortcut of the menu item
   */
  shortcut?: string
  /**
   * Classname of the item
   */
  className?: string
  /**
   * Content of the menu item
   */
  children?: React.ReactNode
  /**
   * When true, prevents the user from interacting with the item.
   * @default false
   */
  disabled?: boolean
  /**
   * When true, displays a spinner and prevents interaction
   * @default false
   */
  loading?: boolean
}

type DropdownMenuItemElement = React.ElementRef<
  typeof DropdownMenuPrimitive.Item
>

const DropdownMenuItem = React.forwardRef<
  DropdownMenuItemElement,
  DropdownMenuItemProps
>(
  (
    {
      children,
      className,
      disabled,
      loading = false,
      variant = 'default',
      shortcut,
      onSelect,
      asChild,
      ...props
    },
    forwardedRef,
  ) => {
    let innerChildren = children

    if (!asChild) {
      innerChildren = (
        <>
          {loading && <LoadingIcon />}
          {children}
          {shortcut && (
            <span className={styles.shortcut} aria-hidden>
              {shortcut.split(' ').map((character, index) => {
                return <Kbd key={`shortcut-${index}`}>{character}</Kbd>
              })}
            </span>
          )}
        </>
      )
    }

    return (
      <DropdownMenuPrimitive.Item
        {...props}
        disabled={disabled || loading}
        className={clsx(
          styles.item,
          variant === 'critical' && styles.critical,
          loading && styles.loading,
          className,
        )}
        ref={forwardedRef}
        onSelect={onSelect}
        asChild={asChild}
      >
        {innerChildren}
      </DropdownMenuPrimitive.Item>
    )
  },
)

DropdownMenuItem.displayName = 'DropdownMenu.Item'

/**
 * Dropdown menu checkbox item
 */
type DropdownMenuCheckboxItemProps = {
  /**
   * Classname of the item
   */
  className?: string
  /**
   * Content of the menu item
   */
  children?: React.ReactNode
  /**
   * When true, prevents the user from interacting with the item.
   * @default false
   */
  disabled?: boolean
  /**
   * Controlled state of the checkbox
   */
  checked?: CheckedState
  /**
   * Event handler called when the checked state changes.
   */
  onCheckedChange?: (checked: CheckedState) => void
  /**
   * Event handler called when the user selects an item (via mouse or keyboard)
   */
  onSelect?: () => void
}

type DropdownMenuCheckboxItemElement = React.ElementRef<
  typeof DropdownMenuPrimitive.CheckboxItem
>

const DropdownMenuCheckboxItem = React.forwardRef<
  DropdownMenuCheckboxItemElement,
  DropdownMenuCheckboxItemProps
>(({ children, className, disabled, onSelect, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.CheckboxItem
      {...props}
      disabled={disabled}
      className={clsx(styles.checkboxItem, className)}
      ref={forwardedRef}
      onSelect={onSelect}
    >
      <DropdownMenuPrimitive.ItemIndicator className={styles.postfix}>
        <CheckIcon />
      </DropdownMenuPrimitive.ItemIndicator>
      <Slottable>{children}</Slottable>
    </DropdownMenuPrimitive.CheckboxItem>
  )
})

DropdownMenuCheckboxItem.displayName = 'DropdownMenu.CheckboxItem'

/**
 * Dropdown menu sub menu root component
 */
type DropdownMenuSubProps = {
  /**
   * Content of the menu item
   */
  children?: React.ReactNode
  /**
   * Controlled is submenu opened
   */
  open?: boolean
  /**
   * Uncontrolled is submenu opened
   * @default false
   */
  defaultOpen?: boolean
  /**
   * Event handler called when the open state changes
   * @param open
   */
  onOpenChange?(open: boolean): void
}

const DropdownMenuSub = ({ children, ...props }: DropdownMenuSubProps) => {
  return (
    <DropdownMenuPrimitive.Sub {...props}>{children}</DropdownMenuPrimitive.Sub>
  )
}

DropdownMenuSub.displayName = 'DropdownMenu.Sub'

/**
 * Dropdown menu sub menu trigger item
 */
type DropdownMenuSubTriggerProps = {
  /**
   * Content of the menu item
   */
  children?: React.ReactNode
  /**
   * If disabled, submenu won't open
   * @default false
   */
  disabled?: boolean
  /**
   * Classname to set on the trigger
   */
  className?: string
}

type DropdownMenuSubTriggerElement = React.ElementRef<
  typeof DropdownMenuPrimitive.SubTrigger
>

const DropdownMenuSubTrigger = React.forwardRef<
  DropdownMenuSubTriggerElement,
  DropdownMenuSubTriggerProps
>(({ children, className, disabled, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.SubTrigger
      className={clsx(styles.subTrigger, className)}
      disabled={disabled}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <span className={styles.postfix}>
        <ChevronRightIcon />
      </span>
    </DropdownMenuPrimitive.SubTrigger>
  )
})

DropdownMenuSubTrigger.displayName = 'DropdownMenu.SubTrigger'

/**
 * Dropdown sub menu
 */
type DropdownMenuSubMenuProps = {
  /**
   * Content of the menu item
   */
  children?: React.ReactNode
  /**
   * Classname to set on the menu
   */
  className?: string
}

type DropdownMenuSubMenuElement = React.ElementRef<
  typeof DropdownMenuPrimitive.SubContent
>

const DropdownMenuSubMenu = React.forwardRef<
  DropdownMenuSubMenuElement,
  DropdownMenuSubMenuProps
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.SubContent
      className={clsx(styles.subMenu, className)}
      sideOffset={4}
      collisionPadding={16}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </DropdownMenuPrimitive.SubContent>
  )
})

DropdownMenuSubMenu.displayName = 'DropdownMenu.SubMenu'

/**
 * Dropdown menu group of items
 */
export type DropdownMenuGroupProps = {
  /**
   * Content of the menu item
   */
  children?: React.ReactNode
  /**
   * Visible label of the group
   */
  label?: string
  /**
   * Accessible label of the group
   * Use, if the group does not have a visible label
   */
  'aria-label'?: string
  /**
   * Classname to set on the menu
   */
  className?: string
}

type DropdownMenuGroupElement = React.ElementRef<
  typeof DropdownMenuPrimitive.Group
>

const DropdownMenuGroup = React.forwardRef<
  DropdownMenuGroupElement,
  DropdownMenuGroupProps
>(({ children, className, label, ...props }, forwardedRef) => {
  const labelId = useId()

  // if no items, group does not render
  if (!children) {
    return null
  }

  return (
    <DropdownMenuPrimitive.Group
      className={clsx(styles.group, className)}
      aria-labelledby={label ? labelId : undefined}
      {...props}
      ref={forwardedRef}
    >
      {label && <DropdownMenuLabel id={labelId}>{label}</DropdownMenuLabel>}
      {children}
    </DropdownMenuPrimitive.Group>
  )
})

DropdownMenuGroup.displayName = 'DropdownMenu.Group'

/**
 * Dropdown radio group of items
 */
type DropdownMenuRadioGroupProps = {
  /**
   * Content of the menu item
   */
  children?: React.ReactNode
  /**
   * Visible label of the group
   */
  label?: string
  /**
   * The value of the selected item in the group.
   */
  value?: string
  /**
   * Event handler called when the value changes.
   */
  onChange?: (value: string) => void
  /**
   * Classname to set on the menu
   */
  className?: string
}

type DropdownMenuRadioGroupElement = React.ElementRef<
  typeof DropdownMenuPrimitive.RadioGroup
>

const DropdownMenuRadioGroup = React.forwardRef<
  DropdownMenuRadioGroupElement,
  DropdownMenuRadioGroupProps
>(({ children, className, label, value, onChange, ...props }, forwardedRef) => {
  const labelId = useId()

  return (
    <DropdownMenuPrimitive.RadioGroup
      className={clsx(styles.radioGroup, className)}
      aria-labelledby={label ? labelId : undefined}
      value={value}
      onValueChange={onChange}
      {...props}
      ref={forwardedRef}
    >
      {label && <DropdownMenuLabel id={labelId}>{label}</DropdownMenuLabel>}
      {children}
    </DropdownMenuPrimitive.RadioGroup>
  )
})

DropdownMenuRadioGroup.displayName = 'DropdownMenu.RadioGroup'

/**
 * Dropdown radio item
 */
type DropdownMenuRadioItemProps = {
  /**
   * Label of the radio item
   */
  children?: React.ReactNode
  /**
   * Classname to set on the menu
   */
  className?: string
  /**
   * Unique value associated with an item
   */
  value: string
  /**
   * When true, prevents the user from selecting an item
   * @default false
   */
  disabled?: boolean
  /**
   * Fires when the user selects an item (via mouse or keyboard)
   */
  onSelect?: () => void
}

type DropdownMenuRadioItemElement = React.ElementRef<
  typeof DropdownMenuPrimitive.RadioItem
>

const DropdownMenuRadioItem = React.forwardRef<
  DropdownMenuRadioItemElement,
  DropdownMenuRadioItemProps
>(({ children, className, value, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.RadioItem
      className={clsx(styles.radioItem, className)}
      value={value}
      {...props}
      ref={forwardedRef}
    >
      <DropdownMenuPrimitive.ItemIndicator className={styles.postfix}>
        <CheckIcon />
      </DropdownMenuPrimitive.ItemIndicator>
      <Slottable>{children}</Slottable>
    </DropdownMenuPrimitive.RadioItem>
  )
})

DropdownMenuRadioItem.displayName = 'DropdownMenu.RadioItem'

const DropdownMenuObject = Object.assign(DropdownMenu, {
  Item: DropdownMenuItem,
  CheckboxItem: DropdownMenuCheckboxItem,
  Label: DropdownMenuLabel,
  Separator: DropdownMenuSeparator,
  Sub: DropdownMenuSub,
  SubTrigger: DropdownMenuSubTrigger,
  SubMenu: DropdownMenuSubMenu,
  Group: DropdownMenuGroup,
  RadioGroup: DropdownMenuRadioGroup,
  RadioItem: DropdownMenuRadioItem,
})

export { DropdownMenuObject as DropdownMenu }
export type { DropdownMenuProps, DropdownMenuItemProps }
