// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const CreditCardIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'credit-card'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M2 10H22M4 5H20C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17V7C2 5.89543 2.89543 5 4 5Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
CreditCardIcon.displayName = 'CreditCardIcon'
export { CreditCardIcon }
