// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const PinterestIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'pinterest'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92488 1 1 5.92488 1 12C1 16.66 3.8994 20.6439 7.99129 22.2466C7.89517 21.3763 7.80834 20.0378 8.02952 19.0877C8.22934 18.2293 9.31931 13.62 9.31931 13.62C9.31931 13.62 8.99028 12.9611 8.99028 11.9869C8.99028 10.4573 9.87682 9.31543 10.9807 9.31543C11.9192 9.31543 12.3724 10.0201 12.3724 10.865C12.3724 11.8087 11.7716 13.2198 11.4615 14.5274C11.2023 15.6223 12.0105 16.5152 13.0904 16.5152C15.0454 16.5152 16.5483 14.4537 16.5483 11.478C16.5483 8.84429 14.6558 7.00276 11.9535 7.00276C8.82374 7.00276 6.98653 9.35033 6.98653 11.7765C6.98653 12.7219 7.35073 13.7357 7.80518 14.2867C7.89508 14.3957 7.90824 14.4912 7.88155 14.6022C7.79797 14.9498 7.6124 15.6969 7.57597 15.8497C7.528 16.0511 7.41655 16.0937 7.20808 15.9969C5.83426 15.3573 4.9753 13.3488 4.9753 11.7354C4.9753 8.2655 7.49644 5.07891 12.2434 5.07891C16.0593 5.07891 19.0247 7.79797 19.0247 11.4319C19.0247 15.223 16.6344 18.2739 13.3167 18.2739C12.2021 18.2739 11.1542 17.6949 10.7955 17.0109C10.7955 17.0109 10.2439 19.1111 10.1102 19.6257C9.86186 20.5812 9.19145 21.7788 8.74303 22.5094C9.77223 22.828 10.866 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92488 18.0751 1 12 1Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
PinterestIcon.displayName = 'PinterestIcon'
export { PinterestIcon }
