import clsx from 'clsx'
import React, { ChangeEventHandler, useContext } from 'react'

import useId from '../../helpers/useId'
import { CheckIcon } from '../icons'
import { VisuallyHidden } from '../VisuallyHidden'
import { generateSwatchCssProperties } from './helpers'

import styles from './SwatchGroup.module.css'

export interface SwatchProps {
  /**
   * Color value in hex, rgb, or hsl
   */
  color: string
  /**
   * Name of the color, it is used as a label for the swatch
   */
  name: string
}

interface SwatchGroupProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  /**
   * Value of the selected color, it is used to set the value of the input
   */
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const Swatch: React.FC<SwatchProps> = ({ color, name }) => {
  const { value, onChange, id } = useContext(SwatchGroupContext)
  const selected = value === color

  const swatchId = `${id}-${name}-swatch`

  return (
    <label htmlFor={swatchId}>
      <VisuallyHidden>{name}</VisuallyHidden>
      <input
        type="radio"
        id={swatchId}
        name={swatchId}
        value={color}
        checked={selected}
        onChange={onChange}
        className={styles.input}
      />
      <span
        className={styles.circle}
        style={generateSwatchCssProperties(color)}
      >
        {selected && <CheckIcon color="currentColor" />}
      </span>
    </label>
  )
}

const SwatchGroupContext = React.createContext<{
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  id: string
}>({ id: '' })

const SwatchGroup: React.FC<SwatchGroupProps> & {
  Swatch: React.FC<SwatchProps>
} = ({ value, onChange, className, children, ...props }: SwatchGroupProps) => {
  const id = useId()

  return (
    <SwatchGroupContext.Provider value={{ value, onChange, id }}>
      <div className={clsx(styles.base, className)} {...props}>
        {children}
      </div>
    </SwatchGroupContext.Provider>
  )
}

SwatchGroup.Swatch = Swatch

export { SwatchGroup }
