import React, { ReactNode } from 'react'
import clsx from 'clsx'

import { Dialog, DialogContentProps, DialogProps } from '../Dialog'
import { Flex } from '../Flex'
import styles from './SuccessDialog.module.css'
import { Heading } from '../Heading'
import { Paragraph, ParagraphProps } from '../Paragraph'
import { Image, ImageProps } from '../Image'

/*
 * SuccessDialog - main component
 */

interface SuccessDialogProps extends DialogProps {
  children: ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

/**
 * SuccessDialog
 *
 * This component renders a modal dialog with the following elements:
 *
 * - Close Button: Positioned at the top-right corner to close the modal.
 * - Image Placeholder: A large image area at the top.
 * - Title: A prominent title indicating the main message.
 * - Description: A short paragraph providing additional information or instructions.
 * - Primary Button: A prominent button for the primary action.
 * - Tertiary Button: A less prominent button for a secondary action.
 */
const SuccessDialog = ({
  children,
  open,
  onOpenChange,
}: SuccessDialogProps): JSX.Element => (
  <Dialog open={open} onOpenChange={onOpenChange}>
    {children}
  </Dialog>
)

SuccessDialog.displayName = 'SuccessDialog'

/*
 * SuccessDialogContent - sub-component
 */

interface SuccessDialogContentProps {
  children: ReactNode
  className?: string
  size?: DialogContentProps['size']
}

const SuccessDialogContent = React.forwardRef<
  HTMLDivElement,
  SuccessDialogContentProps
>(({ children, className, size = 'large' }, ref) => (
  <Dialog.Content
    size={size}
    className={`${styles.dialogContent} ${className || ''}`}
    ref={ref}
  >
    {children}
    <Dialog.CloseButton />
  </Dialog.Content>
))

SuccessDialogContent.displayName = 'SuccessDialog.Content'

/*
 * SuccessDialogImage - sub-component
 */

type SuccessDialogImageProps = ImageProps

const SuccessDialogImage = React.forwardRef<
  HTMLImageElement,
  SuccessDialogImageProps
>(({ className, ...props }, forwardedRef) => (
  <Image
    className={clsx(styles.image, className)}
    {...props}
    ref={forwardedRef}
  />
))

SuccessDialogImage.displayName = 'SuccessDialog.Image'

/*
 * SuccessDialogTitle - sub-component
 */

type SuccessDialogTitleProps = React.HTMLAttributes<HTMLHeadingElement>

const SuccessDialogTitle = React.forwardRef<
  HTMLHeadingElement,
  SuccessDialogTitleProps
>(({ className, ...props }, forwardedRef) => (
  <Heading
    size="medium"
    color="neutral"
    ref={forwardedRef}
    className={clsx(styles.title, className)}
    {...props}
  />
))

SuccessDialogTitle.displayName = 'SuccessDialog.Title'

/*
 * SuccessDialogDescription - sub-component
 */

type SuccessDialogDescriptionProps = ParagraphProps

const SuccessDialogDescription = React.forwardRef<
  HTMLHeadingElement,
  SuccessDialogDescriptionProps
>(({ className, ...props }, forwardedRef) => (
  <Paragraph
    ref={forwardedRef}
    className={clsx(styles.description, className)}
    {...props}
  />
))

SuccessDialogDescription.displayName = 'SuccessDialog.Description'

/*
 * SuccessDialogActions - sub-component
 */

type SuccessDialogActionsProps = React.HTMLAttributes<HTMLDivElement>

const SuccessDialogActions = React.forwardRef<
  HTMLDivElement,
  SuccessDialogActionsProps
>(({ children, className, ...props }, forwardedRef) => (
  <Flex
    direction="column"
    gap="xs"
    align="center"
    ref={forwardedRef}
    className={clsx(styles.actions, className)}
    {...props}
  >
    {children}
  </Flex>
))

SuccessDialogActions.displayName = 'SuccessDialog.Actions'

/*
 * SuccessDialogTrigger
 */

const SuccessDialogTrigger = Dialog.Trigger
SuccessDialogTrigger.displayName = 'SuccessDialog.Trigger'

const SuccessDialogObject = Object.assign(SuccessDialog, {
  Trigger: SuccessDialogTrigger,
  Content: SuccessDialogContent,
  Image: SuccessDialogImage,
  Title: SuccessDialogTitle,
  Description: SuccessDialogDescription,
  Actions: SuccessDialogActions,
})

export { SuccessDialogObject as SuccessDialog }

export type {
  SuccessDialogProps,
  SuccessDialogContentProps,
  SuccessDialogImageProps,
  SuccessDialogTitleProps,
  SuccessDialogDescriptionProps,
  SuccessDialogActionsProps,
}
