// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const TagIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'tag'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M17 7H16.9908M11.4091 2.586C11.7841 2.2109 12.2927 2.00011 12.8231 2H19.9951C20.5256 2 21.0343 2.21071 21.4093 2.58579C21.7844 2.96086 21.9951 3.46957 21.9951 4V11.172C21.995 11.7024 21.7842 12.211 21.4091 12.586L12.7051 21.29C12.2506 21.7416 11.6359 21.9951 10.9951 21.9951C10.3544 21.9951 9.73965 21.7416 9.28513 21.29L2.70513 14.71C2.25349 14.2555 2 13.6408 2 13C2 12.3592 2.25349 11.7445 2.70513 11.29L11.4091 2.586Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
TagIcon.displayName = 'TagIcon'
export { TagIcon }
