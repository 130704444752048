// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const AppearanceIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'appearance'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M5.00008 2L10.0001 7M2.00008 13H17.0001M19.0001 11L11.0001 3L2.40008 11.6C2.03363 11.9739 1.82837 12.4765 1.82837 13C1.82837 13.5235 2.03363 14.0261 2.40008 14.4L7.60008 19.6C8.40008 20.4 9.60008 20.4 10.4001 19.6L19.0001 11ZM22.0001 20C22.0001 20.5304 21.7894 21.0391 21.4143 21.4142C21.0392 21.7893 20.5305 22 20.0001 22C19.4697 22 18.9609 21.7893 18.5859 21.4142C18.2108 21.0391 18.0001 20.5304 18.0001 20C18.0001 18.4 19.7001 17.6 20.0001 16C20.3001 17.6 22.0001 18.4 22.0001 20Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
AppearanceIcon.displayName = 'AppearanceIcon'
export { AppearanceIcon }
