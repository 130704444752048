import React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import clsx from 'clsx'

import useId from '../../helpers/useId'
import { CheckIcon, MinusIcon } from '../icons'
import { Text } from '../Text'

import styles from './Checkbox.module.css'

type CheckboxElement = React.ElementRef<typeof CheckboxPrimitive.Root>

type CheckboxProps = Omit<
  CheckboxPrimitive.CheckboxProps,
  'onCheckedChange' | 'onChange'
> & {
  /**
   * Control whether the checkbox is checked or not
   */
  checked?: CheckboxPrimitive.CheckedState
  /**
   * Control whether the checkbox is checked or not
   * @default false
   */
  defaultChecked?: CheckboxPrimitive.CheckedState
  /**
   * Set classname on the checkbox
   */
  className?: string
  /**
   * Label of the checkbox, if passed a label element will be rendered
   */
  children?: React.ReactNode
  /**
   * Callback when the checkbox is checked or unchecked
   */
  onChange?: (checked: CheckboxPrimitive.CheckedState) => void
}

/**
 * Base input element to toggle an option on and off.
 */
const Checkbox = React.forwardRef<CheckboxElement, CheckboxProps>(
  (
    {
      id: passedId,
      className,
      checked,
      children,
      onChange,
      ...props
    }: CheckboxProps,
    forwardedRef,
  ) => {
    const id = useId(passedId)

    return (
      <div className={clsx(styles.container, className)}>
        <CheckboxPrimitive.Root
          {...props}
          id={id}
          checked={checked}
          ref={forwardedRef}
          className={clsx(styles.checkbox)}
          onCheckedChange={onChange}
        >
          <CheckboxPrimitive.Indicator asChild className={styles.indicator}>
            {checked === 'indeterminate' ? (
              <MinusIcon stroke={3} size="xsmall" />
            ) : (
              <CheckIcon stroke={3} size="xsmall" />
            )}
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>

        {children && (
          <Text as="label" htmlFor={id}>
            {children}
          </Text>
        )}
      </div>
    )
  },
)

Checkbox.displayName = 'Checkbox'

type CheckboxGroupProps = React.HTMLAttributes<HTMLFieldSetElement> & {
  label: React.ReactNode
}

const CheckboxGroup = React.forwardRef<HTMLFieldSetElement, CheckboxGroupProps>(
  (
    { label, children, className, ...props }: CheckboxGroupProps,
    forwardedRef,
  ) => {
    return (
      <fieldset
        ref={forwardedRef}
        className={clsx(styles.group, className)}
        {...props}
      >
        <Text as="legend" weight="bold" className={styles.legend}>
          {label}
        </Text>
        {children}
      </fieldset>
    )
  },
)

CheckboxGroup.displayName = 'Checkbox.Group'

const CheckboxObject = Object.assign(Checkbox, {
  Group: CheckboxGroup,
})

export { CheckboxObject as Checkbox }
export type { CheckboxProps }
