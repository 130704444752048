// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const WhatsappIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'whatsapp'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.92584 6.58748C8.71255 6.07667 8.55081 6.05725 8.22769 6.04419C8.11769 6.03783 7.99514 6.03147 7.85919 6.03147C7.43878 6.03147 6.9993 6.15436 6.73409 6.42592C6.41097 6.75575 5.60917 7.52523 5.60917 9.10305C5.60917 10.681 6.75988 12.2071 6.91508 12.4207C7.07681 12.6339 9.15856 15.9191 12.391 17.258C14.9188 18.3056 15.6688 18.2085 16.2441 18.0858C17.0846 17.9048 18.1383 17.2836 18.4035 16.5337C18.6686 15.7835 18.6686 15.1432 18.5909 15.0075C18.5134 14.8717 18.2999 14.7943 17.9768 14.6324C17.6537 14.4707 16.0825 13.6947 15.7852 13.5912C15.4942 13.4814 15.2163 13.5202 14.9966 13.8306C14.6862 14.2639 14.3825 14.7038 14.1368 14.9688C13.9429 15.1757 13.6259 15.2017 13.3609 15.0917C13.0053 14.9432 12.0098 14.5936 10.7814 13.5008C9.83094 12.6538 9.18452 11.5999 8.99717 11.2829C8.80966 10.9596 8.97775 10.772 9.12642 10.5975C9.28816 10.3969 9.44319 10.2548 9.60475 10.0671C9.76648 9.87958 9.85689 9.78264 9.96036 9.56264C10.0704 9.34934 9.99267 9.12934 9.91516 8.96761C9.83747 8.80553 9.19105 7.22755 8.92584 6.58748ZM11.9967 1C5.93264 1 1 5.93402 1 11.9998C1 14.4054 1.77584 16.6365 3.09464 18.4472L1.72394 22.5342L5.95206 21.1829C7.69109 22.334 9.76631 23 12.0033 23C18.0674 23 23 18.0656 23 12.0002C23 5.93436 18.0674 1.00034 12.0033 1.00034L11.9967 1Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
WhatsappIcon.displayName = 'WhatsappIcon'
export { WhatsappIcon }
