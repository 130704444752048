import React from 'react'
import clsx from 'clsx'
import * as HoverCardPrimitive from '@radix-ui/react-hover-card'

import usePortalContainer from '../../helpers/usePortalContainer'
import { OverlayArrow } from '../../helpers/OverlayArrow'

import styles from './HoverCard.module.css'

/**
 * HoverCard parent element, wraps trigger and content
 */
type HoverCardProps = HoverCardPrimitive.HoverCardProps & {
  children: React.ReactNode
  /** The controlled open state of the HoverCard. Must be used in conjunction with `onOpenChange`. */
  open?: boolean
  /** The open state of the HoverCard when it is initially rendered. Use when you do not need to control its open state. */
  defaultOpen?: boolean
  /** Event handler called when the open state of the HoverCard changes. */
  onOpenChange?: (open: boolean) => void
  /**
   * The duration from when the mouse enters the trigger until the hover card opens.
   * @default 700
   */
  openDelay?: number
  /**
   * The duration from when the mouse leaves the trigger or content until the hover card closes.
   * @default 300
   */
  closeDelay?: number
}

/**
 * HoverCard is used to display content on hover. It is for sighted users only and is often used to preview content available behind a link
 */
const HoverCard = ({
  openDelay = 700,
  closeDelay = 300,
  ...props
}: HoverCardProps): JSX.Element => (
  <HoverCardPrimitive.Root
    openDelay={openDelay}
    closeDelay={closeDelay}
    {...props}
  />
)

HoverCard.displayName = 'HoverCard'

/**
 * HoverCard trigger wrapper
 */
type HoverCardTriggerProps = Omit<
  React.ComponentPropsWithoutRef<typeof HoverCardPrimitive.Trigger>,
  'asChild'
>

const HoverCardTrigger = React.forwardRef<
  React.ElementRef<typeof HoverCardPrimitive.Trigger>,
  HoverCardTriggerProps
>((props, forwardedRef) => (
  <HoverCardPrimitive.Trigger {...props} ref={forwardedRef} asChild />
))

HoverCardTrigger.displayName = 'HoverCard.Trigger'

/**
 * HoverCard content
 */
type HoverCardContentProps = {
  className?: string
  /**
   * The preferred side of the anchor to render against when open. Will be reversed when collisions occur and `avoidCollisions` is enabled.
   * @default 'bottom'
   */
  side?: 'top' | 'right' | 'bottom' | 'left'
  /**
   * The preferred alignment against the anchor. May change when collisions occur.
   * @default 'center'
   */
  align?: 'start' | 'center' | 'end'
  /** Contents of the HoverCard */
  children: React.ReactNode
  /** Whether the content have an arrow */
  arrow?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const HoverCardContent = React.forwardRef<
  React.ElementRef<typeof HoverCardPrimitive.Content>,
  HoverCardContentProps
>(
  (
    {
      className,
      align = 'center',
      side = 'bottom',
      children,
      arrow = true,
      ...contentProps
    },
    forwardedRef,
  ) => {
    const container = usePortalContainer()

    return (
      <HoverCardPrimitive.Portal container={container}>
        <HoverCardPrimitive.Content
          className={clsx(styles.content, className)}
          align={align}
          side={side}
          sideOffset={8}
          collisionPadding={16}
          // to allow arrow to show with rounded corners
          arrowPadding={16}
          ref={forwardedRef}
          {...contentProps}
        >
          {children}
          {arrow && (
            <HoverCardPrimitive.Arrow asChild>
              <OverlayArrow size={20} className={styles.arrow} />
            </HoverCardPrimitive.Arrow>
          )}
        </HoverCardPrimitive.Content>
      </HoverCardPrimitive.Portal>
    )
  },
)

HoverCardContent.displayName = 'HoverCard.Content'

const HoverCardObject = Object.assign(HoverCard, {
  Trigger: HoverCardTrigger,
  Content: HoverCardContent,
})

export { HoverCardObject as HoverCard }
export type { HoverCardProps, HoverCardTriggerProps, HoverCardContentProps }
