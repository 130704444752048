import React from 'react'
import clsx from 'clsx'
import type * as Polymorphic from '@radix-ui/react-polymorphic'

import styles from './UnstyledButton.module.css'

type PolymorphicUnstyledButton = Polymorphic.ForwardRefComponent<
  'button',
  object
>

/**
 * Unstyled button utility component to use as a base for other buttons
 */
export const UnstyledButton = React.forwardRef(
  ({ as: Tag = 'button', className, children, ...props }, forwardedRef) => {
    return (
      <Tag
        className={clsx(styles.base, className)}
        ref={forwardedRef}
        {...props}
      >
        {children}
      </Tag>
    )
  },
) as PolymorphicUnstyledButton

UnstyledButton.displayName = 'UnstyledButton'

type UnstyledButtonProps = React.ComponentProps<typeof UnstyledButton>

export type { UnstyledButtonProps }
