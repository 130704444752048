import React from 'react'
import clsx from 'clsx'

import styles from './Separator.module.css'

type SeparatorElement = React.ElementRef<'div'>
interface SeparatorProps extends React.ComponentPropsWithoutRef<'div'> {
  /**
   * Either `vertical` or `horizontal`.
   */
  orientation?: 'horizontal' | 'vertical'

  /**
   * Whether or not the component is purely decorative. When true, accessibility-related attributes
   * are updated so that that the rendered element is removed from the accessibility tree.
   */
  decorative?: boolean
}

const Separator = React.forwardRef<SeparatorElement, SeparatorProps>(
  (
    {
      orientation = 'horizontal',
      decorative = false,
      className,
      ...props
    }: SeparatorProps,
    forwardedRef,
  ) => {
    const ariaOrientation = orientation === 'vertical' ? orientation : undefined
    const semanticProps = decorative
      ? { role: 'none' }
      : { 'aria-orientation': ariaOrientation, role: 'separator' }
    const separatorClassName = clsx(
      styles.separator,
      orientation === 'horizontal' ? styles.horizontal : styles.vertical,
      className,
    )

    return (
      <span
        data-orientation={orientation}
        {...semanticProps}
        {...props}
        ref={forwardedRef}
        className={separatorClassName}
      />
    )
  },
)

Separator.displayName = 'Separator'

export { Separator }
export type { SeparatorProps }
