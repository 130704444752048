import { useCallback } from 'react'
import { flushSync } from 'react-dom'

declare global {
  interface Document {
    startViewTransition?: (callback: () => void) => void
  }
}

/**
 * Hook to use View Transition API in React components. If the browser does not support it, the callback
 * is executed immediately.
 *
 * @example
 * ```tsx
 * const transition = useViewTransition()
 *
 * transition(() => {
 *  setState('new state')
 * })
 * ```
 */
export function useViewTransition() {
  const startViewTransition = useCallback((callback: () => void) => {
    if (!document.startViewTransition) {
      callback()
      return
    }

    document.startViewTransition(() => {
      flushSync(callback)
    })
  }, [])

  return startViewTransition
}
