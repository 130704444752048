// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const LinkShorteningOffIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'link-shortening-off'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M10.0078 12.6053C10.3314 13.0046 10.7443 13.3351 11.2184 13.5742C11.6925 13.8133 12.2168 13.9555 12.7557 13.9911C13.2946 14.0267 13.8355 13.955 14.3417 13.7807C14.8479 13.6064 15.3076 13.3336 15.6895 12.9809L17.9502 10.8941C18.6365 10.2382 19.0163 9.35963 19.0077 8.44773C18.9991 7.53582 18.6028 6.66351 17.9043 6.01867C17.2057 5.37383 16.2607 5.00806 15.2728 5.00013C14.2849 4.99221 13.3332 5.34277 12.6226 5.9763L11.3265 7.16576M14.0078 11.3947C13.6842 10.9954 13.2713 10.6649 12.7972 10.4258C12.3231 10.1867 11.7988 10.0445 11.2599 10.0089C10.721 9.97326 10.1801 10.045 9.67392 10.2193C9.16772 10.3936 8.70805 10.6664 8.32609 11.0191L6.06546 13.1059C5.37913 13.7618 4.99937 14.6404 5.00795 15.5523C5.01654 16.4642 5.41279 17.3365 6.11135 17.9813C6.80992 18.6262 7.75491 18.9919 8.74279 18.9999C9.73068 19.0078 10.6824 18.6572 11.393 18.0237L12.6816 16.8342M2 6L2 2M2 2L6 2M2 2L6 6M22 18V22M22 22H18M22 22L18 18"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
LinkShorteningOffIcon.displayName = 'LinkShorteningOffIcon'
export { LinkShorteningOffIcon }
