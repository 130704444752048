import React from 'react'
import clsx from 'clsx'

import { Badge, BadgeProps } from './Badge'

import styles from './BetaBadge.module.css'

type BetaBadgeProps = Omit<BadgeProps, 'children'>

const BetaBadge = React.forwardRef<HTMLSpanElement, BetaBadgeProps>(
  ({ className, ...props }, forwardedRef) => {
    return (
      <Badge
        className={clsx(className, styles.beta)}
        {...props}
        ref={forwardedRef}
      >
        Beta
      </Badge>
    )
  },
)

BetaBadge.displayName = 'BetaBadge'

export { BetaBadge, BetaBadgeProps }
