// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const GooglePhotosIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'google-photos'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M15.8891 10.3891C16.9205 9.35764 17.5 7.95869 17.5 6.5C17.5 5.04131 16.9205 3.64236 15.8891 2.61091C14.8576 1.57946 13.4587 1 12 1V12C12 10.5413 11.4205 9.14236 10.3891 8.11091C9.35764 7.07946 7.95869 6.5 6.5 6.5C5.04131 6.5 3.64236 7.07946 2.61091 8.11091C1.57946 9.14236 1 10.5413 1 12H12C10.5413 12 9.14236 12.5795 8.11091 13.6109C7.07946 14.6424 6.5 16.0413 6.5 17.5C6.5 18.9587 7.07946 20.3576 8.11091 21.3891C9.14236 22.4205 10.5413 23 12 23V12C12 13.4587 12.5795 14.8576 13.6109 15.8891C14.6424 16.9205 16.0413 17.5 17.5 17.5C18.9587 17.5 20.3576 16.9205 21.3891 15.8891C22.4205 14.8576 23 13.4587 23 12H12C13.4587 12 14.8576 11.4205 15.8891 10.3891Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
GooglePhotosIcon.displayName = 'GooglePhotosIcon'
export { GooglePhotosIcon }
