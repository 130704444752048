// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const PaypalIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'paypal'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M21 10.1342C21 10.5808 20.9415 11.0375 20.8479 11.49C20.0856 15.1725 17.4765 16.445 14.1438 16.445H13.629C13.2213 16.445 12.8748 16.7233 12.8118 17.1025L12.0891 21.3442C12.0252 21.7217 11.6787 22 11.2719 22H8.80954C8.45855 22 8.19035 21.71 8.24435 21.39L9.49174 13.99L9.54754 13.9433H11.487C16.1985 13.9433 19.1451 11.7617 20.0127 7.63667C20.7435 8.315 21 9.2 21 10.1342ZM8.20565 13.6775C8.31005 12.9892 8.61875 12.6967 9.45214 12.695L11.4861 12.6933C15.6027 12.6933 17.9643 10.9517 18.7068 7.36833C19.4268 3.92333 16.815 2 13.2789 2H6.66935C6.20405 2 5.80716 2.31833 5.73516 2.75083C3.66156 15.1133 3.06217 18.2567 3.00097 19.2158C2.98207 19.5175 3.24306 19.7758 3.57246 19.7758H7.16255L8.20565 13.6775Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
PaypalIcon.displayName = 'PaypalIcon'
export { PaypalIcon }
