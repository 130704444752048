import React from 'react'
import clsx from 'clsx'

import { UnstyledButton } from '../UnstyledButton'
import { CloseIcon } from '../icons'
import { VisuallyHidden } from '../VisuallyHidden'
import { Heading } from '../Heading'
import { Paragraph, ParagraphProps } from '../Paragraph'

import styles from './PromotionalBanner.module.css'

type PromotionalBannerProps = React.HTMLAttributes<HTMLDivElement> & {
  onDismiss?: React.MouseEventHandler<HTMLButtonElement>
}

type PromotionalBannerContentProps = React.HTMLAttributes<HTMLDivElement>

const PromotionalBannerContent = React.forwardRef<
  HTMLDivElement,
  PromotionalBannerContentProps
>(({ className, ...props }, forwardedRef) => (
  <div
    ref={forwardedRef}
    className={clsx(styles.content, className)}
    {...props}
  />
))

PromotionalBannerContent.displayName = 'PromotionalBanner.Content'

type PromotionalBannerHeadingProps = React.HTMLAttributes<HTMLHeadingElement>

const PromotionalBannerHeading = React.forwardRef<
  HTMLHeadingElement,
  PromotionalBannerHeadingProps
>(({ className, ...props }, forwardedRef) => (
  <Heading
    size="small"
    ref={forwardedRef}
    className={clsx(styles.heading, className)}
    {...props}
  />
))

PromotionalBannerHeading.displayName = 'PromotionalBanner.Heading'

type PromotionalBannerDescriptionProps = ParagraphProps

const PromotionalBannerDescription = React.forwardRef<
  HTMLHeadingElement,
  PromotionalBannerDescriptionProps
>(({ className, ...props }, forwardedRef) => (
  <Paragraph
    ref={forwardedRef}
    className={clsx(styles.description, className)}
    {...props}
  />
))

PromotionalBannerDescription.displayName = 'PromotionalBanner.Description'

type PromotionalBannerActionsProps = React.HTMLAttributes<HTMLDivElement>

const PromotionalBannerActions = React.forwardRef<
  HTMLDivElement,
  PromotionalBannerActionsProps
>(({ className, ...props }, forwardedRef) => (
  <div
    ref={forwardedRef}
    className={clsx(styles.actions, className)}
    {...props}
  />
))

PromotionalBannerActions.displayName = 'PromotionalBanner.Actions'

type PromotionalBannerImageProps = Omit<
  React.ImgHTMLAttributes<HTMLImageElement>,
  'alt'
> & {
  fallbackColor?: string
}

const PromotionalBannerImage = React.forwardRef<
  HTMLImageElement,
  PromotionalBannerImageProps
>(({ className, fallbackColor = '#e0e0e0', ...props }, forwardedRef) => (
  <div
    className={clsx(styles.image, className)}
    style={{ backgroundColor: fallbackColor }}
  >
    <img loading="lazy" ref={forwardedRef} alt="" {...props} />
  </div>
))

PromotionalBannerImage.displayName = 'PromotionalBanner.Image'

type PromotionalBannerBadgeProps = React.HTMLAttributes<HTMLDivElement>

const PromotionalBannerBadge = React.forwardRef<
  HTMLImageElement,
  PromotionalBannerBadgeProps
>(({ className, ...props }, forwardedRef) => (
  <div
    className={clsx(styles.badge, className)}
    ref={forwardedRef}
    {...props}
  />
))

PromotionalBannerBadge.displayName = 'PromotionalBanner.Badge'

const PromotionalBanner = React.forwardRef<
  HTMLDivElement,
  PromotionalBannerProps
>(
  (
    { className, children, onDismiss, ...props }: PromotionalBannerProps,
    forwardedRef,
  ) => {
    return (
      <div
        ref={forwardedRef}
        role="complementary"
        className={clsx(styles.promotionalBanner, className)}
        {...props}
      >
        {children}
        {onDismiss && (
          <UnstyledButton onClick={onDismiss} className={styles.closeButton}>
            <VisuallyHidden>Dismiss</VisuallyHidden>
            <CloseIcon color="currentColor" />
          </UnstyledButton>
        )}
      </div>
    )
  },
)

PromotionalBanner.displayName = 'PromotionalBanner'

const PromotionalBannerObject = Object.assign(PromotionalBanner, {
  Content: PromotionalBannerContent,
  Heading: PromotionalBannerHeading,
  Description: PromotionalBannerDescription,
  Actions: PromotionalBannerActions,
  Image: PromotionalBannerImage,
  Badge: PromotionalBannerBadge,
})

export {
  PromotionalBannerObject as PromotionalBanner,
  PromotionalBannerProps,
  PromotionalBannerContentProps,
  PromotionalBannerHeadingProps,
  PromotionalBannerImageProps,
  PromotionalBannerActionsProps,
  PromotionalBannerBadgeProps,
  PromotionalBannerDescriptionProps,
}
