import React from 'react'
import clsx from 'clsx'
import * as PopoverPrimitive from '@radix-ui/react-popover'

import usePortalContainer from '../../helpers/usePortalContainer'
import { OverlayArrow } from '../../helpers/OverlayArrow'

import styles from './Popover.module.css'

/**
 * Popover parent element, wraps trigger and content
 */
type PopoverProps = PopoverPrimitive.PopoverProps & {
  children: React.ReactNode
  /** The controlled open state of the popover. Must be used in conjunction with `onOpenChange`. */
  open?: boolean
  /** The open state of the popover when it is initially rendered. Use when you do not need to control its open state. */
  defaultOpen?: boolean
  /** Event handler called when the open state of the popover changes. */
  onOpenChange?: (open: boolean) => void
  /** The modality of the popover. When set to true, interaction with outside elements will be disabled and only popover content will be visible to screen readers. */
  modal?: boolean
}

const Popover = (props: PopoverProps): JSX.Element => (
  <PopoverPrimitive.Root {...props} />
)

Popover.displayName = 'Popover'

/**
 * Popover trigger wrapper
 */
type PopoverTriggerProps = Omit<
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger>,
  'asChild'
>
const PopoverTrigger = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Trigger>,
  PopoverTriggerProps
>((props, forwardedRef) => (
  <PopoverPrimitive.Trigger {...props} ref={forwardedRef} asChild />
))

PopoverTrigger.displayName = 'Popover.Trigger'

/**
 * Popover Anchor wrapper
 */
type PopoverAnchorProps = Omit<
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Anchor>,
  'asChild'
>

const PopoverAnchor = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Anchor>,
  PopoverAnchorProps
>((props, forwardedRef) => (
  <PopoverPrimitive.Anchor {...props} ref={forwardedRef} asChild />
))

PopoverAnchor.displayName = 'Popover.Anchor'

/**
 * Popover content
 */
type PopoverContentProps = {
  className?: string
  /**
   * The preferred side of the anchor to render against when open. Will be reversed when collisions occur and `avoidCollisions` is enabled.
   * @default 'bottom'
   */
  side?: 'top' | 'right' | 'bottom' | 'left'
  /**
   * The preferred alignment against the anchor. May change when collisions occur.
   * @default 'end'
   */
  align?: 'start' | 'center' | 'end'
  /**
   * Whether the popover should render an arrow pointing to the trigger.
   * @default false
   */
  arrow?: boolean
  /**
   * Contents of the popover
   */
  children: React.ReactNode
  /**
   * Size of the popover, limits maximum width
   * by default, the popover will grow to fit its content
   */
  size?: 'small' | 'medium' | 'large'
} & Partial<
  Pick<
    PopoverPrimitive.PopoverContentProps,
    'sideOffset' | 'alignOffset' | 'onEscapeKeyDown'
  >
> &
  React.HTMLAttributes<HTMLDivElement>

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  PopoverContentProps
>(
  (
    {
      className,
      arrow = false,
      align = 'end',
      alignOffset = 0,
      side = 'bottom',
      sideOffset = 8,
      size,
      children,
      ...contentProps
    },
    forwardedRef,
  ) => {
    const container = usePortalContainer()

    return (
      <PopoverPrimitive.Portal container={container}>
        <PopoverPrimitive.Content
          className={clsx(styles.content, size && styles[size], className)}
          align={align}
          alignOffset={alignOffset}
          side={side}
          sideOffset={sideOffset}
          collisionPadding={16}
          arrowPadding={16}
          ref={forwardedRef}
          {...contentProps}
        >
          {children}
          {arrow && (
            <PopoverPrimitive.Arrow asChild>
              <OverlayArrow size={16} className={styles.arrow} />
            </PopoverPrimitive.Arrow>
          )}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    )
  },
)

PopoverContent.displayName = 'Popover.Content'

const PopoverObject = Object.assign(Popover, {
  Trigger: PopoverTrigger,
  Content: PopoverContent,
  Anchor: PopoverAnchor,
})

export { PopoverObject as Popover }
export type { PopoverProps, PopoverTriggerProps, PopoverContentProps }
