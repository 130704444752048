import React, { createContext, useContext } from 'react'

const ContainerContext = createContext<HTMLElement | null>(null)

/** @internal */
export function PortalContainerProvider({
  container,
  children,
}: {
  container: HTMLElement
  children: React.ReactNode
}) {
  return (
    <ContainerContext.Provider value={container}>
      {children}
    </ContainerContext.Provider>
  )
}

/** @internal */
export default function usePortalContainer(): HTMLElement | null {
  return useContext(ContainerContext)
}
