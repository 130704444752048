// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const WandSparklesIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'wand-sparkles'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M14 7L17 10M5.00005 6V10M19 14V18M10 2V4M7.00004 8H3.00005M21 16H17M11 3H9.00004M21.64 3.64L20.36 2.36C20.2475 2.24632 20.1136 2.15607 19.966 2.09448C19.8184 2.03289 19.66 2.00118 19.5 2.00118C19.3401 2.00118 19.1817 2.03289 19.0341 2.09448C18.8865 2.15607 18.7526 2.24632 18.64 2.36L2.36004 18.64C2.24636 18.7525 2.15612 18.8865 2.09452 19.0341C2.03293 19.1817 2.00122 19.3401 2.00122 19.5C2.00122 19.6599 2.03293 19.8183 2.09452 19.9659C2.15612 20.1135 2.24636 20.2475 2.36004 20.36L3.64005 21.64C3.75187 21.7549 3.88558 21.8462 4.03329 21.9086C4.181 21.971 4.33971 22.0031 4.50005 22.0031C4.66038 22.0031 4.81909 21.971 4.9668 21.9086C5.11451 21.8462 5.24822 21.7549 5.36005 21.64L21.64 5.36C21.755 5.24818 21.8463 5.11447 21.9087 4.96676C21.971 4.81905 22.0031 4.66034 22.0031 4.5C22.0031 4.33966 21.971 4.18095 21.9087 4.03324C21.8463 3.88553 21.755 3.75182 21.64 3.64Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
WandSparklesIcon.displayName = 'WandSparklesIcon'
export { WandSparklesIcon }
