// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const GithubIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'github'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9664 1C5.91131 1 1 5.81879 1 11.7598C1 16.5126 4.16208 20.5392 8.46789 21.9915C9.00612 22.0575 9.20795 21.7274 9.20795 21.4634C9.20795 21.1993 9.20795 20.5392 9.20795 19.6151C6.18043 20.2752 5.50764 18.1628 5.50764 18.1628C5.0367 16.9086 4.29664 16.5786 4.29664 16.5786C3.28746 15.9185 4.36391 15.9185 4.36391 15.9185C5.44037 15.9845 6.04587 17.0406 6.04587 17.0406C7.05505 18.6909 8.60245 18.2288 9.20795 17.9648C9.27523 17.2387 9.61162 16.7766 9.88073 16.5126C7.45871 16.2485 4.90214 15.3244 4.90214 11.1657C4.90214 9.97748 5.30581 9.05333 6.04587 8.2612C5.97859 8.06316 5.57492 6.94098 6.18043 5.48874C6.18043 5.48874 7.12232 5.2247 9.20795 6.61092C10.0826 6.34688 11.0245 6.28087 11.9664 6.28087C12.9083 6.28087 13.8502 6.41289 14.7248 6.61092C16.8104 5.2247 17.7523 5.48874 17.7523 5.48874C18.3578 6.94098 17.9541 8.06316 17.8869 8.32721C18.5596 9.05333 19.0306 10.0435 19.0306 11.2317C19.0306 15.3904 16.474 16.2485 14.052 16.5126C14.4557 16.8426 14.792 17.5027 14.792 18.4929C14.792 19.9451 14.792 21.0673 14.792 21.4634C14.792 21.7274 14.9939 22.0575 15.5321 21.9915C19.9052 20.5392 23 16.5126 23 11.7598C22.9327 5.81879 18.0214 1 11.9664 1Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
GithubIcon.displayName = 'GithubIcon'
export { GithubIcon }
