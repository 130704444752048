import { isLightColor } from '../../helpers/colors'

export const generateSwatchCssProperties = (
  color: string,
): React.CSSProperties => {
  return {
    '--swatch-background-color': color,
    '--swatch-text-color': isLightColor(color)
      ? `color-mix(in srgb, ${color} 10%, black)`
      : `color-mix(in srgb, ${color} 10%, white)`,
  } as React.CSSProperties
}
