import React, { forwardRef } from 'react'
import clsx from 'clsx'
import * as ToastPrimitive from '@radix-ui/react-toast'

import { CheckCircleIcon, CloseIcon, CriticalIcon, IconProps } from '../icons'
import { IconButton } from '../IconButton'

import styles from './Toast.module.css'

/**
 * This component is not exported and used only internally.
 * If you need toast notifications, please use imperative API
 * import { toast } from 'popcorn'
 */
type ToastVariant = 'neutral' | 'error' | 'success' | 'info' | 'warning'

type ToastProps = {
  /**
   * Variant of the toast
   * @default 'neutral'
   */
  variant?: ToastVariant
  /**
   * Children is the trigger of the Toast
   */
  children: React.ReactNode
  /**
   * Control the sensitivity of the toast for accessibility purposes. For toasts that are
   * the result of a user action, choose foreground. Toasts generated from background
   * tasks should use background.
   * (currently not in use)
   * @default 'foreground'
   */
  type?: 'foreground' | 'background'
  /**
   * Controls whether the Toast is dismissible
   */
  dismissible?: boolean
  /**
   * Duration the Toast is shown
   */
  duration?: number
  /**
   * Controls whether the Toast is open
   */
  open?: boolean
  /**
   * Controls whether the Toast is open by default
   * @default false
   */
  defaultOpen?: boolean
  /**
   * Classname applied to the Toast
   */
  className?: string
  /**
   * Callback when the Toast is opened or closed
   */
  onDismiss?: () => void
}

const iconByVariant: Partial<
  Record<ToastVariant, React.ComponentType<IconProps>>
> = {
  error: CriticalIcon,
  success: CheckCircleIcon,
}

const Toast = forwardRef<
  React.ElementRef<typeof ToastPrimitive.Root>,
  ToastProps
>(
  (
    {
      children,
      variant = 'neutral',
      type = 'foreground',
      duration,
      dismissible = true,
      open,
      defaultOpen,
      className,
      onDismiss,
    }: ToastProps,
    forwardedRef,
  ) => {
    const onOpenChange = (open: boolean): void => {
      if (!open && onDismiss) {
        onDismiss()
      }
    }

    const Icon = iconByVariant[variant]

    return (
      <ToastPrimitive.Root
        ref={forwardedRef}
        // when udefined, aria-role will be set by radix based on type
        role={variant === 'error' ? 'alert' : undefined}
        data-testid="toast"
        type={type}
        duration={duration}
        // when dismissible is true, open is controlled by the toast state
        open={dismissible ? open : true}
        defaultOpen={defaultOpen}
        className={clsx(styles.toast, styles[variant], className)}
        onOpenChange={onOpenChange}
      >
        {Icon && (
          <div className={styles.icon} aria-hidden>
            <Icon />
          </div>
        )}
        <ToastPrimitive.Title className={styles.message}>
          {children}
        </ToastPrimitive.Title>
        {dismissible && (
          <ToastPrimitive.Close asChild>
            <IconButton
              size="small"
              variant="tertiary"
              label="Dismiss"
              className={styles.close}
            >
              <CloseIcon />
            </IconButton>
          </ToastPrimitive.Close>
        )}
      </ToastPrimitive.Root>
    )
  },
)

Toast.displayName = 'Toast'

type ToastActionsProps = React.HTMLAttributes<HTMLDivElement>

const ToastActions = forwardRef<React.ElementRef<'div'>, ToastActionsProps>(
  ({ className, children, ...props }, forwardedRef) => (
    <div
      {...props}
      className={clsx(styles.actions, className)}
      ref={forwardedRef}
    >
      {children}
    </div>
  ),
)

ToastActions.displayName = 'Toast.Actions'

const ToastObject = Object.assign(Toast, {
  Actions: ToastActions,
})

export { ToastObject as Toast }
export type { ToastProps, ToastVariant }
