// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const OneDriveIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'one-drive'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M1.98831 16.66C1.35834 15.7627 1.01406 14.6981 1.00043 13.6042C0.96284 10.5886 3.3935 8.11376 6.42945 8.0764C7.36953 6.65765 8.80821 5.63764 10.4645 5.21572C14.1426 4.27878 17.8889 6.48089 18.8322 10.1343C19.4684 10.177 20.0882 10.3546 20.6496 10.655C22.8225 11.8176 23.6351 14.5096 22.4647 16.6679C21.685 18.1048 20.1738 19.0005 18.5304 19H6.49973C4.70085 19.0009 3.01555 18.1268 1.98831 16.66Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
OneDriveIcon.displayName = 'OneDriveIcon'
export { OneDriveIcon }
