// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const QuestionIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'question'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M8.71423 9.12401C8.93961 8.48331 9.38448 7.94304 9.97003 7.59891C10.5556 7.25477 11.244 7.12898 11.9134 7.2438C12.5829 7.35862 13.19 7.70665 13.6274 8.22625C14.0648 8.74585 14.3042 9.40348 14.3032 10.0827C14.3032 12 11.4272 12.9587 11.4272 12.9587M11.5039 16.7933H11.5139M7.9 19.5039C9.80858 20.483 12.0041 20.7482 14.0909 20.2517C16.1777 19.7552 18.0186 18.5298 19.2818 16.7961C20.545 15.0624 21.1474 12.9346 20.9806 10.7961C20.8137 8.65754 19.8886 6.64889 18.3718 5.13212C16.855 3.61534 14.8464 2.69017 12.7078 2.52334C10.5693 2.3565 8.44147 2.95897 6.70782 4.22217C4.97417 5.48537 3.74869 7.32623 3.25222 9.41303C2.75575 11.4998 3.02094 13.6953 4 15.6039L2 21.5039L7.9 19.5039Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
QuestionIcon.displayName = 'QuestionIcon'
export { QuestionIcon }
