// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const DiscordIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'discord'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2363 20C17.2363 20 16.5582 19.181 15.9932 18.4572C18.4606 17.7524 19.4024 16.1905 19.4024 16.1905C18.6301 16.7048 17.8955 17.0667 17.2363 17.3143C16.2945 17.7143 15.3904 17.981 14.5051 18.1334C12.6969 18.4762 11.0394 18.381 9.62671 18.1143C8.55308 17.9048 7.63014 17.6 6.85788 17.2953C6.42466 17.1238 5.95377 16.9143 5.48288 16.6477C5.45462 16.6286 5.42637 16.6143 5.39812 16.6C5.36986 16.5857 5.34161 16.5715 5.31336 16.5524C5.27569 16.5334 5.25685 16.5143 5.23801 16.4953C4.89897 16.3048 4.71062 16.1715 4.71062 16.1715C4.71062 16.1715 5.61473 17.6953 8.00685 18.4191C7.44178 19.1429 6.74486 20 6.74486 20C2.58219 19.8667 1 17.1048 1 17.1048C1 10.9715 3.71233 6.00014 3.71233 6.00014C6.42466 3.94302 9.00514 4.00016 9.00514 4.00016L9.19349 4.22873C5.80308 5.21919 4.23973 6.72394 4.23973 6.72394C4.23973 6.72394 4.65411 6.49537 5.35103 6.17157C7.36644 5.27634 8.96747 5.02872 9.62671 4.97158L9.66894 4.96436C9.76395 4.948 9.84826 4.93348 9.94692 4.93348C11.0959 4.7811 12.3955 4.74301 13.7517 4.89539C15.5411 5.10491 17.4623 5.63824 19.4212 6.72394C19.4212 6.72394 17.9332 5.29538 14.7312 4.30492L14.9949 4.00016C14.9949 4.00016 17.5753 3.94302 20.2877 6.00014C20.2877 6.00014 23 10.9715 23 17.1048C23 17.1048 21.399 19.8667 17.2363 20ZM15.3527 11.1049C14.2791 11.1049 13.4315 12.0572 13.4315 13.2191C13.4315 14.381 14.2979 15.3334 15.3527 15.3334C16.4264 15.3334 17.274 14.381 17.274 13.2191C17.274 12.0572 16.4264 11.1049 15.3527 11.1049ZM6.55651 13.2191C6.55651 12.0572 7.40411 11.1049 8.47774 11.1049C9.55137 11.1049 10.4178 12.0572 10.399 13.2191C10.399 14.381 9.55137 15.3334 8.47774 15.3334C7.42295 15.3334 6.55651 14.381 6.55651 13.2191Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
DiscordIcon.displayName = 'DiscordIcon'
export { DiscordIcon }
