// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const DribbbleIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'dribbble'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92796 1 1 5.92796 1 12C1 18.072 5.92796 23 12 23C18.0501 23 23 18.0722 23 12C23 5.92778 18.0501 1 12 1ZM10.0857 3.11278L10.0576 3.07332C10.6811 2.94929 11.3232 2.87951 12 2.87951C14.2669 2.87951 16.3696 3.7233 17.9645 5.08825C17.616 5.53782 16.2431 7.13205 13.3446 8.2458C12.0187 5.82029 10.5763 3.79993 10.0857 3.11278ZM11.2734 8.89308C7.37339 9.9024 3.92784 9.96945 3.06602 9.97359C3.71478 7.23675 5.55432 4.97343 8.00202 3.78413C8.38532 4.31524 9.86296 6.41111 11.2734 8.89308ZM14.2069 9.85425C17.1878 8.6179 18.737 6.89112 19.1721 6.35185C20.3583 7.85111 21.062 9.70921 21.1374 11.7464C20.4002 11.6049 17.6779 11.1498 14.9105 11.4622C14.8915 11.4158 14.872 11.367 14.8526 11.3185C14.8203 11.2376 14.7872 11.1549 14.754 11.0774L14.7531 11.0755C14.5775 10.6657 14.4004 10.2524 14.2069 9.85425ZM2.85778 12.0217V11.8793C3.74281 11.896 7.9057 11.8437 12.1903 10.5765C12.4116 11.0194 12.6307 11.461 12.8354 11.9163L12.8104 11.9229L12.7891 11.9285C12.7339 11.9428 12.6717 11.9589 12.6095 11.9796C8.1625 13.415 5.63622 17.1352 5.09314 17.9888C3.68818 16.379 2.85778 14.2917 2.85778 12.0217ZM17.2705 19.4595C17.1217 18.607 16.6212 16.1538 15.5523 13.1962C18.1352 12.8299 20.3949 13.4127 20.9936 13.5761C20.5733 16.0007 19.2013 18.1051 17.2705 19.4595ZM15.4775 20.4558C15.3239 19.5336 14.7873 16.8635 13.5713 13.6701C8.75004 15.3716 6.83705 18.6118 6.47989 19.2839C8.00722 20.4421 9.92121 21.1422 12 21.1422C13.2262 21.1422 14.4115 20.9002 15.4775 20.4558Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
DribbbleIcon.displayName = 'DribbbleIcon'
export { DribbbleIcon }
