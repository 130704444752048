import getCurrentURL from './getCurrentURL'

const ENV_LOCAL = 'local'
const ENV_STAGING = 'staging'
const ENV_PRODUCTION = 'production'

function getEnvironmentFromURL() {
  const currentURL = getCurrentURL()
  const isStaging = !!currentURL.host.match(/dev.buffer.com/i)
  const isLocal = !!currentURL.host.match(/local.buffer.com/i)

  if (isLocal) {
    return ENV_LOCAL
  }
  if (isStaging) {
    return ENV_STAGING
  }
  return ENV_PRODUCTION
}

export default getEnvironmentFromURL
