import React from 'react'
import clsx from 'clsx'

import styles from './OverlayArrow.module.css'

/**
 * Internal component to render the arrow of the overlay content,
 * such as hover card, menu or popover
 */
type OverlayArrowProps = React.ComponentPropsWithoutRef<'div'> & {
  size?: number
}

const OverlayArrow = React.forwardRef<
  React.ElementRef<'div'>,
  OverlayArrowProps
>(({ size = 16, className, ...props }, ref) => (
  <div
    ref={ref}
    data-arrow={true}
    {...props}
    className={clsx(styles.arrow, className)}
    style={
      {
        '--arrow-size': size + 'px',
      } as React.CSSProperties
    }
  />
))

OverlayArrow.displayName = 'OverlayArrow'

export { OverlayArrow }
export type { OverlayArrowProps }
