import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import clsx from 'clsx'
import React, { forwardRef } from 'react'
import { Text } from '../Text'
import styles from './RadioGroup.module.css'

interface RadioGroupProps
  extends Omit<RadioGroupPrimitive.RadioGroupProps, 'onChange'> {
  /**
   * Children are the elements in the radio group
   */
  children: React.ReactNode
  /**
   * The controlled value of the pressed items
   */
  value?: string
  /**
   * The value of the item to show as pressed. Use when you do not need
   * to control the state of the items
   */
  defaultValue?: string
  /**
   * Event handler called when the pressed state of an item changes
   */
  onChange?: (value: string) => void
  /**
   * When true, prevents the user from interacting with the radio group and
   * all its items
   * @default false
   */
  disabled?: boolean
  /**
   * Label describing the radio group
   */
  label?: React.ReactNode
}

const RadioGroup = React.forwardRef<HTMLFieldSetElement, RadioGroupProps>(
  (
    {
      defaultValue,
      disabled,
      label,
      className,
      onChange,
      children,
      ...props
    }: RadioGroupProps,
    forwardedRef,
  ) => {
    return (
      <fieldset ref={forwardedRef} className={clsx(styles.wrapper, className)}>
        <Text as="legend" weight="bold" className={styles.groupLabel}>
          {label}
        </Text>
        <RadioGroupPrimitive.Root
          {...props}
          disabled={disabled}
          defaultValue={defaultValue}
          onValueChange={onChange}
          className={styles.group}
        >
          {children}
        </RadioGroupPrimitive.Root>
      </fieldset>
    )
  },
)

RadioGroup.displayName = 'RadioGroup'

type RadioGroupItemBase = React.ElementRef<typeof RadioGroupPrimitive.Item>

type RadioGroupItemPropsBase = RadioGroupPrimitive.RadioGroupItemProps & {
  /**
   * The controlled value of the pressed items
   */
  value: string
  /**
   * When true, prevents the user from interacting with the radio group item
   */
  disabled?: boolean
}

/**
 * Internal component
 * RadioGroupItemBase is the base component for the RadioGroup.Item component
 * and is used by RadioCard
 */
const RadioGroupItemBase = forwardRef<
  RadioGroupItemBase,
  RadioGroupItemPropsBase
>(
  (
    {
      value,
      disabled = false,
      className,
      ...radioItemProps
    }: RadioGroupItemPropsBase,
    ref,
  ) => {
    return (
      <RadioGroupPrimitive.Item
        {...radioItemProps}
        ref={ref}
        value={value}
        disabled={disabled}
        className={clsx(
          styles.item,

          className,
        )}
      >
        <RadioGroupPrimitive.Indicator className={styles.indicator} />
      </RadioGroupPrimitive.Item>
    )
  },
)

RadioGroupItemBase.displayName = 'RadioGroup.ItemBase'

type RadioGroupItem = React.ElementRef<typeof RadioGroupPrimitive.Item>

type RadioGroupItemProps = RadioGroupPrimitive.RadioGroupItemProps & {
  /**
   * Label text of the radio group item
   */
  children: React.ReactNode
  /**
   * The controlled value of the pressed items
   */
  value: string
  /**
   * When true, prevents the user from interacting with the radio group item
   */
  disabled?: boolean
}

const RadioGroupItem = forwardRef<RadioGroupItem, RadioGroupItemProps>(
  (
    {
      children,
      value,
      disabled = false,
      ...radioItemProps
    }: RadioGroupItemProps,
    ref,
  ) => {
    return (
      <Text as="label" weight="medium" className={styles.itemLabel}>
        <RadioGroupItemBase
          {...radioItemProps}
          ref={ref}
          value={value}
          disabled={disabled}
        />
        {children}
      </Text>
    )
  },
)

RadioGroupItem.displayName = 'RadioGroup.Item'

const RadioGroupObject = Object.assign(RadioGroup, {
  Item: RadioGroupItem,
})

export { RadioGroupItemBase, RadioGroupObject as RadioGroup }
export type { RadioGroupProps }
