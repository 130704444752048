// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const TentativeTimeIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'tentative-time'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M21.9497 11C22.018 11.6776 22.0168 12.3604 21.9462 13.0378M2.05019 13.0391C1.98196 12.3615 1.98313 11.6787 2.05367 11.0013M19.7566 18.3345C19.3257 18.8619 18.8421 19.3439 18.3132 19.7729M4.2436 5.70459C4.67448 5.17722 5.15811 4.69524 5.68695 4.26615M13.0195 21.9683C12.3419 22.0366 11.6591 22.0354 10.9818 21.9649M10.9804 2.07076C11.658 2.00253 12.3408 2.0037 13.0182 2.07424M5.68525 19.7759C5.15787 19.345 4.67589 18.8614 4.24681 18.3326M18.3144 4.26319C18.8418 4.69407 19.3238 5.1777 19.7529 5.70654M12 6.00002V12L16 14"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
TentativeTimeIcon.displayName = 'TentativeTimeIcon'
export { TentativeTimeIcon }
