import React from 'react'
import clsx from 'clsx'
import type * as Polymorphic from '@radix-ui/react-polymorphic'

import { UnstyledButton } from '../UnstyledButton'
import { LoadingIcon } from '../icons'

import styles from './Button.module.css'

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'critical'
  | 'critical-subtle'
  | 'success'
  | 'warning'

type ButtonSize = 'large' | 'medium' | 'small'

type ButtonProps = {
  /**
   * Controls visual style of the Button
   * @default primary
   */
  variant?: ButtonVariant
  /**
   * Controls size of the Button
   * @default medium
   */
  size?: ButtonSize
  /**
   * Controls whether the Button is disabled, disabled buttons should be used sparingly,
   * @default false
   */
  disabled?: boolean

  /**
   * Controls whether the Button is inactive while loading
   * @default false
   */
  loading?: boolean
  /**
   * Additional classname applied to the Button
   */
  className?: string

  /**
   * Content to render inside the button, typically a label
   */
  children: React.ReactNode
  /**
   * On click handler
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

type PolymorphicButton = Polymorphic.ForwardRefComponent<'button', ButtonProps>

/**
 * Primary UI component for user interaction. In case you need a icon-only button, use `IconButton` instead.
 */
const Button = React.forwardRef(
  (
    {
      variant = 'primary',
      size = 'medium',
      children,
      loading,
      disabled,
      className,
      ...props
    }: ButtonProps,
    forwardedRef,
  ) => {
    return (
      <UnstyledButton
        className={clsx(
          styles.base,
          loading && styles.loading,
          styles[size],
          styles[variant],
          className,
        )}
        // TODO: This is temporary, disabled buttons are not focusable by default
        // which makes them mot accessible. We should revisit it and make sure
        // that the button is focusable when disabled or loading
        disabled={loading || disabled}
        ref={forwardedRef}
        {...props}
      >
        {loading && <LoadingIcon />}
        {children}
      </UnstyledButton>
    )
  },
) as PolymorphicButton

Button.displayName = 'Button'

export { Button }
export type { ButtonProps, ButtonVariant, ButtonSize }
