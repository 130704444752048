// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const LockOpenIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'lock-open'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M7 11V6.99997C6.99875 5.76002 7.45828 4.56384 8.28937 3.64364C9.12047 2.72344 10.2638 2.14487 11.4975 2.02026C12.7312 1.89565 13.9671 2.23387 14.9655 2.96928C15.9638 3.70469 16.6533 4.78482 16.9 5.99997M5 11H19C20.1046 11 21 11.8954 21 13V20C21 21.1045 20.1046 22 19 22H5C3.89543 22 3 21.1045 3 20V13C3 11.8954 3.89543 11 5 11Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
LockOpenIcon.displayName = 'LockOpenIcon'
export { LockOpenIcon }
