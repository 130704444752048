import React, { forwardRef, useState } from 'react'
import clsx from 'clsx'
import styles from './Image.module.css'

interface Source {
  /** Set of image source URLs along with their respective media conditions */
  srcSet: string
  /** Media condition that dictates when each source set is used */
  media: string
  /** MIME type of the image source, e.g., "image/webp", "image/png" */
  type?: string
}

export interface ImageProps
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'srcSet'> {
  /** Primary image source URL */
  src: string
  /** Array of source objects for responsive images */
  srcSet?: Source[]
  /** Text description of the image for accessibility */
  alt: string
  /** Placeholder image displayed while the primary image is loading */
  placeholder?: string
  /** Data-testid attribute for testing purposes */
  'data-testid'?: string
}

export const Image = forwardRef<HTMLImageElement, ImageProps>(
  (
    {
      src,
      srcSet,
      alt,
      placeholder,
      className,
      style,
      'data-testid': testId,
      ...props
    }: ImageProps,
    ref,
  ): JSX.Element => {
    const [loaded, setLoaded] = useState(false)

    return (
      <picture data-testid={testId}>
        {srcSet?.map((source, index) => (
          <source key={index} {...source} />
        ))}
        <img
          ref={ref}
          src={src}
          alt={alt}
          className={clsx(styles.image, className)}
          style={
            {
              ...style,
              '--image-placeholder':
                placeholder && !loaded ? `url(${placeholder})` : undefined,
            } as React.CSSProperties // Type casting for custom CSS properties
          }
          onLoad={() => setLoaded(true)}
          {...props}
        />
      </picture>
    )
  },
)

Image.displayName = 'Image'
