// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const SnapchatIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'snapchat'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M22.6849 17.9743C18.8275 16.0843 18.2127 13.1663 18.1853 12.9498C18.1522 12.6876 18.1147 12.4815 18.4004 12.2148C18.676 11.9572 19.8986 11.1913 20.2378 10.9516C20.7985 10.5548 21.0454 10.1587 20.8634 9.67168C20.7361 9.33479 20.4262 9.20789 20.0997 9.20789C19.9967 9.20821 19.8941 9.21987 19.7936 9.24267C19.1775 9.37795 18.5793 9.69036 18.2331 9.77475C18.1915 9.78561 18.1487 9.79144 18.1058 9.79214C17.9212 9.79214 17.8512 9.70905 17.869 9.48423C17.9123 8.80271 18.0039 7.47252 17.8977 6.22994C17.7519 4.52033 17.2071 3.67326 16.5612 2.92411C16.2487 2.5608 14.7976 1 11.9973 1C9.19705 1 7.7479 2.5608 7.43732 2.9196C6.78944 3.66876 6.24529 4.51583 6.10082 6.22543C5.99454 7.46801 6.09 8.79756 6.12946 9.47972C6.14219 9.69359 6.07728 9.78763 5.89271 9.78763C5.84976 9.78689 5.80704 9.78105 5.76543 9.77024C5.41985 9.68585 4.8216 9.37344 4.20554 9.23816C4.10505 9.21536 4.0024 9.2037 3.89942 9.20338C3.57166 9.20338 3.26299 9.33221 3.1357 9.66717C2.95369 10.1542 3.19935 10.5503 3.76195 10.9471C4.10117 11.1867 5.32375 11.952 5.59932 12.2103C5.88444 12.477 5.84753 12.6831 5.81443 12.9453C5.78707 13.165 5.17164 16.083 1.31488 17.9698C1.08895 18.0806 0.704549 18.315 1.38235 18.6938C2.44645 19.289 3.1548 19.2252 3.70531 19.584C4.17245 19.8887 3.89624 20.5457 4.23609 20.7828C4.65359 21.0746 5.88762 20.7622 7.48187 21.2949C8.81837 21.7407 9.63045 23 12.0005 23C14.3706 23 15.2062 21.7349 16.5192 21.2949C18.1102 20.7622 19.3468 21.0746 19.7649 20.7828C20.1042 20.5457 19.8286 19.8887 20.2957 19.584C20.8462 19.2252 21.5539 19.289 22.6187 18.6938C23.2952 18.3195 22.9108 18.0851 22.6849 17.9743Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
SnapchatIcon.displayName = 'SnapchatIcon'
export { SnapchatIcon }
