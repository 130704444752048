/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import clsx from 'clsx'

import styles from './Label.module.css'

type LabelProps = React.ComponentPropsWithoutRef<'label'> & {
  /**
   * Size of the label
   */
  size?: 'small' | 'medium'
  /**
   * Whether associated control is required or not
   */
  required?: boolean
}

const Label = React.forwardRef<React.ElementRef<'label'>, LabelProps>(
  ({ size = 'medium', className, ...props }: LabelProps, forwardedRef) => {
    const handleMouseDown = (event: React.MouseEvent<HTMLLabelElement>) => {
      // only prevent text selection if clicking inside the label itself
      const target = event.target as HTMLElement
      if (target.closest('button, input, select, textarea')) return

      props.onMouseDown?.(event)
      // prevent text selection when double clicking label
      if (!event.defaultPrevented && event.detail > 1) event.preventDefault()
    }

    return (
      <label
        {...props}
        className={clsx(styles.base, styles[size], className)}
        ref={forwardedRef}
        onMouseDown={handleMouseDown}
      />
    )
  },
)

Label.displayName = 'Label'

export { Label }
export type { LabelProps }
