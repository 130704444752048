// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const TextRephraseIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'text-rephrase'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M12 6H3M8 14H3M8 10H3M16 18H3M17.4 6.62132C17.7978 6.2235 18.3374 6 18.9 6C19.4626 6 20.0022 6.2235 20.4 6.62132C20.7978 7.01915 21.0213 7.55871 21.0213 8.12132C21.0213 8.68393 20.7978 9.2235 20.4 9.62132L16 14.0213L12 15.0213L13 11.0213L17.4 6.62132Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
TextRephraseIcon.displayName = 'TextRephraseIcon'
export { TextRephraseIcon }
