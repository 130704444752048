import React from 'react'
import clsx from 'clsx'
import { useLightboxState, useController } from 'yet-another-react-lightbox'
import { Flex } from '../Flex'
import {
  ZoomInIcon,
  ZoomOutIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '../icons'
import { UnstyledButton } from '../UnstyledButton'
import { IconButton } from '../IconButton'
import styles from './Lightbox.module.css'

export const ZoomControls = ({
  zoomLevel,
  maxZoom,
  zoomIn,
  zoomOut,
}: {
  zoomLevel: number
  maxZoom: number
  zoomIn?: () => void
  zoomOut?: () => void
}): JSX.Element | null => {
  const { currentSlide } = useLightboxState()
  // Do not show zoom controls for videos or images without dimensions
  if (currentSlide?.type === 'video') return null
  if (!currentSlide?.width || !currentSlide?.height) return null

  return (
    <Flex align="center" justify="center" className={styles.zoomControls}>
      <IconButton
        label="Zoom in"
        onClick={zoomIn}
        variant="tertiary"
        className={styles.zoomButton}
        disabled={zoomLevel >= maxZoom}
      >
        <ZoomInIcon />
      </IconButton>
      <IconButton
        label="Zoom out"
        onClick={zoomOut}
        variant="tertiary"
        className={styles.zoomButton}
        disabled={zoomLevel <= 1}
      >
        <ZoomOutIcon />
      </IconButton>
    </Flex>
  )
}

export const NextButton = (): JSX.Element => {
  const { slides, currentIndex } = useLightboxState()
  const { next } = useController()

  return (
    <UnstyledButton
      aria-label="Next"
      className={clsx(
        styles.navigationButton,
        'yarl__button yarl__navigation_next',
      )}
      disabled={currentIndex + 1 === slides.length}
      onClick={() => next()}
    >
      <Flex className={styles.navigationIcon} align="center" justify="center">
        <ChevronRightIcon color="inverted" size="large" />
      </Flex>
    </UnstyledButton>
  )
}

export const PrevButton = (): JSX.Element => {
  const { currentIndex } = useLightboxState()
  const { prev } = useController()

  return (
    <UnstyledButton
      aria-label="Next"
      className={clsx(
        styles.navigationButton,
        'yarl__button yarl__navigation_prev',
      )}
      disabled={currentIndex === 0}
      onClick={() => prev()}
    >
      <Flex className={styles.navigationIcon} align="center" justify="center">
        <ChevronLeftIcon color="inverted" size="large" />
      </Flex>
    </UnstyledButton>
  )
}
