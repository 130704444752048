import React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import clsx from 'clsx'

import { Card, CardProps } from '../Card'
import { Checkbox } from '../Checkbox'

import styles from './CheckboxCard.module.css'
import useId from '../../helpers/useId'
import { Flex } from '../Flex/Flex'

type CheckboxCardElement = React.ElementRef<typeof Card>

type CheckboxCardProps = Omit<CardProps, 'asChild'> & {
  /**
   * Control whether the checkbox is disabled or not
   */
  disabled?: boolean
  /**
   * Control whether the checkbox is checked or not
   */
  checked?: CheckboxPrimitive.CheckedState
  /**
   * Control whether the checkbox is checked or not
   * @default false
   */
  defaultChecked?: CheckboxPrimitive.CheckedState
  /**
   * Callback when the checkbox is checked or unchecked
   */
  onCheckedChange?: (checked: CheckboxPrimitive.CheckedState) => void
}

/**
 * Base input element to toggle an option on and off.
 */
const CheckboxCard = React.forwardRef<CheckboxCardElement, CheckboxCardProps>(
  (
    {
      id: passedId,
      checked,
      defaultChecked,
      children,
      className,
      onCheckedChange,
      disabled,
      ...props
    }: CheckboxCardProps,
    forwardedRef,
  ) => {
    const id = useId(passedId)

    return (
      <Card
        {...props}
        ref={forwardedRef}
        className={clsx(styles.card, className)}
        asChild
      >
        <Flex as="label" htmlFor={id} gap="xs" align="center">
          {children}

          <Checkbox
            id={id}
            className={styles.checkbox}
            disabled={disabled}
            onChange={onCheckedChange}
            checked={checked}
            defaultChecked={defaultChecked}
          />
        </Flex>
      </Card>
    )
  },
)

CheckboxCard.displayName = 'CheckboxCard'

type CheckboxGroupProps = React.HTMLAttributes<HTMLFieldSetElement> & {
  /**
   * Control whether the group is disabled or not
   */
  disabled?: boolean
}

const CheckboxCardGroup = React.forwardRef<
  HTMLFieldSetElement,
  CheckboxGroupProps
>(({ children, className, ...props }: CheckboxGroupProps, forwardedRef) => {
  return (
    <fieldset
      ref={forwardedRef}
      className={clsx(styles.group, className)}
      {...props}
    >
      {children}
    </fieldset>
  )
})

CheckboxCardGroup.displayName = 'CheckboxCard.Group'

const CheckboxCardObject = Object.assign(CheckboxCard, {
  Group: CheckboxCardGroup,
})

export { CheckboxCardObject as CheckboxCard, CheckboxCardGroup }
export type { CheckboxCardProps }
