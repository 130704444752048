// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const FlaskConicalIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'flask-conical'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M10 2V9.527C10.0003 9.83801 9.92806 10.1448 9.78902 10.423L4.72002 20.55C4.64293 20.703 4.60643 20.8732 4.61402 21.0444C4.62162 21.2155 4.67305 21.3818 4.7634 21.5274C4.85374 21.6729 4.97997 21.7928 5.12997 21.8756C5.27997 21.9584 5.44871 22.0012 5.62002 22H18.38C18.5513 22.0012 18.7201 21.9584 18.8701 21.8756C19.0201 21.7928 19.1463 21.6729 19.2366 21.5274C19.327 21.3818 19.3784 21.2155 19.386 21.0444C19.3936 20.8732 19.3571 20.703 19.28 20.55L14.211 10.423C14.072 10.1448 13.9997 9.83801 14 9.527V2M8.50002 2H15.5M7.00002 16H17"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
FlaskConicalIcon.displayName = 'FlaskConicalIcon'
export { FlaskConicalIcon }
