// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const StartPageIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'start-page'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 2C3.67157 2 3 2.63959 3 3.42857V20.5714C3 21.3604 3.67157 22 4.5 22H19.5C20.3284 22 21 21.3604 21 20.5714V3.42857C21 2.63959 20.3284 2 19.5 2H4.5ZM12 4.14286C10.3431 4.14286 9 5.42204 9 7C9 8.57796 10.3431 9.85714 12 9.85714C13.6569 9.85714 15 8.57796 15 7C15 5.42204 13.6569 4.14286 12 4.14286ZM4.99997 13.4286C4.99997 12.6396 5.67154 12 6.49997 12H17.5C18.3284 12 19 12.6396 19 13.4286C19 14.2176 18.3284 14.8571 17.5 14.8571H6.49997C5.67154 14.8571 4.99997 14.2176 4.99997 13.4286ZM6.49997 16.2857C5.67154 16.2857 4.99997 16.9253 4.99997 17.7143C4.99997 18.5033 5.67154 19.1429 6.49997 19.1429H17.5C18.3284 19.1429 19 18.5033 19 17.7143C19 16.9253 18.3284 16.2857 17.5 16.2857H6.49997Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
StartPageIcon.displayName = 'StartPageIcon'
export { StartPageIcon }
