// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const ShoppingCartIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'shopping-cart'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M2.05005 2.04999H4.05005L6.71005 14.47C6.80763 14.9248 7.06072 15.3315 7.42576 15.6198C7.7908 15.9082 8.24495 16.0603 8.71005 16.05H18.49C18.9452 16.0493 19.3865 15.8933 19.7411 15.6078C20.0956 15.3224 20.3422 14.9245 20.4401 14.48L22.09 7.04999H5.12005M9.00005 21C9.00005 21.5523 8.55233 22 8.00005 22C7.44776 22 7.00005 21.5523 7.00005 21C7.00005 20.4477 7.44776 20 8.00005 20C8.55233 20 9.00005 20.4477 9.00005 21ZM20 21C20 21.5523 19.5523 22 19 22C18.4478 22 18 21.5523 18 21C18 20.4477 18.4478 20 19 20C19.5523 20 20 20.4477 20 21Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
ShoppingCartIcon.displayName = 'ShoppingCartIcon'
export { ShoppingCartIcon }
