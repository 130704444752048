import React from 'react'
import clsx from 'clsx'

import { Badge, BadgeProps } from './Badge'

import styles from './NewBadge.module.css'

type NewBadgeProps = Omit<BadgeProps, 'children'>

const NewBadge = React.forwardRef<HTMLSpanElement, NewBadgeProps>(
  ({ className, ...props }, forwardedRef) => {
    return (
      <Badge
        size="small"
        className={clsx(className, styles.new)}
        {...props}
        ref={forwardedRef}
      >
        New
      </Badge>
    )
  },
)

NewBadge.displayName = 'NewBadge'

export { NewBadge, NewBadgeProps }
