// Copied from: https://github.com/radix-ui/primitives/blob/b32a93318cdfce383c2eec095710d35ffbd33a1c/packages/react/use-callback-ref/src/useCallbackRef.tsx#L7
import * as React from 'react'

/**
 * A custom hook that converts a callback to a ref to avoid triggering re-renders when passed as a
 * prop or avoid re-executing effects when passed as a dependency
 */
function useCallbackRef<T extends (...args: Parameters<T>) => ReturnType<T>>(
  callback: T | undefined,
): T {
  const callbackRef = React.useRef(callback)

  React.useEffect(() => {
    callbackRef.current = callback
  })

  // https://github.com/facebook/react/issues/19240
  return React.useMemo(
    () => ((...args: Parameters<T>) => callbackRef.current?.(...args)) as T,
    [],
  )
}

export { useCallbackRef }
