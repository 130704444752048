// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const IconGoogleIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'google-icon'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M16.3997 6.94997C15.2741 6.05004 13.8416 5.50003 12.2555 5.50003C9.3029 5.50003 6.81492 7.36497 5.94203 9.95778C5.72549 10.6003 5.60461 11.2856 5.60461 12.0001C5.60461 12.7111 5.76911 13.5216 5.98354 14.1611C6.89159 16.6919 9.3484 18.5 12.2555 18.5C13.7013 18.5 14.9789 18.1634 15.9935 17.538C17.1692 16.813 17.9913 15.6992 18.2932 14.2502H12.256V10.0002H22.7441C22.8976 10.6501 23 11.3501 23 12.0001C23 15.5856 21.6555 18.3815 19.5891 20.2584C17.622 22.0459 15.0009 23 12.2555 23C7.80742 23 3.9831 20.5077 2.1526 16.8696C1.41552 15.4035 1 13.7516 1 12.0001C1 10.2471 1.41586 8.59405 2.15415 7.12711C3.98465 3.48955 7.80742 1 12.2555 1C15.1206 1 17.6787 2.04999 19.674 3.75L16.3997 6.94997Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
IconGoogleIcon.displayName = 'IconGoogleIcon'
export { IconGoogleIcon }
