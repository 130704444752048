// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const IgProductTagIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'ig-product-tag'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.285 5.11716C17.2384 5.1612 18.1504 5.54669 18.8656 6.21185C19.6266 6.91959 20.1117 7.89457 20.2285 8.95126L21.3476 17.6674C21.4148 18.2746 21.3581 18.8898 21.1814 19.4726C21.0046 20.0554 20.7118 20.5926 20.322 21.0489C19.9323 21.5053 19.4545 21.8705 18.9199 22.1206C18.3854 22.3707 17.8062 22.5 17.2204 22.5H7.15457C6.56877 22.5 5.98959 22.3707 5.45506 22.1206C4.92052 21.8705 4.4427 21.5053 4.05296 21.0489C3.66322 20.5926 3.37037 20.0554 3.19363 19.4726C3.01688 18.8898 2.96023 18.2746 3.02741 17.6674L4.14645 8.95126C4.26334 7.89457 4.74844 6.91959 5.50944 6.21185C6.22465 5.54669 7.13665 5.16119 8.09004 5.11715C8.23228 4.23377 8.63457 3.41157 9.24957 2.76983C10.0288 1.95677 11.0856 1.5 12.1875 1.5C13.2894 1.5 14.3462 1.95677 15.1254 2.76983C15.7404 3.41157 16.1427 4.23377 16.285 5.11716ZM10.2289 5.11291C10.3312 4.81094 10.4974 4.53346 10.7185 4.30266C11.1081 3.89613 11.6365 3.66774 12.1875 3.66774C12.7385 3.66774 13.2669 3.89613 13.6565 4.30266C13.8777 4.53346 14.0438 4.81094 14.1461 5.11291H10.2289ZM8.33685 9.76629C8.14205 9.96955 8.03262 10.2452 8.03262 10.5327V10.894C8.03262 12.0438 8.47036 13.1466 9.24955 13.9596C10.0287 14.7727 11.0855 15.2295 12.1875 15.2295C13.2894 15.2295 14.3462 14.7727 15.1254 13.9596C15.9046 13.1466 16.3424 12.0438 16.3424 10.894V10.5327C16.3424 10.2452 16.2329 9.96955 16.0381 9.76629C15.8433 9.56302 15.5791 9.44883 15.3036 9.44883C15.0281 9.44883 14.7639 9.56302 14.5691 9.76629C14.3744 9.96955 14.2649 10.2452 14.2649 10.5327V10.894C14.2649 11.4689 14.046 12.0203 13.6565 12.4268C13.2669 12.8333 12.7385 13.0617 12.1875 13.0617C11.6365 13.0617 11.1081 12.8333 10.7185 12.4268C10.3289 12.0203 10.1101 11.4689 10.1101 10.894V10.5327C10.1101 10.2452 10.0006 9.96955 9.80582 9.76629C9.61102 9.56302 9.34682 9.44883 9.07133 9.44883C8.79585 9.44883 8.53165 9.56302 8.33685 9.76629Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
IgProductTagIcon.displayName = 'IgProductTagIcon'
export { IgProductTagIcon }
