import React from 'react'
import { ToastVariant } from './Toast'

type ToastId = number | string
type ToastMessage = string | React.ReactNode

const DEFAULT_DURATION = 5000

type ToasterToast = {
  id: ToastId
  message?: ToastMessage
  variant?: ToastVariant
  dismissible?: boolean
  duration?: number
  action?: {
    label: string | React.ReactNode
    altText?: string
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  }
  onDismiss?: (id: ToastId) => void
}

type ToastOptions = Omit<ToasterToast, 'id' | 'variant' | 'message'> & {
  id?: number | string
}

type ToastEvent =
  | {
      type: 'dismiss'
      id: ToastId
    }
  | {
      type: 'push'
      toast: ToasterToast
    }
  | {
      type: 'dismissAll'
    }

type ToastStateSubscriber = (event: ToastEvent) => void

/* Used to generate toast ids */
let toastsCounter = 1

class Observer {
  subscribers: Array<ToastStateSubscriber>
  toasts: Array<ToasterToast>

  constructor() {
    this.subscribers = []
    this.toasts = []
  }

  subscribe = (subscriber: ToastStateSubscriber) => {
    this.subscribers.push(subscriber)

    return () => {
      const index = this.subscribers.indexOf(subscriber)
      this.subscribers.splice(index, 1)
    }
  }

  publishEvent = (event: ToastEvent) => {
    this.subscribers.forEach((subscriber) => subscriber(event))
  }

  addToast = (toast: ToasterToast) => {
    this.toasts = [...this.toasts, toast]
    this.publishEvent({ type: 'push', toast })
  }

  updateToast = (id: ToastId, newToast: ToasterToast) => {
    const exitingToast = this.toasts.find((toast) => toast.id === id)

    if (!exitingToast) {
      return
    }

    const updatedToast: ToasterToast = {
      ...exitingToast,
      ...newToast,
    }

    this.toasts = this.toasts.map((item) =>
      item.id === id ? updatedToast : item,
    )

    this.publishEvent({ type: 'push', toast: updatedToast })
  }

  create = (
    data: ToastOptions & {
      message?: ToastMessage
      variant?: ToastVariant
    },
  ) => {
    const alreadyExists =
      !!data?.id && this.toasts.some((toast) => toast.id === data.id)
    const id = data?.id ?? toastsCounter++

    if (alreadyExists) {
      this.updateToast(id, { id, ...data })
      return id
    }

    const preparedToast = {
      ...data,
      dismissible: data.dismissible ?? true,
      duration: data.duration ?? DEFAULT_DURATION,
      variant: data.variant ?? 'neutral',
      id,
    }

    this.addToast(preparedToast)
    return id
  }

  dismiss = (id: ToastId) => {
    this.publishEvent({ type: 'dismiss', id })
    return id
  }

  dismissAll = () => {
    this.publishEvent({ type: 'dismissAll' })
  }

  message = (message: ToastMessage, data?: ToastOptions) => {
    return this.create({ ...data, message })
  }

  error = (message: ToastMessage, data?: ToastOptions) => {
    return this.create({ ...data, message, variant: 'error' })
  }

  success = (message: ToastMessage, data?: ToastOptions) => {
    return this.create({ ...data, variant: 'success', message })
  }
}

const ToastState = new Observer()

const toast = Object.assign(ToastState.message, {
  success: ToastState.success,
  error: ToastState.error,
  dismiss: ToastState.dismiss,
  dismissAll: ToastState.dismissAll,
})

export { toast, ToastState }
export type {
  ToastOptions,
  ToasterToast,
  ToastStateSubscriber,
  ToastId,
  ToastMessage,
  ToastEvent,
}
