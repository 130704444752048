import React, { forwardRef } from 'react'
import clsx from 'clsx'
import type * as Polymorphic from '@radix-ui/react-polymorphic'

import gapStyles from '../../styles/atoms/gap.module.css'
import displayStyles from '../../styles/atoms/display.module.css'
import alignItemsStyles from '../../styles/atoms/align-items.module.css'
import flexDirectionStyles from '../../styles/atoms/flex-direction.module.css'
import flexWrapStyles from '../../styles/atoms/flex-wrap.module.css'
import justifyContentStyles from '../../styles/atoms/justify-content.module.css'
import { Space } from '../../styles/tokens/tokens'

interface FlexProps {
  /**
   * Defines as which HTML element to render
   * @default 'div'
   */
  as?: keyof JSX.IntrinsicElements
  /**
   * CSS display property
   * @default 'flex'
   */
  display?: 'none' | 'inline-flex' | 'flex'
  /**
   * flex-direction property
   * @default 'row'
   */
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  /**
   * align-items property
   * @default 'start'
   */
  align?: 'start' | 'center' | 'end' | 'baseline' | 'stretch' | 'normal'
  /**
   * justify-content property
   * @default 'start'
   */
  justify?: 'start' | 'center' | 'end' | 'between' | 'around'
  /**
   * flex-wrap property
   * @default 'nowrap'
   */
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  /**
   * Gap between children
   */
  gap?: Space
}

type PolymorphicFlex = Polymorphic.ForwardRefComponent<'div', FlexProps>

export const Flex = forwardRef(
  (
    {
      as,
      children,
      display = 'flex',
      direction = 'row',
      align = 'start',
      justify = 'start',
      wrap = 'nowrap',
      gap,
      className,
      ...props
    },
    forwardedRef,
  ) => {
    const Tag = as || 'div'

    return (
      <Tag
        className={clsx(
          displayStyles[display],
          flexDirectionStyles[direction],
          alignItemsStyles[align],
          justifyContentStyles[justify],
          flexWrapStyles[wrap],
          gap && gapStyles[`gap-${gap}`],
          className,
        )}
        ref={forwardedRef}
        {...props}
      >
        {children}
      </Tag>
    )
  },
) as PolymorphicFlex

Flex.displayName = 'Flex'

export type { FlexProps }
