// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const MediumIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'medium'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M7.23333 5.5C6.41476 5.5 5.6042 5.66813 4.84794 5.99478C4.09168 6.32144 3.40452 6.80023 2.8257 7.40381C2.24688 8.00739 1.78774 8.72394 1.47448 9.51256C1.16123 10.3012 1 11.1464 1 12C1 12.8536 1.16123 13.6988 1.47448 14.4874C1.78774 15.2761 2.24688 15.9926 2.8257 16.5962C3.40452 17.1998 4.09168 17.6786 4.84794 18.0052C5.6042 18.3319 6.41476 18.5 7.23333 18.5C8.05191 18.5 8.86246 18.3319 9.61873 18.0052C10.375 17.6786 11.0621 17.1998 11.641 16.5962C12.2198 15.9926 12.6789 15.2761 12.9922 14.4874C13.3054 13.6988 13.4667 12.8536 13.4667 12C13.4667 11.1464 13.3054 10.3012 12.9922 9.51256C12.6789 8.72394 12.2198 8.00739 11.641 7.40381C11.0621 6.80023 10.375 6.32144 9.61873 5.99478C8.86246 5.66813 8.05191 5.5 7.23333 5.5ZM17.1333 6.26471C16.7481 6.26471 16.3667 6.41305 16.0108 6.70128C15.6549 6.9895 15.3315 7.41196 15.0592 7.94453C14.7868 8.47711 14.5707 9.10936 14.4233 9.8052C14.2759 10.501 14.2 11.2468 14.2 12C14.2 12.7532 14.2759 13.499 14.4233 14.1948C14.5707 14.8906 14.7868 15.5229 15.0592 16.0555C15.3315 16.588 15.6549 17.0105 16.0108 17.2987C16.3667 17.5869 16.7481 17.7353 17.1333 17.7353C17.5185 17.7353 17.9 17.5869 18.2559 17.2987C18.6118 17.0105 18.9351 16.588 19.2075 16.0555C19.4799 15.5229 19.696 14.8906 19.8434 14.1948C19.9908 13.499 20.0667 12.7532 20.0667 12C20.0667 11.2468 19.9908 10.501 19.8434 9.8052C19.696 9.10936 19.4799 8.47711 19.2075 7.94453C18.9351 7.41196 18.6118 6.9895 18.2559 6.70128C17.9 6.41305 17.5185 6.26471 17.1333 6.26471ZM21.9 7.02941C21.6083 7.02941 21.3285 7.5531 21.1222 8.48526C20.9159 9.41743 20.8 10.6817 20.8 12C20.8 13.3183 20.9159 14.5826 21.1222 15.5147C21.3285 16.4469 21.6083 16.9706 21.9 16.9706C22.1917 16.9706 22.4715 16.4469 22.6778 15.5147C22.8841 14.5826 23 13.3183 23 12C23 10.6817 22.8841 9.41743 22.6778 8.48526C22.4715 7.5531 22.1917 7.02941 21.9 7.02941Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
MediumIcon.displayName = 'MediumIcon'
export { MediumIcon }
