// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const LinkedinIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'linkedin'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.338 18.3384H15.6701V14.161C15.6701 13.1644 15.6518 11.8831 14.2828 11.8831C12.8935 11.8831 12.6807 12.9684 12.6807 14.0889V18.3384H10.0129V9.74825H12.574V10.9219H12.6095C12.9657 10.2466 13.8366 9.5345 15.1355 9.5345C17.8389 9.5345 18.338 11.3136 18.338 13.6265V18.3384ZM7.00419 8.574C6.14846 8.574 5.45584 7.88013 5.45584 7.02563C5.45584 6.17175 6.14846 5.47813 7.00419 5.47813C7.85768 5.47813 8.5513 6.17175 8.5513 7.02563C8.5513 7.88013 7.85768 8.574 7.00419 8.574ZM5.66871 18.3384H8.33855V9.74825H5.66871V18.3384ZM19.6676 3H4.32861C3.59562 3 3 3.581 3 4.29737V19.7011C3 20.4181 3.59562 21 4.32861 21H19.6676C20.4019 21 21 20.4181 21 19.7011V4.29737C21 3.581 20.4019 3 19.6676 3Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
LinkedinIcon.displayName = 'LinkedinIcon'
export { LinkedinIcon }
