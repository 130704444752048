import clsx from 'clsx'
import React from 'react'
import { useControllableState } from '../../hooks/useControllableState'
import { useMergeRefs } from '../../hooks/useMergeRefs'

import styles from './Textarea.module.css'

type TextareaProps = React.ComponentPropsWithoutRef<'textarea'> & {
  /**
   * Size of the textarea
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Textarea resizing, set "auto" to grow with content
   * @default 'auto'
   */
  resize?: 'none' | 'auto' | 'vertical' | 'horizontal' | 'both'
  /**
   * Number of rows to display
   * @default 2
   */
  rows?: number
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      value: passedValue,
      className,
      size = 'medium',
      defaultValue,
      resize = 'auto',
      onChange,
      rows = 2,
      ...props
    }: TextareaProps,
    forwardedRef,
  ) => {
    const [value, setValue] = useControllableState({
      prop: passedValue,
      defaultProp: defaultValue,
    })

    const innerRef = React.useRef<HTMLTextAreaElement>(null)
    const ref = useMergeRefs(forwardedRef, innerRef)

    const shouldAutoGrow = resize === 'auto'

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value)
        onChange?.(event)
      },
      [onChange, setValue],
    )

    React.useEffect(() => {
      const textarea = innerRef.current
      if (!textarea) {
        return
      }

      // set height to content height
      if (shouldAutoGrow) {
        textarea.style.height = 'inherit'
        textarea.style.height = `calc(${textarea.scrollHeight}px + (var(--textarea-padding-y)))`
      }
    }, [ref, value, shouldAutoGrow])

    return (
      <textarea
        {...props}
        rows={rows}
        value={value}
        style={
          {
            '--textarea-resize': resize === 'auto' ? 'none' : resize,
          } as React.CSSProperties
        }
        className={clsx(styles.base, styles[size], className)}
        ref={ref}
        onChange={handleChange}
      />
    )
  },
)

Textarea.displayName = 'Textarea'

export { Textarea, TextareaProps }
