import * as React from 'react'
import clsx from 'clsx'
import type * as Polymorphic from '@radix-ui/react-polymorphic'

import styles from './Link.module.css'

type LinkOwnProps = {
  /**
   * Will set the link to open in a new tab
   * with `rel="noopener noreferrer"`
   * @default false
   */
  external?: boolean
  /**
   * Link contents
   */
  children: React.ReactNode
  /**
   * Additional classname applied to the link
   */
  className?: string
  /**
   * Link color. Use inherit to match the text color when link needs to be more subtle
   * @default 'brand'
   */
  color?: 'brand' | 'inherit'
  /**
   * rel attribute
   */
  rel?: string
  /**
   * target attribute
   */
  target?: string
}

type PolymorphicLink = Polymorphic.ForwardRefComponent<'a', LinkOwnProps>

const Link = React.forwardRef(
  (
    {
      as: Tag = 'a',
      children,
      rel,
      target,
      external = false,
      className,
      color = 'brand',
      ...props
    },
    forwardedRef,
  ) => (
    <Tag
      {...props}
      ref={forwardedRef}
      rel={external ? 'noopener noreferrer' : rel}
      target={external ? '_blank' : target}
      className={clsx(
        styles.link,
        color === 'inherit' && styles.inherit,
        external && styles.external,
        className,
      )}
    >
      {children}
    </Tag>
  ),
) as PolymorphicLink

Link.displayName = 'Link'

export { Link }
export type { LinkOwnProps }
