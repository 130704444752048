import * as React from 'react'
import clsx from 'clsx'

import styles from './Strong.module.css'

type StrongElement = React.ElementRef<'strong'>
type StrongProps = React.ComponentPropsWithoutRef<'strong'>

const Strong = React.forwardRef<StrongElement, StrongProps>(
  (props, forwardedRef) => (
    <strong
      {...props}
      ref={forwardedRef}
      className={clsx(styles.strong, props.className)}
    />
  ),
)

Strong.displayName = 'Strong'

export { Strong }
export type { StrongProps }
