import React from 'react'
import clsx from 'clsx'

import { Badge, BadgeProps } from './Badge'

import styles from './AlphaBadge.module.css'

type AlphaBadgeProps = Omit<BadgeProps, 'children'>

const AlphaBadge = React.forwardRef<HTMLSpanElement, AlphaBadgeProps>(
  ({ className, ...props }, forwardedRef) => {
    return (
      <Badge
        className={clsx(className, styles.alpha)}
        {...props}
        ref={forwardedRef}
      >
        Alpha
      </Badge>
    )
  },
)

AlphaBadge.displayName = 'AlphaBadge'

export { AlphaBadge, AlphaBadgeProps }
